import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { message, Form, Icon, Input, Modal, Spin, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  AUTH_UPDATE_USERNAME,
  AuthUpdateUsername as AuthUpdateUsernameData,
  AuthUpdateUsernameVariables,
} from "../../../../lib/graphql/mutations";

interface FormProps {
  userId: number;
  setUsername: (input: any) => void;
  visible: boolean;
  setVisible: (input: any) => void;
}

interface IconProps {
  userId: number;
  setUsername: (input: any) => void;
}

const ChangeMyUsernameModalFormBase = ({
  userId,
  setUsername,
  visible,
  setVisible,
  form,
}: FormProps & FormComponentProps) => {
  let [updateErrorMsg, setUpdateErrorMsg] = useState<string | null>(null);
  let [newUsername, setNewUsername] = useState("");

  const [authUpdateUsername, { loading: updateRunning }] = useMutation<
    AuthUpdateUsernameData,
    AuthUpdateUsernameVariables
  >(AUTH_UPDATE_USERNAME, {
    onCompleted: (data) => {
      message.success("Login ID changed successfully.");
      setUsername(newUsername);

      form.resetFields();
      setVisible(false);
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update Login ID, please reenter your password!");
      if (data?.graphQLErrors) {
        let message = data.graphQLErrors[0].message;
        //      if(message.startsWith("ER_DUP_ENTRY") && message.includes("ux_users_email")){
        if (
          message.startsWith("ER_DUP_ENTRY") &&
          message.includes("ux_userCredentials_username")
        ) {
          setUpdateErrorMsg(
            "This Login ID cannot be used, please choose different ID."
          );
        } else {
          setUpdateErrorMsg(
            "We're unable to update your Login ID, try again later."
          );
        }
      } else {
        setUpdateErrorMsg(
          "We're unable to update your Login ID, try again later."
        );
      }
    },
  });

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      setUpdateErrorMsg(null);

      setNewUsername(values.newUsername);

      authUpdateUsername({
        variables: {
          id: `${userId}`,
          newUsername: values.newUsername,
          password: values.password,
        },
      });
    });
  };

  return (
    <Modal
      visible={visible}
      title="Change the Login ID"
      okText="Update"
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Spin spinning={updateRunning}>
        <Form layout="vertical">
          <Form.Item label="New Login ID">
            {form.getFieldDecorator("newUsername", {
              rules: [
                {
                  required: true,
                  min: 5,
                  max: 100,
                  message: "Enter a new Login ID of 5 characters or more!",
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="Password">
            {form.getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  max: 50,
                  message: "Please enter your current Password!",
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
        </Form>
        {updateErrorMsg && (
          <div>
            <Alert message={updateErrorMsg} type="error" showIcon />
          </div>
        )}
      </Spin>
    </Modal>
  );
};

const ChangeMyUsernameModalForm = Form.create<FormProps & FormComponentProps>({
  name: "change_my_username_form",
})(ChangeMyUsernameModalFormBase);

export const ChangeMyUsernameIcon = ({ userId, setUsername }: IconProps) => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ marginLeft: "15px", display: "inline-block" }}>
      <Icon type="edit" onClick={() => showModal()} />
      <ChangeMyUsernameModalForm
        userId={userId}
        visible={visible}
        setVisible={setVisible}
        setUsername={setUsername}
      />
    </div>
  );
};
