import { gql } from "apollo-boost";

export const AUTH_CURRENT_USER = gql`
  query AuthCurrentUser {
    authCurrentUser {
      id
      userStatusId
      userStatus{
        id
        code
        name
      }
      roleId
      role{
        id
        code
        name
      }
      email
      altEmail
      firstName
      middleName
      lastName
      primaryPhone
      canSmsPrimaryPhone
      altPhone
      canSmsAltPhone
      avatar
      userCredential{
        id
        username
        lastLoginAt
      }
      isDeleted
    }
  }
`;
