import { gql } from "apollo-boost";

export const OPEN_CALL_FOLLOWUPS = gql`
  query OpenCallFollowups($userId: String, $openOnly: Boolean) {
    openCallFollowups(userId: $userId, openOnly: $openOnly) {
      id
      callId
      call{
        callType{
          name
        }
        studentId
        student{
          contact{
            firstName
            middleName
            lastName
          }
          university{
            shortName
          }
          universityCampus{
            name
          }
        }
        navigatorUserId
        navigatorUser{
          firstName
          middleName
          lastName
        }
      }
      followupNumber
      followupStatus{
        name
      }
      followupType{
        name
      }
      dateDue
      updatedAt
      daysSinceOpened
      daysSinceClosed
      daysSinceActivity
      daysToDueDate
      daysOpen
    }
  }
`;
