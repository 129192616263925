import { gql } from "apollo-boost";

export const UNIVERSITY = gql`
  query University($id: String!) {
    university(id: $id) {
      id
      universityKey
      name
      shortName
      avatar
      contactId
      contact{
        id
        firstName
        middleName
        lastName
        namePrefix
        nameSuffix
        email
        altEmail
        primaryPhone
        canSmsPrimaryPhone
        altPhone
        canSmsAltPhone
        addressTypeId
        countryId
        addressLine1
        addressLine2
        city
        stateId
        province
        zipCode
        freeFormAddress    
      }
      coverageStartDate
      coverageTerminationDate
      isDeleted
      universityCampuses {
        id
        campusKey
        name
        description
        isDefault
      }
    }
  }
`;

