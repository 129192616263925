import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import { message, Button, Layout, Popconfirm, Spin, Table } from "antd";
import moment from "moment-timezone";

import { StringSorter, DateOnlySorter } from "../../../../lib/utils";
import { ShowDeletableRecordText } from "../../components";
import { UniversityListSkeleton } from "../UniversityListSkeleton";
import {
  UNIVERSITIES,
  Universities as UniversitiesData,
} from "../../../../lib/graphql/queries";
import {
  DELETE_UNIVERSITY,
  DeleteUniversity as DeleteUniversityData,
  DeleteUniversityVariables,
  UNDELETE_UNIVERSITY,
  UndeleteUniversity as UndeleteUniversityData,
  UndeleteUniversityVariables,
} from "../../../../lib/graphql/mutations/app";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../../lib/utils";
import "../../styles/admin.css";

const { Content } = Layout;

interface Props {
  title: string;
}
interface PopEditUniversityProps {
  id: string;
  handleEditUniversity: (id: string) => Promise<void>;
  handleDeleteUniversity: (id: string) => Promise<void>;
}
interface PopUndeleteUniversityProps {
  id: string;
  handleUndeleteUniversity: (id: string) => Promise<void>;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

export const UniversityList = ({ title }: Props) => {
  let [refreshTs] = useState<number>(new Date().valueOf());

  const { data, loading, error, refetch } = useQuery<UniversitiesData>(
    UNIVERSITIES,
    {
      variables: {
        includeDeleted: true,
      },
      fetchPolicy: "no-cache",
    }
  );
  const universities = data ? data.universities : undefined;

  const [deleteUniversity, { loading: deleteRunning }] = useMutation<
    DeleteUniversityData,
    DeleteUniversityVariables
  >(DELETE_UNIVERSITY, {
    onCompleted: (data) => {
      message.success("Record successfully deleted.");
    },
    onError: (data) => {
      message.error("Failed to delete record!");
    },
  });

  const [undeleteUniversity, { loading: undeleteRunning }] = useMutation<
    UndeleteUniversityData,
    UndeleteUniversityVariables
  >(UNDELETE_UNIVERSITY, {
    onCompleted: (data) => {
      message.success("Record successfully restored.");
    },
    onError: (data) => {
      message.error("Failed to restore record!");
    },
  });

  const addUrl = `/admin/app/university/0`;
  const editUrl = `/admin/app/university`;
  const history = useHistory();

  const handleAddUniversity = async () => {
    history.push(`${addUrl}`);
  };

  const handleEditUniversity = async (id: string) => {
    history.push(`${editUrl}/${id}`);
  };

  const handleDeleteUniversity = async (id: string) => {
    await deleteUniversity({ variables: { id } });
    refetch();
  };

  const handleUndeleteUniversity = async (id: string) => {
    await undeleteUniversity({ variables: { id } });
    refetch();
  };

  const columns: any = [
    {
      dataIndex: "id",
      key: "avatar",
      width: 27,
      render: (text: any, row: any) => (
        <div className="air__utils__avatar air__utils__avatar--size27">
          <img src={`/avatar/university/${text}?_ts=${refreshTs}`} alt="avatar" />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) => StringSorter(a.name, b.name),
    },
    {
      title: "Group #",
      dataIndex: "universityKey",
      key: "universityKey",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) =>
        StringSorter(a.universityKey, b.universityKey),
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) => StringSorter(a.shortName, b.shortName),
    },
    {
      title: "Coverage Start",
      dataIndex: "coverageStartDate",
      key: "coverageStartDate",
      align: "center",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText
          text={
            text
              ? moment(new Date(text)).format(dateFormat)
              : "-- not defined --"
          }
          isRecordDeleted={row.isDeleted}
        />
      ),
      sorter: (a: any, b: any) =>
        DateOnlySorter(a.coverageStartDate, b.coverageStartDate),
    },
    {
      title: "Coverage Termination",
      dataIndex: "coverageTerminationDate",
      key: "coverageTerminationDate",
      align: "center",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText
          text={
            text
              ? moment(new Date(text)).format(dateFormat)
              : "-- not defined --"
          }
          isRecordDeleted={row.isDeleted}
        />
      ),
      sorter: (a: any, b: any) =>
        DateOnlySorter(a.coverageTerminationDate, b.coverageTerminationDate),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any) =>
        row.isDeleted ? (
          <PopUndeleteUniversity
            id={text}
            handleUndeleteUniversity={handleUndeleteUniversity}
          />
        ) : (
          <PopEditUniversity
            id={text}
            handleEditUniversity={handleEditUniversity}
            handleDeleteUniversity={handleDeleteUniversity}
          />
        ),
    },
  ];

  if (loading) {
    return (
      <div className="admin">
        <UniversityListSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <UniversityListSkeleton title={title} error />
      </div>
    );
  }

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Spin spinning={deleteRunning || undeleteRunning}>
              <div>
                <Helmet title="Manage Universities" />
                <div className="air__utils__heading">
                  <h5>Manage Universities</h5>
                </div>

                <div className="card">
                  <div className="card-header card-header-flex">
                    <div className="d-flex flex-column justify-content-center mr-auto">
                      <h4 className="mb-0">
                        <strong>University List</strong>
                      </h4>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{ marginTop: "25px" }}
                    >
                      {addUrl && (
                        <span>
                          <Button
                            onClick={() => handleAddUniversity()}
                            size="small"
                            shape="round"
                          >
                            Add
                          </Button>
                        </span>
                      )}
                      &nbsp;
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="air__utils__scrollTable">
                      <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={universities}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(hasAuthRole("Navigator"), hasAuthRole("AccountManager"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};

export const PopUndeleteUniversity = ({
  id,
  handleUndeleteUniversity,
}: PopUndeleteUniversityProps) => {
  return (
    <span>
      <Popconfirm
        title={`Are you sure restore this deleted University entry?`}
        onConfirm={() => handleUndeleteUniversity(id)}
        okText="Yes"
        cancelText="No"
      >
        <Button size="small" shape="round">
          Restore
        </Button>
      </Popconfirm>
    </span>
  );
};

export const PopEditUniversity = ({
  id,
  handleEditUniversity,
  handleDeleteUniversity,
}: PopEditUniversityProps) => {
  return (
    <span>
      <Button
        style={{ marginRight: "5px" }}
        onClick={() => handleEditUniversity(id)}
        size="small"
        shape="round"
      >
        Edit
      </Button>

      <Popconfirm
        title={`Are you sure delete this University entry?`}
        onConfirm={() => handleDeleteUniversity(id)}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ marginRight: "5px" }} size="small" shape="round">
          Delete
        </Button>
      </Popconfirm>
    </span>
  );
};
