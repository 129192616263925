import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { CodesSkeleton, CodeListManager } from "../../components";

import { STATES, States as StatesData } from "../../../../lib/graphql/queries";
import {
  DELETE_STATE,
  DeleteState as DeleteStateData,
  DeleteStateVariables,
  UNDELETE_STATE,
  UndeleteState as UndeleteStateData,
  UndeleteStateVariables,
} from "../../../../lib/graphql/mutations/codes";

import "../../styles/admin.css";

interface Props {
  title: string;
}

export const StateList = ({ title }: Props) => {
  const codeType = "State";
  const codeTypePlural = "States";
  const canAddCodes = true;

  const { data, loading, error, refetch } = useQuery<StatesData>(STATES, {
    variables: {
      includeDeleted: true,
    },
    fetchPolicy: "no-cache",
  });
  const codes = data ? data.states : undefined;

  const [deleteCode, { loading: deleteRunning }] = useMutation<
    DeleteStateData,
    DeleteStateVariables
  >(DELETE_STATE, {
    onCompleted: (data) => {
      message.success("Record successfully deleted.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to delete record!");
    },
  });

  const [undeleteCode, { loading: undeleteRunning }] = useMutation<
    UndeleteStateData,
    UndeleteStateVariables
  >(UNDELETE_STATE, {
    onCompleted: (data) => {
      message.success("Record successfully restored.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to restore record!");
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} error />
      </div>
    );
  }

  return (
    <CodeListManager
      codeType={codeType}
      codeTypePlural={codeTypePlural}
      canAddCodes={canAddCodes}
      codes={codes}
      deleteRunning={deleteRunning}
      undeleteRunning={undeleteRunning}
      deleteCode={deleteCode}
      undeleteCode={undeleteCode}
    />
  );
};
