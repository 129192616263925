import { gql } from "apollo-boost";

export const MERGE_STUDENTS = gql`
  mutation MergeStudents($targetStudentID: String!, $sourceStudentID: String!) {
    mergeStudents(targetStudentID: $targetStudentID, sourceStudentID: $sourceStudentID) {
        id
        studentIdentifier
        dateOfBirth
        ssn
        coverageStartDate
        coverageEffectiveDate
        coverageTerminationDate
        
        contactId
        permanentContactId
        universityId
        universityCampusId
        contactTypeId
        studentTypeId
        genderId
        languageId

        contact{
        id
        firstName
        middleName
        lastName
        addressLine1
        addressLine2
        city
        stateId
        state{
            code
            name
        }
        zipCode
        primaryPhone
        altPhone
        email
        altEmail
        },

        insurances{
        id
        isActive
        insuranceCarrier
        idNumber
        startOfCoverageDate
        endOfCoverageDate
        },

        permanentContact{
        id
        addressLine1
        addressLine2
        city
        stateId
        state{
            code
            name
        }
        zipCode
        },

        university{
        shortName
        name
        avatar
        },

        universityCampus{
        name
        },

        contactType{
        code
        name
        },
        gender{
        code
        name
        },
        language{
        code
        name
        },
        studentType{
        code
        name
        }
    }
  }
`;