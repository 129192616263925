import { gql } from "apollo-boost";
 
export const FOLLOWUP_TYPE = gql`
  query FollowupType($id: String!) {
    followupType(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
