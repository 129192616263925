import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import {
  message,
  Button,
  Form,
  Input,
  Select,
  Spin,
  Card,
  Popconfirm,
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import { MergeDuplicateStudentsSkeleton } from "../MergeDuplicateStudentsSkeleton";
import { STATES, States as StatesData } from "../../../lib/graphql/queries";

import {
  STUDENT,
  Student as StudentData,
  StudentVariables,
  STUDENT_BY_STUDENT_IDENTIFIER,
  StudentByStudentIdentifier as StudentByStudentIdentifierData,
  StudentByStudentIdentifierVariables,
} from "../../../lib/graphql/queries";
import {
  MERGE_STUDENTS,
  MergeStudents as MergeStudentsData,
  MergeStudentsVariables,
} from "../../../lib/graphql/mutations/app";
import { anyAuth, AuthVisible, hasAuthRole } from "../../../lib/utils";

interface MatchParams {
  id: string;
  id2: string;
}
interface Props {
  title: string;
}

export const MergeDuplicateStudentsBase = ({
  title,
  form,
}: Props & FormComponentProps) => {
  const { id, id2 } = useParams<MatchParams>();
  const history = useHistory();
  const { Option } = Select;
  const dashUrl = "/";
  const mergedUrl = "/tools/duplicateStudent";

  const { data: statesData } = useQuery<StatesData>(STATES, {
    fetchPolicy: "no-cache",
  });

  const queryStudentTarget = useQuery<StudentData, StudentVariables>(STUDENT, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
    skip: id === "0",
    onCompleted: (data) => {
      if (data) {
        const record = data.student ? data.student : null;
        if (record) {
          form.setFieldsValue({
            idTarget: record.id,
            firstNameTarget: record.contact.firstName,
            middleNameTarget: record.contact.middleName,
            lastNameTarget: record.contact.lastName,
            emailTarget: record.contact.email,
            altEmailTarget: record.contact.altEmail,
            primaryPhoneTarget: record.contact.primaryPhone,
            altPhoneTarget: record.contact.altPhone,
            addressLine1Target: record.contact.addressLine1,
            addressLine2Target: record.contact.addressLine2,
            cityTarget: record.contact.city,
            stateIdTarget: `${record.contact.stateId}`,
            zipCodeTarget: record.contact.zipCode,
            universityTarget: record.university.name,
            universityCampusTarget: record.universityCampus?.name,
            contactTypeTarget: record.contactType?.name,
            studentIdentifierTarget: record.studentIdentifier,
            studentTypeTarget: record.studentType?.name,
            coverageStartDateTarget: record.coverageStartDate,
            coverageEffectiveDataTarget: record.coverageEffectiveDate,
            coverageTerminationDateTarget: record.coverageTerminationDate,
            ssnTarget: record.ssn,
            dateOfBirthTarget: record.dateOfBirth,
            genderTarget: record.gender?.name,
            languageTarget: record.language?.name,
          });
        }
      }
    },
    onError: (data) => {
      console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to fetch the target student record.`);
    },
  });

  useQuery<StudentData, StudentVariables>(STUDENT, {
    variables: {
      id: id2,
    },
    fetchPolicy: "no-cache",
    skip: id2 === "0",
    onCompleted: (data) => {
      if (data) {
        const record = data.student ? data.student : null;
        if (record) {
          form.setFieldsValue({
            idSource: record.id,
            firstNameSource: record.contact.firstName,
            middleNameSource: record.contact.middleName,
            lastNameSource: record.contact.lastName,
            emailSource: record.contact.email,
            altEmailSource: record.contact.altEmail,
            primaryPhoneSource: record.contact.primaryPhone,
            altPhoneSource: record.contact.altPhone,
            addressLine1Source: record.contact.addressLine1,
            addressLine2Source: record.contact.addressLine2,
            citySource: record.contact.city,
            stateIdSource: `${record.contact.stateId}`,
            zipCodeSource: record.contact.zipCode,
            universitySource: record.university.name,
            universityCampusSource: record.universityCampus?.name,
            contactTypeSource: record.contactType?.name,
            studentIdentifierSource: record.studentIdentifier,
            studentTypeSource: record.studentType?.name,
            coverageStartDateSource: record.coverageStartDate,
            coverageEffectiveDataSource: record.coverageEffectiveDate,
            coverageTerminationDateSource: record.coverageTerminationDate,
            ssnSource: record.ssn,
            dateOfBirthSource: record.dateOfBirth,
            genderSource: record.gender?.name,
            languageSource: record.language?.name,
          });
        }
      }
    },
    onError: (data) => {
      console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to fetch the source student record.`);
    },
  });

  const [lookupStudentsSource] = useLazyQuery<
    StudentByStudentIdentifierData,
    StudentByStudentIdentifierVariables
  >(STUDENT_BY_STUDENT_IDENTIFIER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data) {
        const record = data.studentByStudentIdentifier
          ? data.studentByStudentIdentifier
          : null;
        if (record) {
          form.setFieldsValue({
            idSource: record.id,
            firstNameSource: record.contact.firstName,
            middleNameSource: record.contact.middleName,
            lastNameSource: record.contact.lastName,
            emailSource: record.contact.email,
            altEmailSource: record.contact.altEmail,
            primaryPhoneSource: record.contact.primaryPhone,
            altPhoneSource: record.contact.altPhone,
            addressLine1Source: record.contact.addressLine1,
            addressLine2Source: record.contact.addressLine2,
            citySource: record.contact.city,
            stateIdSource: `${record.contact.stateId}`,
            zipCodeSource: record.contact.zipCode,
            universitySource: record.university.name,
            universityCampusSource: record.universityCampus?.name,
            contactTypeSource: record.contactType?.name,
            studentIdentifierSource: record.studentIdentifier,
            studentTypeSource: record.studentType?.name,
            coverageStartDateSource: record.coverageStartDate,
            coverageEffectiveDataSource: record.coverageEffectiveDate,
            coverageTerminationDateSource: record.coverageTerminationDate,
            ssnSource: record.ssn,
            dateOfBirthSource: record.dateOfBirth,
            genderSource: record.gender?.name,
            languageSource: record.language?.name,
          });
        }
      }
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Source student lookup failed!");
    },
  });

  const [lookupStudentsTarget, { loading: lookupRunningTarget }] = useLazyQuery<
    StudentByStudentIdentifierData,
    StudentByStudentIdentifierVariables
  >(STUDENT_BY_STUDENT_IDENTIFIER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data) {
        const record = data.studentByStudentIdentifier
          ? data.studentByStudentIdentifier
          : null;
        if (record) {
          form.setFieldsValue({
            idTarget: record.id,
            firstNameTarget: record.contact.firstName,
            middleNameTarget: record.contact.middleName,
            lastNameTarget: record.contact.lastName,
            emailTarget: record.contact.email,
            altEmailTarget: record.contact.altEmail,
            primaryPhoneTarget: record.contact.primaryPhone,
            altPhoneTarget: record.contact.altPhone,
            addressLine1Target: record.contact.addressLine1,
            addressLine2Target: record.contact.addressLine2,
            cityTarget: record.contact.city,
            stateIdTarget: `${record.contact.stateId}`,
            zipCodeTarget: record.contact.zipCode,
            universityTarget: record.university.name,
            universityCampusTarget: record.universityCampus?.name,
            contactTypeTarget: record.contactType?.name,
            studentIdentifierTarget: record.studentIdentifier,
            studentTypeTarget: record.studentType?.name,
            coverageStartDateTarget: record.coverageStartDate,
            coverageEffectiveDataTarget: record.coverageEffectiveDate,
            coverageTerminationDateTarget: record.coverageTerminationDate,
            ssnTarget: record.ssn,
            dateOfBirthTarget: record.dateOfBirth,
            genderTarget: record.gender?.name,
            languageTarget: record.language?.name,
          });
        }
      }
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Target student lookup failed!");
    },
  });

  const [mergeStudents] = useMutation<
    MergeStudentsData,
    MergeStudentsVariables
  >(MERGE_STUDENTS, {
    onCompleted: (data) => {
      message.success("Records successfully merged.");
    },
    onError: (data) => {
      console.log(`Merge error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to merge record!");
    },
  });

  if (queryStudentTarget.loading) {
    return (
      <div className="admin">
        <MergeDuplicateStudentsSkeleton title={title} />
      </div>
    );
  }

  if (queryStudentTarget.error) {
    return (
      <div className="admin">
        <MergeDuplicateStudentsSkeleton title={title} error />
      </div>
    );
  }

  const gotoDash = () => {
    history.push(dashUrl);
  };

  const gotoMerged = (id: string) => {
    history.push(`${mergedUrl}/${id}/0`);
  };

  const handleCancel = (e: any) => {
    message.info("No changes saved.");
    gotoDash();
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      await mergeStudents({
        variables: {
          sourceStudentID: values.idSource,
          targetStudentID: values.idTarget,
        },
      });

      gotoMerged(values.idTarget);
    });
  };

  const handleSearchTarget = (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error(err);
        message.error("No search terms provided");
        return;
      }

      lookupStudentsTarget({
        variables: { studentIdentifier: values.studentIdentifierTarget },
      });
    });
  };

  const handleSearchSource = (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("No search terms provided.");
        return;
      }

      lookupStudentsSource({
        variables: { studentIdentifier: values.studentIdentifierSource },
      });
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Navigator"),
          hasAuthRole("AccountManager"),
          hasAuthRole("PortalManager")
        )}
      >
        <div>
          <Spin spinning={lookupRunningTarget}>
            <div>
              <Helmet
                title={`Merge Duplicate Student
            }`}
              />
              <div className="air__utils__heading">
                <h5>
                  <Link to={`${dashUrl}`}>Merge Duplicate Student</Link>
                </h5>
              </div>

              <Form {...formItemLayout} labelAlign="left">
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <Card
                      type="inner"
                      size="small"
                      title="Target Student Record"
                    >
                      <div className="row">
                        <div className="col-xl-12 col-lg-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <Form.Item label="Student ID">
                                    {form.getFieldDecorator(
                                      "studentIdentifierTarget",
                                      {
                                        rules: [{ max: 100 }],
                                      }
                                    )(
                                      <Input
                                        placeholder="Enter a student's id"
                                        onPressEnter={handleSearchTarget}
                                      />
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="col-md-3">
                                  <Form.Item label="ID">
                                    {form.getFieldDecorator("idTarget", {
                                      rules: [{ max: 100 }],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-1">
                                  <Form.Item label=" ">
                                    {form.getFieldDecorator("confirm4")(
                                      <button
                                        type="button"
                                        className="btn btn-light px-5"
                                        onClick={handleSearchTarget}
                                      >
                                        Search
                                      </button>
                                    )}
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <Form.Item label="First Name">
                                    {form.getFieldDecorator("firstNameTarget", {
                                      rules: [{ max: 100 }],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-6">
                                  <Form.Item label="Last Name">
                                    {form.getFieldDecorator("lastNameTarget", {
                                      rules: [{ max: 100 }],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-8">
                                  <Form.Item label="Primary Phone">
                                    {form.getFieldDecorator(
                                      "primaryPhoneTarget",
                                      {
                                        rules: [{ max: 20 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-3">&nbsp;</div>
                                <div className="col-md-4">
                                  <Form.Item label="Email">
                                    {form.getFieldDecorator("emailSource", {
                                      rules: [
                                        {
                                          max: 100,
                                          type: "email",
                                          message:
                                            "The input is not valid E-mail!",
                                        },
                                        {
                                          message: "Please input your E-mail!",
                                        },
                                      ],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="row">
                                <div className="border-top col-md-12">
                                  <strong>
                                    <u>Student Address</u>
                                  </strong>
                                </div>
                                <div className="col-md-5">
                                  <Form.Item label="Address">
                                    {form.getFieldDecorator(
                                      "addressLine1Target",
                                      {
                                        rules: [{ max: 100 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-6">&nbsp;</div>
                                <div className="col-md-6">
                                  <Form.Item label="Address 2">
                                    {form.getFieldDecorator(
                                      "addressLine2Target",
                                      {
                                        rules: [{ max: 100 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-6">&nbsp;</div>
                                <div className="col-md-3">
                                  <Form.Item label="City">
                                    {form.getFieldDecorator("cityTarget", {
                                      rules: [{ max: 100 }],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-4">
                                  <Form.Item label="State">
                                    {form.getFieldDecorator("stateIdTarget", {
                                      rules: [
                                        {
                                          message: "Please select your state!",
                                        },
                                      ],
                                    })(
                                      <Select disabled>
                                        {statesData &&
                                          statesData.states.map((x) => {
                                            return (
                                              <Option
                                                key={`${x.id}`}
                                                value={`${x.id}`}
                                              >
                                                {x.name}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="col-md-3">
                                  <Form.Item label="Zip">
                                    {form.getFieldDecorator("zipCodeTarget", {
                                      rules: [{ max: 20 }],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-5">
                                  <Form.Item label="University">
                                    {form.getFieldDecorator(
                                      "universityTarget",
                                      {
                                        rules: [{ max: 100 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-5">
                                  <Form.Item label="Campus">
                                    {form.getFieldDecorator(
                                      "universityCampusTarget",
                                      {
                                        rules: [{ max: 100 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>

                                <div className="col-md-7">
                                  <Form.Item label="Student Type">
                                    {form.getFieldDecorator(
                                      "studentTypeTarget",
                                      {
                                        rules: [{ max: 100 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>

                                <div className="col-md-10">
                                  <Form.Item label="Coverage Start Date">
                                    {form.getFieldDecorator(
                                      "coverageStartTarget",
                                      {
                                        rules: [{ max: 20 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-12">
                                  <Form.Item label="Coverage Effective Date">
                                    {form.getFieldDecorator(
                                      "coverageEffectiveDateTarget",
                                      {
                                        rules: [{ max: 20 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-12">
                                  <Form.Item label="Coverage Termination Date">
                                    {form.getFieldDecorator(
                                      "coverageTerminationDateTarget",
                                      {
                                        rules: [{ max: 100 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-4">
                                  <Form.Item label="SSN">
                                    {form.getFieldDecorator("ssnTarget", {
                                      rules: [{ max: 100 }],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-7">
                                  <Form.Item label="Date of Birth">
                                    {form.getFieldDecorator(
                                      "dateOfBirthTarget",
                                      {
                                        rules: [{ max: 100 }],
                                      }
                                    )(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-5">
                                  <Form.Item label="Gender">
                                    {form.getFieldDecorator("genderTarget", {
                                      rules: [{ max: 100 }],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-6">
                                  <Form.Item label="Language">
                                    {form.getFieldDecorator("languageTarget", {
                                      rules: [{ max: 100 }],
                                    })(<Input disabled />)}
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>

                  {((id === "0" && id2 === "0") ||
                    (id !== "0" && id2 !== "0")) && (
                    <div className="col-lg-6 mb-4">
                      <Card
                        type="inner"
                        size="small"
                        title="Source Student Record"
                      >
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-6">
                                    <Form.Item label="Student ID">
                                      {form.getFieldDecorator(
                                        "studentIdentifierSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(
                                        <Input
                                          placeholder="Enter a student's id"
                                          onPressEnter={handleSearchSource}
                                        />
                                      )}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-3">
                                    <Form.Item label="ID">
                                      {form.getFieldDecorator("idSource", {
                                        rules: [{ max: 100 }],
                                      })(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-1">
                                    <Form.Item label=" ">
                                      {form.getFieldDecorator("confirm4")(
                                        <button
                                          type="button"
                                          className="btn btn-light px-5"
                                          onClick={handleSearchSource}
                                        >
                                          Search
                                        </button>
                                      )}
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-6">
                                    <Form.Item label="First Name">
                                      {form.getFieldDecorator(
                                        "firstNameSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-6">
                                    <Form.Item label="Last Name">
                                      {form.getFieldDecorator(
                                        "lastNameSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-8">
                                    <Form.Item label="Primary Phone">
                                      {form.getFieldDecorator(
                                        "primaryPhoneSource",
                                        {
                                          rules: [{ max: 20 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-3">&nbsp;</div>
                                  <div className="col-md-4">
                                    <Form.Item label="Email">
                                      {form.getFieldDecorator("emailSource", {
                                        rules: [
                                          {
                                            max: 100,
                                            type: "email",
                                            message:
                                              "The input is not valid E-mail!",
                                          },
                                          {
                                            message:
                                              "Please input your E-mail!",
                                          },
                                        ],
                                      })(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="border-top col-md-12">
                                    <strong>
                                      <u>Student Address</u>
                                    </strong>
                                  </div>
                                  <div className="col-md-5">
                                    <Form.Item label="Address">
                                      {form.getFieldDecorator(
                                        "addressLine1Source",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-6">&nbsp;</div>
                                  <div className="col-md-6">
                                    <Form.Item label="Address 2">
                                      {form.getFieldDecorator(
                                        "addressLine2Source",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-6">&nbsp;</div>
                                  <div className="col-md-3">
                                    <Form.Item label="City">
                                      {form.getFieldDecorator("citySource", {
                                        rules: [{ max: 100 }],
                                      })(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-4">
                                    <Form.Item label="State">
                                      {form.getFieldDecorator("stateIdSource", {
                                        rules: [
                                          {
                                            message:
                                              "Please select your state!",
                                          },
                                        ],
                                      })(
                                        <Select disabled>
                                          {statesData &&
                                            statesData.states.map((x) => {
                                              return (
                                                <Option
                                                  key={`${x.id}`}
                                                  value={`${x.id}`}
                                                >
                                                  {x.name}
                                                </Option>
                                              );
                                            })}
                                        </Select>
                                      )}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-3">
                                    <Form.Item label="Zip">
                                      {form.getFieldDecorator("zipCodeSource", {
                                        rules: [{ max: 20 }],
                                      })(<Input disabled />)}
                                    </Form.Item>
                                  </div>

                                  <div className="col-md-6">
                                    <Form.Item label="University">
                                      {form.getFieldDecorator(
                                        "universitySource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-5">
                                    <Form.Item label="Campus">
                                      {form.getFieldDecorator(
                                        "universityCampusSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-7">
                                    <Form.Item label="Student Type">
                                      {form.getFieldDecorator(
                                        "studentTypeSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>

                                  <div className="col-md-10">
                                    <Form.Item label="Coverage Start Date">
                                      {form.getFieldDecorator(
                                        "coverageStartSource",
                                        {
                                          rules: [{ max: 20 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-12">
                                    <Form.Item label="Coverage Effective Date">
                                      {form.getFieldDecorator(
                                        "coverageEffectiveDateSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-12">
                                    <Form.Item label="Coverage Termination Date">
                                      {form.getFieldDecorator(
                                        "coverageTerminationDateSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-4">
                                    <Form.Item label="SSN">
                                      {form.getFieldDecorator("ssnSource", {
                                        rules: [{ max: 100 }],
                                      })(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-7">
                                    <Form.Item label="Date of Birth">
                                      {form.getFieldDecorator(
                                        "dateOfBirthSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-5">
                                    <Form.Item label="Gender">
                                      {form.getFieldDecorator("genderSource", {
                                        rules: [{ max: 100 }],
                                      })(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                  <div className="col-md-6">
                                    <Form.Item label="Language">
                                      {form.getFieldDecorator(
                                        "languageSource",
                                        {
                                          rules: [{ max: 100 }],
                                        }
                                      )(<Input disabled />)}
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="form-actions col-md-12">
                    <Popconfirm
                      title={`Are you sure you want to merge?`}
                      onConfirm={handleSave}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button>Merge</Button>
                    </Popconfirm>
                    <Button onClick={handleCancel}>Cancel</Button>
                  </div>
                </div>
              </Form>
            </div>
          </Spin>
        </div>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"), 
          hasAuthRole("Auditor"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};

export const MergeDuplicateStudents = Form.create<Props & FormComponentProps>({
  name: "duplicate_student_form",
})(MergeDuplicateStudentsBase);
