import { gql } from "apollo-boost";
 
export const CALL_TYPE = gql`
  query CallType($id: String!) {
    callType(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
