import React from "react";

interface Props {
  text: any
  isRecordDeleted: any
}

export const ShowDeletableRecordText = ({ text, isRecordDeleted}: Props) => {
  return (
    isRecordDeleted ? (
      <span>
        <del>{text}</del>
      </span>
    ) : (
      <span>
        {text}
      </span>
    )
  );
};
