import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { message, Button, Form, Icon, Input, Modal, Select, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  CALL_NOTE,
  CallNote as CallNoteData,
  CallNoteVariables,
} from "../../../../lib/graphql/queries";

import {
  CREATE_CALL_NOTE,
  CreateCallNote as CreateCalllNoteData,
  CreateCallNoteVariables,
  UPDATE_CALL_NOTE,
  UpdateCallNote as UpdateCallNoteData,
  UpdateCallNoteVariables,
} from "../../../../lib/graphql/mutations";

import {
  NOTE_TYPES,
  NoteTypes as NoteTypesData,
} from "../../../../lib/graphql/queries";

interface FormProps {
  id: string | null;
  callId: string | null;
  callFollowupId: string | null | undefined;
  listRefetch: () => void;
  visible: boolean;
  setVisible: (input: any) => void;
}

interface AddProps {
  callId: string | null;
  callFollowupId: string | null | undefined;
  listRefetch: () => void;
}

interface EditProps {
  id: string;
  callId: string | null;
  callFollowupId: string | null | undefined;
  listRefetch: () => void;
}

const { Option } = Select;
const { TextArea } = Input;

const StudentCallNoteModalFormBase = ({
  id,
  callId,
  callFollowupId,
  listRefetch,
  visible,
  setVisible,
  form,
}: FormProps & FormComponentProps) => {
  let isNew = id ? false : true;

  const { data: noteTypesData } = useQuery<NoteTypesData>(NOTE_TYPES, {
    fetchPolicy: "no-cache",
  });

  const { loading } = useQuery<CallNoteData, CallNoteVariables>(CALL_NOTE, {
    variables: {
      id: `${id}`,
    },
    fetchPolicy: "no-cache",
    skip: isNew,
    onCompleted: (data) => {
      if (data) {
        const record = data.callNote ? data.callNote : null;
        if (record) {
          form.setFieldsValue({
            noteTypeId: `${record.noteTypeId}`,
            note: record.note,
          });
        }
      } else {
        form.setFieldsValue({
          noteTypeId: `1`,
          note: null,
        });
    }
    },
    onError: (data) => {
      console.log(`StudentCallNoteModalFormBase - Query error - callNote: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to fetch the note record.`);
    },
  });

  const [createCallNote, { loading: createRunning }] = useMutation<
    CreateCalllNoteData,
    CreateCallNoteVariables
  >(CREATE_CALL_NOTE, {
    onCompleted: (data) => {
      message.success("Note added successfully.");

      form.resetFields();
      setVisible(false);

      listRefetch();
    },
    onError: (data) => {
      console.log(`StudentCallNoteModalFormBase - Create error - callNote: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update the note!");
    },
  });

  const [updateCallNote, { loading: updateRunning }] = useMutation<
    UpdateCallNoteData,
    UpdateCallNoteVariables
  >(UPDATE_CALL_NOTE, {
    onCompleted: (data) => {
      message.success("Note updated successfully.");

      form.resetFields();
      setVisible(false);

      listRefetch();
    },
    onError: (data) => {
      console.log(`StudentCallNoteModalFormBase - Update error - callNote: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update the note!");
    },
  });

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (isNew) {
        const callNote = {
          callId: callFollowupId ? null : (callId ? +callId : null),
          callFollowupId: callFollowupId ? +callFollowupId : null,
          noteTypeId: +values.noteTypeId,
          note: values.note,
        };

        createCallNote({
          variables: {
            data: callNote,
          },
        });
      } else {
        const callNote = {
          noteTypeId: +values.noteTypeId,
          note: values.note,
        };
  
        updateCallNote({
          variables: {
            id: `${id}`,
            data: callNote,
          },
        });
      }
    });
  };

  return (
    <Modal
      visible={visible}
      title={
        isNew
          ? "Add a " + (callFollowupId ? "Followup" : "Call") + " Note"
          : "Update a " + (callFollowupId ? "Followup" : "Call") + " Note"
      }
      okText={isNew ? "Create" : "Update"}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Spin spinning={loading || createRunning || updateRunning}>
        <Form>
          <div className="row">
            <div className="col-md-12">
              <Form.Item>
                {form.getFieldDecorator("note")(
                  <TextArea
                    autoSize={{ minRows: 5, maxRows: 30 }}
                    placeholder="add notes..."
                  />
                )}
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item label="Note Type:">
                {form.getFieldDecorator("noteTypeId")(
                  <Select>
                    {noteTypesData &&
                      noteTypesData.noteTypes.map((x) => {
                        return (
                          <Option key={`${x.id}`} value={`${x.id}`}>
                            {x.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export const StudentCallNoteModalForm = Form.create<
  FormProps & FormComponentProps
>({
  name: "admin_university_campus_form",
})(StudentCallNoteModalFormBase);

export const EditStudentCallNoteIcon = ({
  id,
  callId,
  callFollowupId,
  listRefetch,
}: EditProps) => {
  let [visible, setVisible] = useState(false);

  console.log("EditStudentCallNoteIcon()");

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ marginLeft: "10px", display: "inline-block" }}>
      <Icon type="edit" onClick={() => showModal()} />
      {visible && (
        <StudentCallNoteModalForm
          id={id}
          callId={callId}
          callFollowupId={callFollowupId}
          listRefetch={listRefetch}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </div>
  );
};

export const AddStudentCallNoteButton = ({
  callId,
  callFollowupId,
  listRefetch,
}: AddProps) => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button
        type="link"
        className="text-blue p-b-5"
        onClick={() => showModal()}
      >
        <Icon type="form" />
        &nbsp;add notes
      </Button>
      {visible && (
        <StudentCallNoteModalForm
          id={null}
          callId={callId}
          callFollowupId={callFollowupId}
          listRefetch={listRefetch}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </div>
  );
};
