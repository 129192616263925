import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { message, Button, Form, Input, Modal, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  AUTH_UPDATE_PASSWORD,
  AuthUpdatePassword as AuthUpdatePasswordData,
  AuthUpdatePasswordVariables,
} from "../../../../lib/graphql/mutations";

interface FormProps {
  userId: number;
  visible: boolean;
  setVisible: (input: any) => void;
}

interface ButtonProps {
  userId: number;
}

const ChangeMyPasswordModalFormBase = ({
  userId,
  visible,
  setVisible,
  form,
}: FormProps & FormComponentProps) => {
  let [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);

  const [authUpdatePassword, { loading: updateRunning }] = useMutation<
    AuthUpdatePasswordData,
    AuthUpdatePasswordVariables
  >(AUTH_UPDATE_PASSWORD, {
    onCompleted: (data) => {
      message.success("Password changed successfully.");

      form.resetFields();
      setVisible(false);
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error(
        "Failed to update the password, please check your current password!"
      );
    },
  });

  const handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    setConfirmPasswordDirty(confirmPasswordDirty || !!value);
  };

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Password does not match your confirmation password!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: any, callback: any) => {
    if (value && confirmPasswordDirty) {
      form.validateFields(["confirmNewPassword"], { force: true });
    }
    callback();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      authUpdatePassword({
        variables: {
          id: `${userId}`,
          password: values.password,
          newPassword: values.newPassword,
        },
      });
    });
  };

  return (
    <Modal
      visible={visible}
      title="Change the Password"
      okText="Update"
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Spin spinning={updateRunning}>
        <Form layout="vertical">
          <Form.Item label="Current Password">
            {form.getFieldDecorator("password", {
              rules: [
                {
                  required: true,
                  max: 100,
                  message: "Enter your current password!",
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <hr />
          <Form.Item label="New Password" hasFeedback>
            {form.getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  min: 8,
                  max: 50,
                  message: "Enter a new password of 8 characters or more!",
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm New Password" hasFeedback>
            {form.getFieldDecorator("confirmNewPassword", {
              rules: [
                {
                  required: true,
                  max: 50,
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={handleConfirmBlur} />)}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export const ChangeMyPasswordModalForm = Form.create<
  FormProps & FormComponentProps
>({
  name: "admin_update_username_form",
})(ChangeMyPasswordModalFormBase);

export const ChangeMyPasswordButton = ({ userId }: ButtonProps) => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: "inline-block", marginTop: "15px" }}>
      <Button onClick={() => showModal()} size="small" shape="round">
        Change Password
      </Button>
      <ChangeMyPasswordModalForm
        userId={userId}
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  );
};
