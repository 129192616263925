import React from "react";
import { Alert, Divider, Skeleton } from "antd";
import "./styles/CodesSkeleton.css";

interface Props {
  title: string;
  error?: boolean;
}

export const CodesSkeleton = ({ title, error = false }: Props) => {
  const errorAlert = error ? (
    <Alert
      type="error"
      message="Uh oh! Something went wrong :(. Please try again later."
      className="codes-skeleton__alert"
    />
  ) : null;

  return (
    <div className="codes-skeleton">
      {errorAlert}
      <h2>{title}</h2>
      <Skeleton active paragraph={{ rows: 1 }} />
      <Divider />
      <Skeleton active paragraph={{ rows: 1 }} />
      <Divider />
      <Skeleton active paragraph={{ rows: 1 }} />
    </div>
  );
};
