import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Icon, Menu, message } from "antd";

import { UserContext } from "../../../../../lib/utils";
import { useMutation } from "@apollo/react-hooks";

import {
  AUTH_LOGOUT,
  AuthLogout as AuthLogoutData
} from "../../../../../lib/graphql/mutations";

export const MenuItems = () => {
  const { user, setUser } = useContext(UserContext);
  const history = useHistory();
  const homeUrl = "/";

  const [authLogout/*, { loading: loginRunning }*/] = useMutation<
    AuthLogoutData
  >(AUTH_LOGOUT, {
    onCompleted: (data) => {
      message.success("Logged out successfully.");

      setUser(null);
      sessionStorage.removeItem("user");     
      history.push(homeUrl);
    },
    onError: (data) => {
      console.log(`Logout error: ${JSON.stringify(data, null, 2)}`);

      setUser(null);
      sessionStorage.removeItem("user");     
      history.push(homeUrl);
    },
  });

  const logout = () => {
    // setUser(null);
    // sessionStorage.removeItem("user");
    authLogout();
    // history.push(homeUrl);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={`/my-profile`}>
          <strong>User:&nbsp;</strong>
          {user ? user.firstName + " " + user.lastName : ""}
          <div>
            <strong>Role:&nbsp;</strong>
            {user && user.role ? user.role.name : ""}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to={`/my-profile`}>
          <div>
            <strong>Email:&nbsp;</strong>
            {user ? user.email : ""}
            <br />
            <strong>Phone:&nbsp;</strong>
            {user ? user.primaryPhone : ""}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to={`/my-profile`}>
          <span>
            <Icon type="user" />
            <span style={{ marginLeft: "5px" }}>Edit Profile</span>
          </span>
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={logout}>
        <span>
          <Icon type="logout" />
          <span style={{ marginLeft: "5px" }}>Logout</span>
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Menu mode="horizontal" className="menu">
      <Dropdown overlay={menu} trigger={["click"]}>
        <div>
          <a href="/" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <div className="app-header__logo">
              <img
                src={`/avatar/user/${user.id}`}
                alt={
                  user ? user.firstName + " " + user.lastName : "Current User"
                }
              /><Icon type="caret-down" />
            </div>
          </a>
        </div>
      </Dropdown>
    </Menu>
  );
};
