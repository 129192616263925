import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  ROLE,
  Role as RoleData,
  RoleVariables,
} from "../../../../lib/graphql/queries";
import {
  CREATE_ROLE,
  CreateRole as CreateRoleData,
  CreateRoleVariables,
  UPDATE_ROLE,
  UpdateRole as UpdateRoleData,
  UpdateRoleVariables,
} from "../../../../lib/graphql/mutations/codes";
import { CodeForm, SetCodeFormValues } from "../../components"

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

export const RoleFormBase = ({ title, form }: Props & FormComponentProps) => {
  const codeType = "Role";
  const codeTypePlural = "Roles";
  const { id } = useParams<MatchParams>();

  const { loading, error } = useQuery<RoleData, RoleVariables>(
    ROLE,
    {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
      skip: id === "0",
      onCompleted: (data) => {
        if(!data)return;

        SetCodeFormValues(form, data.role);
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  const [createCode, { loading: createRunning }] = useMutation<
    CreateRoleData,
    CreateRoleVariables
  >(CREATE_ROLE, {
    onCompleted: (data) => {
      message.success("Record successfully created.");
    },
    onError: (data) => {
      console.log(`Create error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to create record!");
    },
  });

  const [updateCode, { loading: updateRunning }] = useMutation<
    UpdateRoleData,
    UpdateRoleVariables
  >(UPDATE_ROLE, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
    },
  });

  return (
    <CodeForm
      form={form} 
      title={title} 
      codeType={codeType} 
      codeTypePlural={codeTypePlural}
      isNew={(id === '0')}
      codeMaxLength={2}
      hasDescription={false}
      queryLoading={loading} 
      queryError={error} 
      createCode={createCode} 
      createRunning={createRunning} 
      updateCode={updateCode} 
      updateRunning={updateRunning}
    />
  );
};

export const RoleForm = Form.create<Props & FormComponentProps>({
  name: "admin_code_role_form",
})(RoleFormBase);
