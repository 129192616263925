import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
// import { Empty, Typography } from "antd";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Button, message, Table } from "antd";

import {
  REPORT_RESULT,
  ReportResult as ReportResultData,
  ReportResultVariables,
} from "../../../lib/graphql/queries";
import { ReportFollowups } from "./components";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../lib/utils";

interface MatchParams {
  id: string;
  src: string;
}
interface Props {
  title: string;
}
interface ReportPagingInfo {
  skip: number;
  take: number;
  total: number;
}

// const { Text } = Typography;

moment.tz.setDefault("America/New_York");
// const dateFormat = "MM/DD/YYYY";

export const ReportViewerTasks = ({ title }: Props) => {
  const { id, src } = useParams<MatchParams>();
  const dashUrl = `/reporting`;
  const reportRunnerBaseUrl = `/reporting/run-report/`;
  const reportHistoryBaseUrl = `/reporting/history/`;
  let [reportProfileId, setReportProfileId] = useState<String | null>(null);
  let [reportTitle, setReportTitle] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [reportTasksTitle, setReportTasksTitle] = useState<String | null>(null);
  let [reportTasksIdCsv, setReportTasksIdCsv] = useState<string | null>(null);
  let [reportColumns, setReportColumns] = useState<any[]>([]);
  let [reportData, setReportData] = useState<any[]>([]);

  useQuery<ReportResultData, ReportResultVariables>(REPORT_RESULT, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
    skip: id === "0",
    onCompleted: (data) => {
      if (data) {
        const reportResult = data.reportResult ? data.reportResult : null;
        if (reportResult) {
          // console.log("ReportProfile: ", reportResult);
          setReportProfileId(`${reportResult.reportProfileId}`);
          setReportTitle(reportResult.title);
          setReportData(reportResult.resultData);

          const fields = reportResult.resultColumns.gridFields
            ? reportResult.resultColumns.gridFields
            : reportResult.resultColumns.fields;

          // Extract the fields from the resultData into data rows
          let columns = [];
          if (fields)
            for (let i = 0; i < fields.length; i++) {
              if (reportResult.resultColumns.meta[fields[i]].isHidden) {
                continue;
              }

              let colInfo = reportResult.resultColumns.meta[fields[i]];

              let field = fields[i];
              let title = colInfo && colInfo.title ? colInfo.title : fields[i];

              if (colInfo.isLink && colInfo.linkParams) {
                let render = (text: any, row: any) => (
                  <span>
                    <Button
                      onClick={() => handleViewTasks(row[colInfo.linkParams])}
                      type="link"
                    >
                      {text}
                    </Button>
                  </span>
                );

                columns.push({
                  dataIndex: field,
                  key: field,
                  title: title,
                  render: render,
                });
              } else {
                columns.push({ dataIndex: field, key: field, title: title });
              }
            }
          setReportColumns(columns);
        } else {
          setReportData([]);
          setReportColumns([]);
        }
      }
    },
    onError: (data) => {
      console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to fetch the record.`);
    },
  });

  const handleViewTasks = (idCsv: string) => {
    setReportTasksIdCsv(idCsv);
    console.log("idCsv: ", idCsv);
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Navigator"),
          hasAuthRole("AccountManager"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <div>
          <Helmet title="Report" />
          <div className="air__utils__heading">
            <h5>
              <Link to={`${dashUrl}`}>Reports</Link>&nbsp;:&nbsp;
              {src === "new" && (
                <Link to={`${reportRunnerBaseUrl}${reportProfileId}`}>
                  {reportTitle}
                </Link>
              )}
              {src === "history" && (
                <Link to={`${reportHistoryBaseUrl}`}>Report History</Link>
              )}
              &nbsp;:&nbsp; View
            </h5>
          </div>

          <div>
            <div>
              <Button
                href={"/file/report/pdf/" + id}
                type="primary"
                shape="round"
                icon="download"
                size="small"
              >
                Download PDF
              </Button>
              &nbsp;&nbsp;
              <Button
                href={"/file/report/xlsx/" + id}
                type="primary"
                shape="round"
                icon="download"
                size="small"
              >
                Download Excel
              </Button>
              &nbsp;&nbsp;
              <Button
                href={"/file/report/csv/" + id}
                type="primary"
                shape="round"
                icon="download"
                size="small"
              >
                Download CSV
              </Button>
              &nbsp;&nbsp;
              <Button
                href={"/file/report/txt/" + id}
                type="primary"
                shape="round"
                icon="download"
                size="small"
              >
                Download TXT
              </Button>
              &nbsp;&nbsp;
            </div>
            <div>&nbsp;</div>
            <div className="air__utils__scrollTable">
              <Table
                rowKey="id"
                columns={reportColumns}
                dataSource={reportData}
              />
            </div>
          </div>

          <ReportFollowups
            title={reportTasksTitle}
            idCsv={reportTasksIdCsv}
            setReportTasksIdCsv={setReportTasksIdCsv}
          />
        </div>
      </AuthVisible>

      <AuthVisible when={anyAuth(hasAuthRole("Administrator"))}>
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};
