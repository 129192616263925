import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { message, Button, Form, Icon, Input, Modal, Select, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  CREATE_CALL,
  CreateCall as CreateCallData,
  CreateCallVariables,
} from "../../../../lib/graphql/mutations";

import {
  CALL_TYPES,
  CallTypes as CallTypesData,
  CALL_RESOLUTIONS,
  CallResolutions as CallResolutionsData,
  FOLLOWUP_TYPES,
  FollowupTypes as FollowupTypesData,
  //NOTE_TYPES,
  //NoteTypes as NoteTypesData,
} from "../../../../lib/graphql/queries";

interface FormProps {
  studentId: string;
  listRefetch: () => void;
  visible: boolean;
  setVisible: (input: any) => void;
}

interface AddProps {
  studentId: string;
  listRefetch: () => void;
  buttonSize?: "small" | "default" | "large" | undefined;
}

interface EditProps {
  id: string;
  listRefetch: () => void;
}

const { Option } = Select;
const { TextArea } = Input;

const AddStudentCallModalFormBase = ({
  studentId,
  listRefetch,
  visible,
  setVisible,
  form,
}: FormProps & FormComponentProps) => {
  const { data: callTypesData } = useQuery<CallTypesData>(CALL_TYPES, {
    fetchPolicy: "no-cache",
  });
  const { data: callResolutionsData } = useQuery<CallResolutionsData>(
    CALL_RESOLUTIONS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: followupTypesData } = useQuery<FollowupTypesData>(
    FOLLOWUP_TYPES,
    {
      fetchPolicy: "no-cache",
    }
  );
  // const { data: noteTypesData } = useQuery<NoteTypesData>(NOTE_TYPES, {
  //   fetchPolicy: "no-cache",
  // });

  const [createCall, { loading: createRunning }] = useMutation<
    CreateCallData,
    CreateCallVariables
  >(CREATE_CALL, {
    onCompleted: (data) => {
      message.success("Call added successfully.");

      form.resetFields();
      setVisible(false);

      listRefetch();
    },
    onError: (data) => {
      console.log(
        `StudentCallNoteModalFormBase - Create error - call: ${JSON.stringify(
          data,
          null,
          2
        )}`
      );
      message.error("Failed to add the call!");
    },
  });
  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const call = {
        studentId: +studentId,
        callTypeId: +values.callTypeId,
        navigatorUserId: 3,
        callStatusId: 2,
        contactMethodId: 1,
        callNoteTypeId: 1, //+values.callNoteTypeId,
        callNote: values.callNote,

        followupTypeId: +values.followupTypeId,
        followupNoteTypeId: 1, //+values.followupNoteTypeId,
        followupNote: values.followupNote,

        callResolutionId: values.callResolutionId
          ? +values.callResolutionId
          : null,
        callResolutionNoteTypeId: 1, //+values.callResolutionNoteTypeId,
        callResolutionNote: values.callResolutionNote,
      };

      createCall({
        variables: {
          data: call,
        },
      });
    });
  };

  return (
    <Modal
      width={800}
      visible={visible}
      title={"Add a new call"}
      okText={"Create"}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Spin spinning={createRunning}>
        <Form className="login-form">
          <div className="row">
            <div className="col-md-6">
              <Form.Item label="What type of call is this?">
                {form.getFieldDecorator("callTypeId", {
                  rules: [
                    { required: true },
                    {
                      message: "Select the Type of Call!",
                    },
                  ],
                })(
                  <Select>
                    {callTypesData &&
                      callTypesData.callTypes.map((x) => {
                        return (
                          <Option key={`${x.id}`} value={`${x.id}`}>
                            {x.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="col-md-6">&nbsp;</div>

            <div className="col-md-12">
              <Form.Item>
                {form.getFieldDecorator("callNote")(
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 30 }}
                    placeholder="notes for this call..."
                  />
                )}
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item label="Does this call require a followup?">
                {form.getFieldDecorator("followupRequired", {
                  rules: [
                    { required: true },
                    {
                      message: "Select followup requires Yes or No!",
                    },
                  ],
                })(
                  <Select>
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="col-md-6">&nbsp;</div>

            {form.getFieldValue("followupRequired") === "yes" && (
              <div className="col-md-6">
                <Form.Item label="What type of followup is needed?">
                  {form.getFieldDecorator("followupTypeId", {
                    rules: [
                      { required: form.getFieldValue("followupRequired") === "yes" },
                      { message: "Select the type of followup needed!" }
                    ],
                  })(
                    <Select>
                      {followupTypesData &&
                        followupTypesData.followupTypes.map((x) => {
                          return (
                            <Option key={`${x.id}`} value={`${x.id}`}>
                              {x.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}
            {form.getFieldValue("followupRequired") === "yes" && (
              <div className="col-md-12">
                <Form.Item>
                  {form.getFieldDecorator("followupNote")(
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 10 }}
                      placeholder="notes or instructions for the followup..."
                    />
                  )}
                </Form.Item>
              </div>
            )}

            {form.getFieldValue("followupRequired") === "no" && (
              <div className="col-md-6">
                <Form.Item label="What is the resolution of this call?">
                  {form.getFieldDecorator("callResolutionId", {
                    rules: [
                      { required: form.getFieldValue("followupRequired") === "no" },
                      { message: "Select the resolution for this call!" },
                    ],
                  })(
                    <Select>
                      {callResolutionsData &&
                        callResolutionsData.callResolutions.map((x) => {
                          return (
                            <Option key={`${x.id}`} value={`${x.id}`}>
                              {x.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}

            {form.getFieldValue("followupRequired") === "no" && (
              <div className="col-md-12">
                <Form.Item>
                  {form.getFieldDecorator("callResolutionNote")(
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 10 }}
                      placeholder="notes about the call resolution..."
                    />
                  )}
                </Form.Item>
              </div>
            )}
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export const AddStudentCallModalForm = Form.create<
  FormProps & FormComponentProps
>({
  name: "add_student_call",
})(AddStudentCallModalFormBase);

export const AddStudentCallButton = ({ studentId, listRefetch, buttonSize }: AddProps) => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button type="primary" size={buttonSize} shape="round" onClick={() => showModal()}>
        Add a New Call
      </Button>
      {visible && (
        <AddStudentCallModalForm
          studentId={studentId}
          listRefetch={listRefetch}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </div>
  );
};

export const EditStudentCallIcon = ({ id, listRefetch }: EditProps) => {
  // let [visible, setVisible] = useState(false);

  const showModal = () => {
    // setVisible(true);
  };

  return (
    <div style={{ marginLeft: "10px", display: "inline-block" }}>
      <Icon type="edit" onClick={() => showModal()} />
      {/*visible && (
        <EditStudentCallModalForm
          id={id}
          visible={visible}
          setVisible={setVisible}
        />
      )*/}
    </div>
  );
};
