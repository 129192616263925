import React, { useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import {
  message,
  Button,
  Layout,
  Form,
  Input,
  Select,
  Spin,
  Tabs,
  Upload,
  Alert,
  Icon,
} from "antd";
import moment from "moment-timezone";

import { FormComponentProps } from "antd/lib/form";

import { UserFormSkeleton } from "../UserFormSkeleton";
import { ChangePasswordButton } from "./components";
import { ChangeUsernameIcon } from "./components";

import { ROLES, Roles as RolesData } from "../../../../lib/graphql/queries";
import {
  USER,
  User as UserData,
  UserVariables,
} from "../../../../lib/graphql/queries";

import {
  CREATE_USER,
  CreateUser as CreateUserData,
  CreateUserVariables,
  UPDATE_USER,
  UpdateUser as UpdateUserData,
  UpdateUserVariables,
} from "../../../../lib/graphql/mutations/system";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../../lib/utils";

const { Content } = Layout;

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY hh:mm a z";

export const UserFormBase = ({ title, form }: Props & FormComponentProps) => {
  const { id } = useParams<MatchParams>();
  const history = useHistory();
  let [updateUsernameErrorMsg, setUpdateUsernameErrorMsg] = useState<
    string | null
  >(null);
  let [updateEmailErrorMsg, setUpdateEmailErrorMsg] = useState<string | null>(
    null
  );
  let [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);
  let [username, setUsername] = useState("");
  let [tabKey, setTabKey] = useState("1");
  const { Option } = Select;
  const { TabPane } = Tabs;

  const listUrl = `/admin/system/users`;
  let isNew = id === "0";
  let showUpload = !isNew;
  let [refreshTs, setRefreshTs] = useState<number>(new Date().valueOf());

  const { data: rolesData } = useQuery<RolesData>(ROLES, {
    fetchPolicy: "no-cache",
  });

  const { loading, error } = useQuery<UserData, UserVariables>(USER, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
    skip: id === "0",
    onCompleted: (data) => {
      if (data) {
        const user = data.user ? data.user : null;
        if (user) {
          setUsername(user.userCredential ? user.userCredential.username : "");
          form.setFieldsValue({
            id: user.id,
            userStatusId: `${user.userStatusId}`,
            userStatusName: user.userStatus.name,
            roleId: `${user.roleId}`,
            roleName: user.role.name,
            email: user.email,
            altEmail: user.altEmail,
            firstName: user.firstName,
            middleName: user.middleName,
            lastName: user.lastName,
            primaryPhone: user.primaryPhone,
            // canSmsPrimaryPhone: user.canSmsPrimaryPhone,
            altPhone: user.altPhone,
            // canSmsAltPhone: user.canSmsAltPhone,
            username: user.userCredential ? user.userCredential.username : null,
            lastLoginAt: user.userCredential
              ? user.userCredential.lastLoginAt
              : null,
          });
        }
      }
    },
    onError: (data) => {
      console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to fetch the record.`);
    },
  });

  const [createUser, { loading: createRunning }] = useMutation<
    CreateUserData,
    CreateUserVariables
  >(CREATE_USER, {
    onCompleted: (data) => {
      message.success("Record successfully created.");

      gotoList();
    },
    onError: (data) => {
      console.log(`Create error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to create record!");
      if (data?.graphQLErrors) {
        let message = data.graphQLErrors[0].message;
        if (
          message.startsWith("ER_DUP_ENTRY") &&
          message.includes("ux_userCredentials_username")
        ) {
          setUpdateUsernameErrorMsg(
            "This Login ID cannot be used, please choose different ID."
          );
        } else if (
          message.startsWith("ER_DUP_ENTRY") &&
          message.includes("ux_users_email")
        ) {
          setUpdateEmailErrorMsg(
            "This email is already used, please use a different one."
          );
        } else {
          setUpdateUsernameErrorMsg(
            "We're unable to create your profile, try again later."
          );
        }
      } else {
        setUpdateUsernameErrorMsg(
          "We're unable to create your profile, try again later."
        );
      }
    },
  });

  const [updateUser, { loading: updateRunning }] = useMutation<
    UpdateUserData,
    UpdateUserVariables
  >(UPDATE_USER, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");

      gotoList();
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
      if (data?.graphQLErrors) {
        let message = data.graphQLErrors[0].message;
        if (
          message.startsWith("ER_DUP_ENTRY") &&
          message.includes("ux_userCredentials_username")
        ) {
          setUpdateUsernameErrorMsg(
            "This Login ID cannot be used, please choose different ID."
          );
        } else if (
          message.startsWith("ER_DUP_ENTRY") &&
          message.includes("ux_users_email")
        ) {
          setUpdateEmailErrorMsg(
            "This email is already used, please use a different one."
          );
        } else {
          setUpdateEmailErrorMsg(
            "We're unable to update your profile, try again later."
          );
        }
      } else {
        setUpdateEmailErrorMsg(
          "We're unable to update your profile, try again later."
        );
      }
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <UserFormSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <UserFormSkeleton title={title} error />
      </div>
    );
  }

  const gotoList = () => {
    history.push(listUrl);
  };

  const handleCancel = (e: any) => {
    message.info("No changes saved.");
    gotoList();
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      setUpdateUsernameErrorMsg(null);
      setUpdateEmailErrorMsg(null);

      const input = {
        ...values,
      };
      delete input.confirmPassword;
      delete input.userStatusName;
      delete input.roleName;
      delete input.lastLoginAt;

      input.userStatusId = +input.userStatusId;
      input.roleId = +input.roleId;
      input.canSmsPrimaryPhone = false;
      input.canSmsAltPhone = false;

      if (input.id) {
        delete input.id;
        delete input.username;

        await updateUser({
          variables: {
            id: values.id,
            data: input,
          },
        });
      } else {
        delete input.id;
        await createUser({
          variables: {
            data: input,
          },
        });
      }
    });
  };

  const handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    setConfirmPasswordDirty(confirmPasswordDirty || !!value);
  };

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue("password")) {
      callback("Password does not match your confirmation password!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: any, callback: any) => {
    if (value && confirmPasswordDirty) {
      form.validateFields(["confirmPassword"], { force: true });
    }
    callback();
  };

  const uploadProps = {
    accept: "image/jpeg",
    action: `/upload/avatar?filename=user-${id}.jpg`,
    name: `data_input`,
    withCredentials: true,
    beforeUpload(file: any) {
      const isJpg = file.type === "image/jpeg";
      if (!isJpg) {
        message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      return isJpg && isLt2M;
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setRefreshTs(new Date().valueOf());
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Spin spinning={createRunning || updateRunning}>
              <div>
                <Helmet
                  title={`Manage Users : ${form.getFieldValue("firstName") ||
                    "<new>"} ${form.getFieldValue("lastName")}`}
                />
                <div className="air__utils__heading">
                  <h5>
                    <Link to={listUrl}>Manage Users</Link> :{" "}
                    {form.getFieldValue("firstName") || "<new>"}{" "}
                    {form.getFieldValue("lastName")}
                  </h5>
                </div>

                <div className="row">
                  <div className="col-xl-4 col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex flex-wrap flex-column align-items-center">
                          <div className="air__utils__avatar air__utils__avatar--size64 mb-3">
                            <img src={`/avatar/user/${id}?_ts=${refreshTs}`} alt="avatar" />
                          </div>
                          <div className="text-center">
                            <div className="text-dark font-weight-bold font-size-18">
                              {form.getFieldValue("firstName") || "<new>"}{" "}
                              {form.getFieldValue("lastName")}
                            </div>
                            <div className="text-dark font-weight-bold font-size-18">
                              {form.getFieldValue("roleName")}
                            </div>
                          </div>
                          <div>&nbsp;</div>
                          {showUpload && (
                            <div>
                              <Upload {...uploadProps}>
                                <Button>
                                  <Icon type="upload" />
                                  Click to Upload a 128x128 JPG Image
                                </Button>
                              </Upload>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                        <div>
                          <b>Login ID:</b> {username}
                          {!isNew && (
                            <ChangeUsernameIcon
                              userId={form.getFieldValue("id")}
                              setUsername={setUsername}
                            />
                          )}
                        </div>
                        {!isNew && (
                          <ChangePasswordButton
                            userId={form.getFieldValue("id")}
                          />
                        )}
                        <hr />
                        <div>
                          <b>Status:</b> {form.getFieldValue("userStatusName")}
                        </div>
                        <div>
                          <b>Last Login:</b>{" "}
                          {form.getFieldValue("lastLoginAt")
                            ? moment(
                                new Date(form.getFieldValue("lastLoginAt"))
                              ).format(dateFormat)
                            : "-- never --"}
                        </div>
                        <div>&nbsp;</div>
                        <div>
                          {form.getFieldValue("firstName")}{" "}
                          {form.getFieldValue("lastName")}
                        </div>
                        <div>{form.getFieldValue("primaryPhone")}</div>
                        <div>{form.getFieldValue("email")}</div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <ul className="list-unstyled">
                          <li>
                            <div className={`mr-3`}>
                              <i>no login history</i>
                            </div>
                            <div>
                              <div className="air__utils__donut air__utils__donut--danger mr-3" />
                            </div>
                            <div>&nbsp;</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8 col-lg-12">
                    <Form className="login-form">
                      <div className="card">
                        <div className="card-header card-header-flex flex-column">
                          <Tabs
                            activeKey={tabKey}
                            className="mr-auto air-tabs-bold"
                            onChange={setTabKey}
                          >
                            <TabPane tab="User Profile" key="1" />
                          </Tabs>
                        </div>
                        <div className="card-body">
                          {tabKey === "1" && (
                            <div>
                              {isNew && (
                                <div>
                                  <h5 className="text-black mt-4">
                                    <strong>Security Credentials</strong>
                                  </h5>
                                  {updateUsernameErrorMsg && (
                                    <div>
                                      <Alert
                                        message={updateUsernameErrorMsg}
                                        type="error"
                                        showIcon
                                      />
                                    </div>
                                  )}
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <Form.Item label="Login ID">
                                        {form.getFieldDecorator("username", {
                                          rules: [{ required: true, max: 100 }],
                                        })(
                                          <Input
                                            autoComplete="off"
                                            placeholder="Login ID"
                                          />
                                        )}
                                      </Form.Item>
                                    </div>
                                    <div className="col-lg-6">&nbsp;</div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <Form.Item label="Password" hasFeedback>
                                        {form.getFieldDecorator("password", {
                                          rules: [
                                            {
                                              required: true,
                                              min: 8,
                                              max: 50,
                                              message:
                                                "Enter a new password of 8 characters or more!",
                                            },
                                            {
                                              validator: validateToNextPassword,
                                            },
                                          ],
                                        })(
                                          <Input.Password autoComplete="off" />
                                        )}
                                      </Form.Item>
                                    </div>
                                    <div className="col-lg-6">
                                      <Form.Item
                                        label="Confirm Password"
                                        hasFeedback
                                      >
                                        {form.getFieldDecorator(
                                          "confirmPassword",
                                          {
                                            rules: [
                                              { required: true, max: 50 },
                                              {
                                                validator: compareToFirstPassword,
                                              },
                                            ],
                                          }
                                        )(
                                          <Input.Password
                                            autoComplete="off"
                                            onBlur={handleConfirmBlur}
                                          />
                                        )}
                                      </Form.Item>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <h5 className="text-black mt-0 mb-3">
                                <strong>Access Level</strong>
                              </h5>
                              <div className="row">
                                <div className="col-lg-6">
                                  <Form.Item label="Role">
                                    {form.getFieldDecorator("roleId", {
                                      rules: [
                                        { message: "Select security Role!" },
                                      ],
                                    })(
                                      <Select placeholder="Select a role">
                                        {rolesData &&
                                          rolesData.roles.map((x) => {
                                            return (
                                              <Option
                                                key={`${x.id}`}
                                                value={`${x.id}`}
                                              >
                                                {x.name}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    )}
                                  </Form.Item>
                                </div>
                              </div>

                              <h5 className="text-black mt-0">
                                <strong>Personal Information</strong>
                              </h5>
                              <div className="row">
                                <div className="col-md-4">
                                  <Form.Item label="First Name">
                                    {form.getFieldDecorator("firstName", {
                                      rules: [{ required: true, max: 100 }],
                                    })(<Input />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-4">
                                  <Form.Item label="Middle Name">
                                    {form.getFieldDecorator("middleName", {
                                      rules: [{ max: 100 }],
                                    })(<Input />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-4">
                                  <Form.Item label="Last Name">
                                    {form.getFieldDecorator("lastName", {
                                      rules: [{ required: true, max: 100 }],
                                    })(<Input />)}
                                  </Form.Item>
                                </div>
                              </div>
                              {updateEmailErrorMsg && (
                                <div>
                                  <Alert
                                    message={updateEmailErrorMsg}
                                    type="error"
                                    showIcon
                                  />
                                </div>
                              )}
                              <div className="row">
                                <div className="col-md-6">
                                  <Form.Item label="Email">
                                    {form.getFieldDecorator("email", {
                                      rules: [
                                        {
                                          required: true,
                                          max: 100,
                                          type: "email",
                                          message:
                                            "The input is not valid E-mail!",
                                        },
                                        {
                                          message: "Please input your E-mail!",
                                        },
                                      ],
                                    })(<Input />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-6">
                                  <Form.Item label="Alternate Email">
                                    {form.getFieldDecorator("altEmail", {
                                      rules: [
                                        {
                                          max: 100,
                                          type: "email",
                                          message:
                                            "The input is not valid Email!",
                                        },
                                        {
                                          message: "Please input your Email!",
                                        },
                                      ],
                                    })(<Input />)}
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <Form.Item label="Primary Phone">
                                    {form.getFieldDecorator("primaryPhone", {
                                      rules: [{ required: true, max: 20 }],
                                    })(<Input />)}
                                  </Form.Item>
                                </div>
                                <div className="col-md-6">
                                  <Form.Item label="Alternate Phone">
                                    {form.getFieldDecorator("altPhone", {
                                      rules: [{ max: 20 }],
                                    })(<Input />)}
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="row">
                                {!isNew && (
                                  <div className="col-md-1">
                                    <Form.Item>
                                      {form.getFieldDecorator("username")(
                                        <Input hidden />
                                      )}
                                    </Form.Item>
                                  </div>
                                )}
                                {!isNew && (
                                  <div className="col-md-1">
                                    <Form.Item>
                                      {form.getFieldDecorator("password")(
                                        <Input hidden />
                                      )}
                                    </Form.Item>
                                  </div>
                                )}
                                <div className="col-md-1">
                                  <Form.Item>
                                    {form.getFieldDecorator("id")(
                                      <Input hidden />
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="col-md-1">
                                  <Form.Item>
                                    {form.getFieldDecorator("userStatusId")(
                                      <Input hidden />
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="col-md-1">
                                  <Form.Item>
                                    {form.getFieldDecorator("userStatusName")(
                                      <Input hidden />
                                    )}
                                  </Form.Item>
                                </div>
                                <div className="col-md-1">
                                  <Form.Item>
                                    {form.getFieldDecorator("lastLoginAt")(
                                      <Input hidden />
                                    )}
                                  </Form.Item>
                                </div>
                              </div>

                              <div className="row">
                                <div className="form-actions col-md-10">
                                  <Button
                                    style={{ width: 200 }}
                                    type="primary"
                                    onClick={handleSave}
                                    className="mr-3"
                                  >
                                    {!isNew ? "Update" : "Create"}
                                  </Button>
                                  <Button onClick={handleCancel}>Cancel</Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(hasAuthRole("Navigator"), hasAuthRole("AccountManager"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};

export const UserForm = Form.create<Props & FormComponentProps>({
  name: "admin_user_form",
})(UserFormBase);
