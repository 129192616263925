import { gql } from "apollo-boost";
 
export const STUDENT_TYPE = gql`
  query StudentType($id: String!) {
    studentType(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
