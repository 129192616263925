import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import {
  message,
  Button,
  Layout,
  Form,
  Input,
  Spin,
  Tabs,
  Upload,
  Alert,
  Icon,
} from "antd";
import moment from "moment-timezone";

import { FormComponentProps } from "antd/lib/form";

import { ChangeMyPasswordButton } from "./components";
import { ChangeMyUsernameIcon } from "./components";
import { UserFormSkeleton } from "../admin/system";

import {
  AUTH_CURRENT_USER,
  AuthCurrentUser as UserData,
} from "../../lib/graphql/queries";

import {
  AUTH_UPDATE_PROFILE,
  AuthUpdateProfile as AuthUpdateProfileData,
  AuthUpdateProfileVariables,
} from "../../lib/graphql/mutations";
import { UserContext } from "../../lib/utils";

const { Content } = Layout;

interface Props {
  title: string;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY hh:mm a z";

export const MyProfileBase = ({ title, form }: Props & FormComponentProps) => {
  const { user } = useContext(UserContext);
  const id = user?.id;
  const history = useHistory();
  let [updateErrorMsg, setUpdateErrorMsg] = useState<string | null>(null);
  let [username, setUsername] = useState("");
  let [tabKey, setTabKey] = useState("1");
  const { TabPane } = Tabs;

  const dashUrl = `/`;
  let showUpload = true;
  let [refreshTs, setRefreshTs] = useState<number>(new Date().valueOf());

  const { loading, error } = useQuery<UserData>(AUTH_CURRENT_USER, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const user = data.authCurrentUser ? data.authCurrentUser : null;
      if (user) {
        setUsername(user.userCredential ? user.userCredential.username : "");
        form.setFieldsValue({
          id: user.id,
          userStatusName: user.userStatus.name,
          roleName: user.role.name,
          email: user.email,
          altEmail: user.altEmail,
          firstName: user.firstName,
          middleName: user.middleName,
          lastName: user.lastName,
          primaryPhone: user.primaryPhone,
          altPhone: user.altPhone,
          lastLoginAt: user.userCredential
            ? user.userCredential.lastLoginAt
            : null,
        });
      }
    },
    onError: (data) => {
      console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to fetch the record.`);
    },
  });

  const [updateUser, { loading: updateRunning }] = useMutation<
    AuthUpdateProfileData,
    AuthUpdateProfileVariables
  >(AUTH_UPDATE_PROFILE, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");

      gotoDash();
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
      if (data?.graphQLErrors) {
        let message = data.graphQLErrors[0].message;
        if (
          message.startsWith("ER_DUP_ENTRY") &&
          message.includes("ux_userCredentials_username")
        ) {
          setUpdateErrorMsg(
            "This Login ID cannot be used, please choose different ID."
          );
        } else if (
          message.startsWith("ER_DUP_ENTRY") &&
          message.includes("ux_users_email")
        ) {
          setUpdateErrorMsg(
            "This email is already used, please use a different one."
          );
        } else {
          setUpdateErrorMsg(
            "We're unable to update your profile, try again later."
          );
        }
      } else {
        setUpdateErrorMsg(
          "We're unable to update your profile, try again later."
        );
      }
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <UserFormSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <UserFormSkeleton title={title} error />
      </div>
    );
  }

  const gotoDash = () => {
    history.push(dashUrl);
  };

  const handleCancel = (e: any) => {
    message.info("No changes saved.");
    gotoDash();
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      setUpdateErrorMsg(null);

      const input = {
        ...values,
      };
      delete input.id;
      delete input.userStatusName;
      delete input.roleName;
      delete input.lastLoginAt;

      input.canSmsPrimaryPhone = false;
      input.canSmsAltPhone = false;

      await updateUser({
        variables: {
          id: values.id,
          data: input,
        },
      });
    });
  };

  const uploadProps = {
    accept: "image/jpeg",
    action: `/upload/avatar?filename=user-${id}.jpg`,
    name: `data_input`,
    withCredentials: true,
    beforeUpload(file: any) {
      const isJpg = file.type === "image/jpeg";
      if (!isJpg) {
        message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      return isJpg && isLt2M;
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`Your Avatar was file uploaded successfully. Refresh page tpo update your menu image.`);
        setRefreshTs(new Date().valueOf());
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Content>
      <div>
        <Spin spinning={updateRunning}>
          <div>
            <Helmet title="My Profile" />
            <div className="air__utils__heading">
              <h5>My Profile</h5>
            </div>

            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap flex-column align-items-center">
                      <div className="air__utils__avatar air__utils__avatar--size64 mb-3">
                        <img src={`/avatar/user/${id}?_ts=${refreshTs}`} alt="avatar" />
                      </div>
                      <div className="text-center">
                        <div className="text-dark font-weight-bold font-size-18">
                          {form.getFieldValue("firstName") || "<new>"}{" "}
                          {form.getFieldValue("lastName")}
                        </div>
                        <div className="text-dark font-weight-bold font-size-18">
                          {form.getFieldValue("roleName")}
                        </div>
                      </div>
                      <div>&nbsp;</div>
                      <div>
                        {showUpload && (
                          <div>
                            <Upload {...uploadProps}>
                              <Button>
                                <Icon type="upload" />
                                Click to Upload a 128x128 JPG Image
                              </Button>
                            </Upload>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div>
                      <b>Login ID:</b> {username}
                      <ChangeMyUsernameIcon
                        userId={form.getFieldValue("id")}
                        setUsername={setUsername}
                      />
                    </div>
                    <ChangeMyPasswordButton userId={form.getFieldValue("id")} />
                    <hr />
                    <div>
                      <b>Status:</b> {form.getFieldValue("userStatusName")}
                    </div>
                    <div>
                      <b>Last Login:</b>{" "}
                      {form.getFieldValue("lastLoginAt")
                        ? moment(
                            new Date(form.getFieldValue("lastLoginAt"))
                          ).format(dateFormat)
                        : "-- never --"}
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      {form.getFieldValue("firstName")}{" "}
                      {form.getFieldValue("lastName")}
                    </div>
                    <div>{form.getFieldValue("primaryPhone")}</div>
                    <div>{form.getFieldValue("email")}</div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body">
                    <ul className="list-unstyled">
                      <li>
                        <div className={`mr-3`}>
                          <i>no login history</i>
                        </div>
                        <div>
                          <div className="air__utils__donut air__utils__donut--danger mr-3" />
                        </div>
                        <div>&nbsp;</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-12">
                <Form className="login-form">
                  <div className="card">
                    <div className="card-header card-header-flex flex-column">
                      <Tabs
                        activeKey={tabKey}
                        className="mr-auto air-tabs-bold"
                        onChange={setTabKey}
                      >
                        <TabPane tab="Personal Information" key="1" />
                      </Tabs>
                    </div>
                    <div className="card-body">
                      {tabKey === "1" && (
                        <div>
                          {updateErrorMsg && (
                            <div>
                              <Alert
                                message={updateErrorMsg}
                                type="error"
                                showIcon
                              />
                            </div>
                          )}
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Item label="First Name">
                                {form.getFieldDecorator("firstName", {
                                  rules: [{ required: true, max: 100 }],
                                })(<Input />)}
                              </Form.Item>
                            </div>
                            <div className="col-md-4">
                              <Form.Item label="Middle Name">
                                {form.getFieldDecorator("middleName", {
                                  rules: [{ max: 100 }],
                                })(<Input />)}
                              </Form.Item>
                            </div>
                            <div className="col-md-4">
                              <Form.Item label="Last Name">
                                {form.getFieldDecorator("lastName", {
                                  rules: [{ required: true, max: 100 }],
                                })(<Input />)}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Item label="Email">
                                {form.getFieldDecorator("email", {
                                  rules: [
                                    {
                                      max: 100,
                                      required: true,
                                      type: "email",
                                      message: "The input is not valid E-mail!",
                                    },
                                    {
                                      message: "Please input your E-mail!",
                                    },
                                  ],
                                })(<Input />)}
                              </Form.Item>
                            </div>
                            <div className="col-md-6">
                              <Form.Item label="Alternate Email">
                                {form.getFieldDecorator("altEmail", {
                                  rules: [
                                    {
                                      max: 100,
                                      type: "email",
                                      message: "The input is not valid Email!",
                                    },
                                    {
                                      message: "Please input your Email!",
                                    },
                                  ],
                                })(<Input />)}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Form.Item label="Primary Phone">
                                {form.getFieldDecorator("primaryPhone", {
                                  rules: [{ required: true, max: 20 }],
                                })(<Input />)}
                              </Form.Item>
                            </div>
                            <div className="col-md-6">
                              <Form.Item label="Alternate Phone">
                                {form.getFieldDecorator("altPhone", {
                                  rules: [{ max: 20 }],
                                })(<Input />)}
                              </Form.Item>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-1">
                              <Form.Item>
                                {form.getFieldDecorator("id")(<Input hidden />)}
                              </Form.Item>
                            </div>
                            <div className="col-md-1">
                              <Form.Item>
                                {form.getFieldDecorator("userStatusName")(
                                  <Input hidden />
                                )}
                              </Form.Item>
                            </div>
                            <div className="col-md-1">
                              <Form.Item>
                                {form.getFieldDecorator("roleName")(
                                  <Input hidden />
                                )}
                              </Form.Item>
                            </div>
                            <div className="col-md-1">
                              <Form.Item>
                                {form.getFieldDecorator("lastLoginAt")(
                                  <Input hidden />
                                )}
                              </Form.Item>
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-actions col-md-10">
                              <Button
                                style={{ width: 200 }}
                                type="primary"
                                onClick={handleSave}
                                className="mr-3"
                              >
                                Update
                              </Button>
                              <Button onClick={handleCancel}>Cancel</Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </Content>
  );
};

export const MyProfile = Form.create<Props & FormComponentProps>({
  name: "admin_my_profile_form",
})(MyProfileBase);
