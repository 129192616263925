import { gql } from "apollo-boost";

export const REPORT_PROFILE = gql`
query ReportProfile($id: String!) {
  reportProfile(id: $id) {
    id
    reportGroup{
      code
      name
    }
    title
    heading
    description
    params
    viewerRoute
  }
}
`;

