import React, { useState, useMemo } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Layout } from "antd";

import { SettingsContext, UserContext } from "../../../lib/utils";

import {
  SETTINGS,
  Settings as SettingsData,
} from "../../../lib/graphql/queries";

import { AppFooter } from "../AppFooter";
import { AppHeader } from "../AppHeader";
import { AppLeftMenu } from "../AppLeftMenu";
import { AppRoutes } from "../AppRoutes";

export const App = () => {
  let userDataJson = sessionStorage.getItem("user");
  let userData = userDataJson ? JSON.parse(userDataJson) : null;

  const { Content } = Layout;
  const [settings, setSettings] = useState({});
  const settingsValue = useMemo(() => ({ settings, setSettings }), [
    settings,
    setSettings,
  ]);
  const [user, setUser] = useState(userData);
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  useQuery<SettingsData>(SETTINGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data) return;
      let localSettings = data.settings ? data.settings.settings : {};

      // console.log(`SettingsData: ${JSON.stringify(localSettings, null, 2)}`);
      setSettings(localSettings);
    },
    onError: (data) => {
      console.log(`Query error for Settings: ${JSON.stringify(data, null, 2)}`);
    },
  });

  return (
    <SettingsContext.Provider value={settingsValue}>
      <UserContext.Provider value={value}>
        <Router>
          {user ? (
            <Layout id="app">
              <AppLeftMenu />
              <Layout className="site-layout">
                <AppHeader />
                <Content style={{ margin: "0 16px" }}>
                  <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360 }}
                  >
                    <AppRoutes />
                  </div>
                </Content>
                <AppFooter />
              </Layout>
            </Layout>
          ) : (
            <Layout id="app">
              <Layout className="site-layout">
                <Content style={{ margin: "0 16px" }}>
                  <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360 }}
                  >
                    <AppRoutes />
                  </div>
                </Content>
                <AppFooter />
              </Layout>
            </Layout>
          )}
        </Router>
      </UserContext.Provider>
    </SettingsContext.Provider>
  );
};
