import { gql } from "apollo-boost";

export const STATES = gql`
  query States($includeDeleted: Boolean) {
    states(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
