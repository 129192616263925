import { gql } from "apollo-boost";

export const REPORT_PROFILES = gql`
  query ReportProfiles($includeDeleted: Boolean) {
    reportProfiles(includeDeleted: $includeDeleted) {
      id
      reportGroup{
        id
        code
        name
      }
      title
      heading
      description
      viewerRoute
    }
  }
`;
