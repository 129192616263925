import { gql } from "apollo-boost";
 
export const ADDRESS_TYPES = gql`
  query AddressTypes($includeDeleted: Boolean) {
    addressTypes(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
