import { gql } from "apollo-boost";
 
export const USER_STATUSES = gql`
  query UserStatuses($includeDeleted: Boolean) {
    userStatuses(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
