import React from "react";
import { Layout } from "antd";

// eslint-disable-next-line @typescript-eslint/no-var-requires
var cchPackage = require('../../../../package.json');
const CCH_APP_VERSION = cchPackage.version;

const { Footer } = Layout;

export const AppFooter = () => {
  return (
    <Footer style={{ textAlign: "center", paddingBottom: "10px"}}>
      &copy; 2020 Christie Campus Health. All rights reserved. [v{CCH_APP_VERSION}]
    </Footer>
  );
};
