import { gql } from "apollo-boost";
 
export const CALL_RESOLUTION = gql`
  query CallResolution($id: String!) {
    callResolution(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
