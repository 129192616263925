import { gql } from "apollo-boost";

export const UNIVERSITIES = gql`
  query Universities($includeDeleted: Boolean) {
    universities(includeDeleted: $includeDeleted) {
      id
      universityKey
      name
      shortName
      avatar
      coverageStartDate
      coverageTerminationDate
      isDeleted
    }
  }
`;
