import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  message,
  Button,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  DatePicker,
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment-timezone";

import { MomentToDateOrNull } from "../../../../lib/utils";
import {
  GENDERS,
  Genders as GendersData,
  LANGUAGES,
  Languages as LanguagesData,
  UNIVERSITIES,
  Universities as UniversitiesData,
  UNIVERSITY_CAMPUSES,
  UniversityCampuses as UniversityCampusesData,
} from "../../../../lib/graphql/queries";

import {
  CREATE_STUDENT,
  CreateStudent as CreateStudentData,
  CreateStudentVariables,
} from "../../../../lib/graphql/mutations";
import { useHistory } from "react-router-dom";

interface FormProps {
  visible: boolean;
  setVisible: (input: any) => void;
}

const { Option } = Select;

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

const AddStudentModalFormBase = ({
  visible,
  setVisible,
  form,
}: FormProps & FormComponentProps) => {
  const history = useHistory();
  const studentUrl = "/student";

  const { data: gendersData } = useQuery<GendersData>(GENDERS, {
    fetchPolicy: "no-cache",
  });
  const { data: languagesData } = useQuery<LanguagesData>(LANGUAGES, {
    fetchPolicy: "no-cache",
  });
  const { data: universitiesData } = useQuery<UniversitiesData>(UNIVERSITIES, {
    fetchPolicy: "no-cache",
  });
  const { data: universityCampusesData } = useQuery<UniversityCampusesData>(
    UNIVERSITY_CAMPUSES,
    {
      variables: {
        universityId: "",
      },
      fetchPolicy: "no-cache",
    }
  );

  const [createStudent, { loading: createRunning }] = useMutation<
    CreateStudentData,
    CreateStudentVariables
  >(CREATE_STUDENT, {
    onCompleted: (data) => {
      message.success("Student added successfully.");

      form.resetFields();
      setVisible(false);

      gotoStudent(data.createStudent.id);
    },
    onError: (data) => {
      console.log(
        `AddStudentModalFormBase - Create error - callNote: ${JSON.stringify(
          data,
          null,
          2
        )}`
      );
      message.error("Failed to update the note!");
    },
  });

  const gotoStudent = (id: string) => {
    history.push(`${studentUrl}/${id}`);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const student = {
        universityId: +values.universityId,
        universityCampusId: +values.universityCampusId,
        studentIdentifier: values.studentIdentifier,
        dateOfBirth: MomentToDateOrNull(values.dateOfBirthMoment),
        genderId: +values.genderId,
        languageId: +values.languageId,
        contact: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          primaryPhone: values.primaryPhone,
          email: values.email,
        },
      };

      createStudent({
        variables: {
          data: student,
        },
      });
    });
  };

  return (
    <Modal
      visible={visible}
      title={"Student Intake Form - Create a student record"}
      okText={"Create"}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Spin spinning={createRunning}>
        <Form className="intake-form">
          <div className="row">
            <div className="col-md-6">
              <Form.Item label="University">
                {form.getFieldDecorator("universityId", {
                  rules: [{ message: "Select student university!" }],
                })(
                  <Select placeholder="Select a university">
                    {universitiesData &&
                      universitiesData.universities.map((x) => {
                        return (
                          <Option key={`${x.id}`} value={`${x.id}`}>
                            {x.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Campus">
                {form.getFieldDecorator("universityCampusId", {
                  rules: [{ message: "Select student university campus!" }],
                })(
                  <Select placeholder="Select a campus">
                    {universityCampusesData &&
                      universityCampusesData.universityCampuses
                        .filter((x) => {
                          return (
                            "" + x.universityId ===
                            form.getFieldValue("universityId")
                          );
                        })
                        .map((y) => {
                          return (
                            <Option key={`${y.id}`} value={`${y.id}`}>
                              {y.name}
                            </Option>
                          );
                        })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item label="Student ID">
                {form.getFieldDecorator("studentIdentifier")(<Input />)}
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Date of Birth">
                {form.getFieldDecorator("dateOfBirthMoment")(
                  <DatePicker format={dateFormat} />
                )}
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item label="Gender">
                {form.getFieldDecorator("genderId", {
                  rules: [{ message: "Select student gender!" }],
                })(
                  <Select placeholder="Select a gender">
                    {gendersData &&
                      gendersData.genders.map((x) => {
                        return (
                          <Option key={`${x.id}`} value={`${x.id}`}>
                            {x.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item label="Language">
                {form.getFieldDecorator("languageId", {
                  rules: [{ message: "Select student's preferred language!" }],
                })(
                  <Select placeholder="Select a language">
                    {languagesData &&
                      languagesData.languages.map((x) => {
                        return (
                          <Option key={`${x.id}`} value={`${x.id}`}>
                            {x.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </div>

            <div className="col-md-4">
              <Form.Item label="First Name">
                {form.getFieldDecorator("firstName")(<Input />)}
              </Form.Item>
            </div>
            <div className="col-md-4">
              <Form.Item label="Middle Name">
                {form.getFieldDecorator("middleName")(<Input />)}
              </Form.Item>
            </div>
            <div className="col-md-4">
              <Form.Item label="Last Name">
                {form.getFieldDecorator("lastName")(<Input />)}
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Primary Phone">
                {form.getFieldDecorator("primaryPhone")(<Input />)}
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item label="Email">
                {form.getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      message: "Please input your E-mail!",
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export const AddStudentModalForm = Form.create<FormProps & FormComponentProps>({
  name: "add_student_form",
})(AddStudentModalFormBase);

export const AddStudentButton = () => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button size="small" shape="round" onClick={() => showModal()}>
        Create Student
      </Button>
      {visible && (
        <AddStudentModalForm visible={visible} setVisible={setVisible} />
      )}
    </div>
  );
};
