import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { message, Button, Form, Icon, Input, Modal, Select, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  CLOSE_CALL_FOLLOWUP,
  CloseCallFollowup as CloseCallFollowupData,
  CloseCallFollowupVariables,
} from "../../../../lib/graphql/mutations";

import {
  FOLLOWUP_RESOLUTION_TYPES,
  FollowupResolutionTypes as FollowupResolutionTypesData,
  CALL_RESOLUTIONS,
  CallResolutions as CallResolutionsData,
  FOLLOWUP_TYPES,
  FollowupTypes as FollowupTypesData,
} from "../../../../lib/graphql/queries";

interface FormProps {
  followupId: string;
  listRefetch: () => void;
  visible: boolean;
  setVisible: (input: any) => void;
}

interface CloseProps {
  followupId: string;
  listRefetch: () => void;
}

interface EditProps {
  id: string;
  listRefetch: () => void;
}

const { Option } = Select;
const { TextArea } = Input;

const CloseStudentCallFollowupModalFormBase = ({
  followupId,
  listRefetch,
  visible,
  setVisible,
  form,
}: FormProps & FormComponentProps) => {
  const { data: followupResolutionTypesData } = useQuery<FollowupResolutionTypesData>(
    FOLLOWUP_RESOLUTION_TYPES,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: callResolutionsData } = useQuery<CallResolutionsData>(
    CALL_RESOLUTIONS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: followupTypesData } = useQuery<FollowupTypesData>(
    FOLLOWUP_TYPES,
    {
      fetchPolicy: "no-cache",
    }
  );


  const [closeCallFollowup, { loading: createRunning }] = useMutation<
    CloseCallFollowupData,
    CloseCallFollowupVariables
  >(CLOSE_CALL_FOLLOWUP, {
    onCompleted: (data) => {
      message.success("Call followup updated successfully.");

      form.resetFields();
      setVisible(false);

      listRefetch();
    },
    onError: (data) => {
      console.log(
        `CloseStudentCallFollowupModalFormBase - Error - call: ${JSON.stringify(
          data,
          null,
          2
        )}`
      );
      message.error("Failed to update the call followup!");
    },
  });
  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const closeFollowup = {
        followupResolutionTypeId: +values.followupResolutionTypeId,
        followupResolutionNoteTypeId: 1,
        followupResolutionNote: values.followupResolutionNote,

        nextFollowupTypeId: values.nextFollowupTypeId ? +values.nextFollowupTypeId : null,
        nextFollowupNoteTypeId: 1,
        nextFollowupNote: values.nextFollowupNote,

        callResolutionId: values.callResolutionId
          ? +values.callResolutionId
          : null,
        callResolutionNoteTypeId: 1,
        callResolutionNote: values.callResolutionNote,
      };

      closeCallFollowup({
        variables: {
          id: followupId,
          data: closeFollowup,
        },
      });
    });
  };

  return (
    <Modal
      width={800}
      visible={visible}
      title={"Close this follow-up"}
      okText={"Submit"}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Spin spinning={createRunning}>
        <Form className="login-form">
          <div className="row">
            <div className="col-md-6">
              <Form.Item label="What was the resolution of this followup?">
                {form.getFieldDecorator("followupResolutionTypeId", {
                  rules: [
                    { required: true },
                    {
                      message: "Select the followup resolution!",
                    },
                  ],
                })(
                  <Select>
                    {followupResolutionTypesData &&
                      followupResolutionTypesData.followupResolutionTypes.map((x) => {
                        return (
                          <Option key={`${x.id}`} value={`${x.id}`}>
                            {x.name}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="col-md-6">&nbsp;</div>

            <div className="col-md-12">
              <Form.Item>
                {form.getFieldDecorator("followupResolutionNote")(
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 30 }}
                    placeholder="followup resolution notes..."
                  />
                )}
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item label="Does this call require another followup?">
                {form.getFieldDecorator("followupRequired", {
                  rules: [
                    { required: true },
                    {
                      message: "Select followup requires Yes or No!",
                    },
                  ],
                })(
                  <Select>
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="col-md-6">&nbsp;</div>

            {form.getFieldValue("followupRequired") === "yes" && (
              <div className="col-md-6">
                <Form.Item label="What type of followup is needed next?">
                  {form.getFieldDecorator("nextFollowupTypeId", {
                    rules: [
                      {
                        required:
                          form.getFieldValue("followupRequired") === "yes",
                      },
                      { message: "Select the type of followup needed" },
                    ],
                  })(
                    <Select>
                      {followupTypesData &&
                        followupTypesData.followupTypes.map((x) => {
                          return (
                            <Option key={`${x.id}`} value={`${x.id}`}>
                              {x.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}
            {form.getFieldValue("followupRequired") === "yes" && (
              <div className="col-md-12">
                <Form.Item>
                  {form.getFieldDecorator("nextFollowupNote")(
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 10 }}
                      placeholder="notes or instructions for the next followup..."
                    />
                  )}
                </Form.Item>
              </div>
            )}

            {form.getFieldValue("followupRequired") === "no" && (
              <div className="col-md-6">
                <Form.Item label="What is the resolution of this call?">
                  {form.getFieldDecorator("callResolutionId", {
                    rules: [
                      {
                        required:
                          form.getFieldValue("followupRequired") === "no",
                      },
                      { message: "Select the resolution for this call!" },
                    ],
                  })(
                    <Select>
                      {callResolutionsData &&
                        callResolutionsData.callResolutions.map((x) => {
                          return (
                            <Option key={`${x.id}`} value={`${x.id}`}>
                              {x.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}

            {form.getFieldValue("followupRequired") === "no" && (
              <div className="col-md-12">
                <Form.Item>
                  {form.getFieldDecorator("callResolutionNote")(
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 10 }}
                      placeholder="notes about the call resolution..."
                    />
                  )}
                </Form.Item>
              </div>
            )}
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export const CloseStudentCallFollowupModalForm = Form.create<
  FormProps & FormComponentProps
>({
  name: "add_student_call",
})(CloseStudentCallFollowupModalFormBase);

export const CloseStudentCallFollowupIcon = ({
  followupId,
  listRefetch,
}: CloseProps) => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button type="link" onClick={() => showModal()}>
        <Icon type="check" />
        &nbsp;close followup
      </Button>
      {visible && (
        <CloseStudentCallFollowupModalForm
          followupId={followupId}
          listRefetch={listRefetch}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </div>
  );
};

export const EditStudentCallFollowupIcon = ({ id, listRefetch }: EditProps) => {
  let [visible, setVisible] = useState(false);

  console.log(`EditStudentCallIcon() - ${id} ${visible}`);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ marginLeft: "10px", display: "inline-block" }}>
      <Icon type="edit" onClick={() => showModal()} />
      {/*visible && (
        <EditStudentCallFollowupModalForm
          id={id}
          visible={visible}
          setVisible={setVisible}
        />
      )*/}
    </div>
  );
};
