import React, { useState } from "react";
import { useMutation, useQuery /*, useMutation*/ } from "@apollo/react-hooks";
import { message, Button, DatePicker, Form, Input, Select } from "antd";

import moment from "moment-timezone";

import { FormComponentProps } from "antd/lib/form";

import { MomentToDateOrNull } from "../../../../lib/utils";
import { StudentFormSkeleton } from "../StudentFormSkeleton";
import {
  GENDERS,
  Genders as GendersData,
  INSURANCE_TYPES,
  InsuranceTypes as InsuranceTypesData,
} from "../../../../lib/graphql/queries";
import { STATES, States as StatesData } from "../../../../lib/graphql/queries";
import {
  INSURANCE,
  Insurance as InsuranceData,
  Insurance_insurance as InsuranceRecord,
  InsuranceVariables,
} from "../../../../lib/graphql/queries";
import {
  CREATE_INSURANCE,
  CreateInsurance as CreateInsuranceData,
  CreateInsuranceVariables,
  UPDATE_INSURANCE,
  UpdateInsurance as UpdateInsuranceData,
  UpdateInsuranceVariables,
} from "../../../../lib/graphql/mutations";

const { Option } = Select;

interface Props {
  studentId: string;
  insuranceId: string;
  refreshStudentSidebar: () => void;
  setInsuranceId: (insuranceId: string) => void;
  setTabKey: (tabKey: string) => void;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

export const StudentInsuranceFormBase = ({
  studentId,
  insuranceId,
  refreshStudentSidebar,
  setInsuranceId,
  setTabKey,
  form,
}: Props & FormComponentProps) => {
  let [insuranceRecord, setInsuranceRecord] = useState<
    InsuranceRecord | undefined
  >(undefined);
  let isNew = insuranceId === "" || insuranceId === "0";
  const { data: gendersData } = useQuery<GendersData>(GENDERS, {
    fetchPolicy: "no-cache",
  });
  const { data: insuranceTypesData } = useQuery<InsuranceTypesData>(
    INSURANCE_TYPES,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { data: statesData } = useQuery<StatesData>(STATES, {
    fetchPolicy: "no-cache",
  });

  const { loading, error } = useQuery<InsuranceData, InsuranceVariables>(
    INSURANCE,
    {
      variables: {
        id: insuranceId,
      },
      fetchPolicy: "no-cache",
      skip: isNew,
      onCompleted: (data) => {
        setInsuranceRecord(data && data.insurance ? data.insurance : undefined);
        updateFormValues(data && data.insurance ? data.insurance : null);
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  const [createInsurance] = useMutation<
    CreateInsuranceData,
    CreateInsuranceVariables
  >(CREATE_INSURANCE, {
    onCompleted: (data) => {
      message.success("Record successfully created.");

      setInsuranceId(data.createInsurance.id);
      form.setFieldsValue({
        insuranceId: data.createInsurance.id,
      });
      setTabKey("3");
    },
    onError: (data) => {
      console.log(`Create error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to create record!");
    },
  });

  const [updateInsurance /*, { loading: updateRunning }*/] = useMutation<
    UpdateInsuranceData,
    UpdateInsuranceVariables
  >(UPDATE_INSURANCE, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");

      setInsuranceId(data.updateInsurance.id);
      form.setFieldsValue({
        insuranceId: data.updateInsurance.id,
      });
      setTabKey("3");
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={"Student Insurance Details"} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={"Student Insurance Details"} error />
      </div>
    );
  }

  const updateFormValues = (record: any) => {
    if (!record) {
      record = { insuranceTypeId: "1", insuredPersonTypeId: "1" };
    }
    record.insuredPersonTypeId = record.insuredPersonTypeId
      ? `${record.insuredPersonTypeId}`
      : "1";

    form.setFieldsValue({
      insuranceTypeId: record.insuranceTypeId
        ? `${record.insuranceTypeId}`
        : "1",
      insuranceCarrier: record.insuranceCarrier,
      idNumber: record.idNumber,
      policyNumber: record.policyNumber,
      plan: record.plan,
      startOfCoverageMoment: record.startOfCoverageDate
        ? moment(record.startOfCoverageDate)
        : null,
      endOfCoverageMoment: record.endOfCoverageDate
        ? moment(record.endOfCoverageDate)
        : null,

      insuredPersonTypeId: record.insuredPersonTypeId
        ? `${record.insuredPersonTypeId}`
        : "1",
    });

    if (record.insuredPersonTypeId !== "1") {
      form.setFieldsValue({
        insuredGenderId: `${record.insuredGenderId}`,
        insuredDateOfBirthMoment: record.insuredDateOfBirth
          ? moment(record.insuredDateOfBirth)
          : null,
        firstName: record.insuredContact
          ? record.insuredContact.firstName
          : null,
        middleName: record.insuredContact
          ? record.insuredContact.middleName
          : null,
        lastName: record.insuredContact ? record.insuredContact.lastName : null,
        addressLine1: record.insuredContact
          ? record.insuredContact.addressLine1
          : null,
        addressLine2: record.insuredContact
          ? record.insuredContact.addressLine2
          : null,
        city: record.insuredContact ? record.insuredContact.city : null,
        stateId: record.insuredContact
          ? `${record.insuredContact.stateId}`
          : null,
        zipCode: record.insuredContact ? record.insuredContact.zipCode : null,
        primaryPhone: record.insuredContact
          ? record.insuredContact.primaryPhone
          : null,
        email: record.insuredContact ? record.insuredContact.email : null,
      });
    }
  };

  const handleCancel = (e: any) => {
    if (isNew) {
      updateFormValues(null);
    } else {
      updateFormValues(insuranceRecord);
    }
    message.info("Changes discarded.");
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      const insurance = {
        isActive: true,
        insuranceTypeId: +values.insuranceTypeId,
        isSubscriber:
          values.insuredPersonTypeId && values.insuredPersonTypeId === "1",
        insuranceCarrier: values.insuranceCarrier,
        idNumber: values.idNumber,
        policyNumber: values.policyNumber,
        plan: values.plan,
        startOfCoverageDate: MomentToDateOrNull(values.startOfCoverageMoment),
        endOfCoverageDate: MomentToDateOrNull(values.endOfCoverageMoment),
        insuredPersonTypeId: values.insuredPersonTypeId
          ? +values.insuredPersonTypeId
          : 1,

        insuredGenderId: values.insuredGenderId
          ? +values.insuredGenderId
          : null,
        insuredDateOfBirth: MomentToDateOrNull(values.insuredDateOfBirthMoment),
        insuredContact: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          primaryPhone: values.primaryPhone,
          altPhone: values.altPhone,
          email: values.email,
          altEmail: values.altEmail,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          stateId: values.stateId ? +values.stateId : null,
          zipCode: values.zipCode,
        },
      };

      if (isNew) {
        await createInsurance({
          variables: {
            data: {
              studentId: +studentId,
              ...insurance,
            },
          },
        });
        refreshStudentSidebar();
      } else {
        await updateInsurance({
          variables: {
            id: insuranceId,
            data: insurance,
          },
        });
        refreshStudentSidebar();
      }
    });
  };

  return (
    <div>
      <Form className="student-insurance-form">
        <h5 className="text-black mt-0">
          <strong>{isNew ? "Add New " : ""}Insurance Information</strong>
        </h5>
        <div className="row">
          <div className="col-md-4">
            <Form.Item label="Type of Insurance">
              {form.getFieldDecorator("insuranceTypeId", {
                rules: [
                  { required: true },
                  { message: "Select insurance type!" },
                ],
              })(
                <Select placeholder="Select an insurance type">
                  {insuranceTypesData &&
                    insuranceTypesData.insuranceTypes.map((x) => {
                      return (
                        <Option key={`${x.id}`} value={`${x.id}`}>
                          {x.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </div>
          <div className="col-md-8">
            <Form.Item label="Carrier">
              {form.getFieldDecorator("insuranceCarrier")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="Insured's ID Number">
              {form.getFieldDecorator("idNumber")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="Insured Policy Number">
              {form.getFieldDecorator("policyNumber")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="Plan Name/Program Name">
              {form.getFieldDecorator("plan")(<Input />)}
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Start of Coverage">
              {form.getFieldDecorator("startOfCoverageMoment")(
                <DatePicker format={dateFormat} />
              )}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="End of Coverage">
              {form.getFieldDecorator("endOfCoverageMoment")(
                <DatePicker format={dateFormat} />
              )}
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Student's relationship to Insured">
              {form.getFieldDecorator("insuredPersonTypeId", {
                rules: [
                  {
                    message: "Select the relationship to Insured!",
                  },
                ],
              })(
                <Select placeholder="Select relationship to Insured">
                  <Option value="1">Self</Option>
                  <Option value="2">Spouse</Option>
                  <Option value="3">Parent/Guardian</Option>
                </Select>
              )}
            </Form.Item>
          </div>
          <div className="col-md-6">&nbsp;</div>

          {form.getFieldValue("insuredPersonTypeId") !== "1" && (
            <div className="row">
              <div className="border-top col-12">&nbsp;</div>
              <div className="col-12">
                <strong>
                  <u>Insured Information</u>
                </strong>
              </div>

              <div className="col-md-4">
                <Form.Item label="First Name">
                  {form.getFieldDecorator("firstName")(<Input />)}
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item label="Middle Name">
                  {form.getFieldDecorator("middleName")(<Input />)}
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item label="Last Name">
                  {form.getFieldDecorator("lastName")(<Input />)}
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Date of Birth">
                  {form.getFieldDecorator("insuredDateOfBirthMoment")(
                    <DatePicker format={dateFormat} />
                  )}
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Gender">
                  {form.getFieldDecorator("insuredGenderId", {
                    rules: [{ message: "Select student gender!" }],
                  })(
                    <Select placeholder="Select a option and change input text above">
                      {gendersData &&
                        gendersData.genders.map((x) => {
                          return (
                            <Option key={`${x.id}`} value={`${x.id}`}>
                              {x.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="col-md-6">&nbsp;</div>

              <div className="col-12">
                <Form.Item label="Address">
                  {form.getFieldDecorator("addressLine1")(
                    <Input placeholder="1234 Main St." />
                  )}
                </Form.Item>
                <Form.Item label="Address 2">
                  {form.getFieldDecorator("addressLine2")(
                    <Input placeholder="Apartment, studio, or floor" />
                  )}
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="City">
                  {form.getFieldDecorator("city")(<Input />)}
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item label="State">
                  {form.getFieldDecorator("stateId", {
                    rules: [{ message: "Please select your state!" }],
                  })(
                    <Select placeholder="Select a state">
                      {statesData &&
                        statesData.states.map((x) => {
                          return (
                            <Option key={`${x.id}`} value={`${x.id}`}>
                              {x.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="col-md-2">
                <Form.Item label="Zip">
                  {form.getFieldDecorator("zipCode")(<Input />)}
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Phone Number">
                  {form.getFieldDecorator("primaryPhone")(<Input />)}
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Email">
                  {form.getFieldDecorator("email")(<Input />)}
                </Form.Item>
              </div>
              <div className="col-md-6">&nbsp;</div>
            </div>
          )}
        </div>

        <div className="form-actions">
          <Button
            style={{ width: 200 }}
            type="primary"
            onClick={handleSave}
            className="mr-3"
          >
            {isNew ? "Create" : "Update"}
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

export const StudentInsuranceForm = Form.create<Props & FormComponentProps>({
  name: "student_insurance_form",
})(StudentInsuranceFormBase);
