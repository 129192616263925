import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import { message, Button, Layout, Popconfirm, Spin, Table } from "antd";
import moment from "moment-timezone";

import { StringSorter, DateOnlySorter } from "../../../../lib/utils";
import { ShowDeletableRecordText } from "../../components";
import { VendorListSkeleton } from "../VendorListSkeleton";
import {
  VENDORS,
  Vendors as VendorsData,
} from "../../../../lib/graphql/queries";
import {
  DELETE_VENDOR,
  DeleteVendor as DeleteVendorData,
  DeleteVendorVariables,
  UNDELETE_VENDOR,
  UndeleteVendor as UndeleteVendorData,
  UndeleteVendorVariables,
} from "../../../../lib/graphql/mutations/app";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../../lib/utils";
import "../../styles/admin.css";

const { Content } = Layout;

interface Props {
  title: string;
}
interface PopEditVendorProps {
  id: string;
  handleEditVendor: (id: string) => Promise<void>;
  handleDeleteVendor: (id: string) => Promise<void>;
}
interface PopUndeleteVendorProps {
  id: string;
  handleUndeleteVendor: (id: string) => Promise<void>;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

export const VendorList = ({ title }: Props) => {
  let [refreshTs] = useState<number>(new Date().valueOf());

  const { data, loading, error, refetch } = useQuery<VendorsData>(VENDORS, {
    variables: {
      includeDeleted: true,
    },
    fetchPolicy: "no-cache",
  });
  const vendors = data ? data.vendors : undefined;

  const [deleteVendor, { loading: deleteRunning }] = useMutation<
    DeleteVendorData,
    DeleteVendorVariables
  >(DELETE_VENDOR, {
    onCompleted: (data) => {
      message.success("Record successfully deleted.");
    },
    onError: (data) => {
      message.error("Failed to delete record!");
    },
  });

  const [undeleteVendor, { loading: undeleteRunning }] = useMutation<
    UndeleteVendorData,
    UndeleteVendorVariables
  >(UNDELETE_VENDOR, {
    onCompleted: (data) => {
      message.success("Record successfully restored.");
    },
    onError: (data) => {
      message.error("Failed to restore record!");
    },
  });

  const addUrl = `/admin/app/vendor/0`;
  const editUrl = `/admin/app/vendor`;
  const history = useHistory();

  const handleAddVendor = async () => {
    history.push(`${addUrl}`);
  };

  const handleEditVendor = async (id: string) => {
    history.push(`${editUrl}/${id}`);
  };

  const handleDeleteVendor = async (id: string) => {
    await deleteVendor({ variables: { id } });
    refetch();
  };

  const handleUndeleteVendor = async (id: string) => {
    await undeleteVendor({ variables: { id } });
    refetch();
  };

  const columns: any = [
    {
      dataIndex: "id",
      key: "avatar",
      width: 27,
      render: (text: any, row: any) => (
        <div className="air__utils__avatar air__utils__avatar--size27">
          <img src={`/avatar/vendor/${text}?_ts=${refreshTs}`} alt="avatar" />
        </div>
      ),
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) => StringSorter(a.shortName, b.shortName),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) => StringSorter(a.name, b.name),
    },
    {
      title: "Coverage Start",
      dataIndex: "coverageStartDate",
      key: "coverageStartDate",
      align: "center",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText
          text={
            text
              ? moment(new Date(text)).format(dateFormat)
              : "-- not defined --"
          }
          isRecordDeleted={row.isDeleted}
        />
      ),
      sorter: (a: any, b: any) =>
        DateOnlySorter(a.coverageStartDate, b.coverageStartDate),
    },
    {
      title: "Coverage Termination",
      dataIndex: "coverageTerminationDate",
      key: "coverageTerminationDate",
      align: "center",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText
          text={
            text
              ? moment(new Date(text)).format(dateFormat)
              : "-- not defined --"
          }
          isRecordDeleted={row.isDeleted}
        />
      ),
      sorter: (a: any, b: any) =>
        DateOnlySorter(a.coverageTerminationDate, b.coverageTerminationDate),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any) =>
        row.isDeleted ? (
          <PopUndeleteVendor
            id={text}
            handleUndeleteVendor={handleUndeleteVendor}
          />
        ) : (
          <PopEditVendor
            id={text}
            handleEditVendor={handleEditVendor}
            handleDeleteVendor={handleDeleteVendor}
          />
        ),
    },
  ];

  if (loading) {
    return (
      <div className="admin">
        <VendorListSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <VendorListSkeleton title={title} error />
      </div>
    );
  }

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Spin spinning={deleteRunning || undeleteRunning}>
              <div>
                <Helmet title="Manage Vendors" />
                <div className="air__utils__heading">
                  <h5>Manage Vendors</h5>
                </div>

                <div className="card">
                  <div className="card-header card-header-flex">
                    <div className="d-flex flex-column justify-content-center mr-auto">
                      <h4 className="mb-0">
                        <strong>Vendor List</strong>
                      </h4>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{ marginTop: "25px" }}
                    >
                      {addUrl && (
                        <span>
                          <Button
                            onClick={() => handleAddVendor()}
                            size="small"
                            shape="round"
                          >
                            Add
                          </Button>
                        </span>
                      )}
                      &nbsp;
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="air__utils__scrollTable">
                      <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={vendors}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(hasAuthRole("Navigator"), hasAuthRole("AccountManager"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};

export const PopUndeleteVendor = ({
  id,
  handleUndeleteVendor,
}: PopUndeleteVendorProps) => {
  return (
    <span>
      <Popconfirm
        title={`Are you sure restore this deleted Vendor entry?`}
        onConfirm={() => handleUndeleteVendor(id)}
        okText="Yes"
        cancelText="No"
      >
        <Button size="small" shape="round">
          Restore
        </Button>
      </Popconfirm>
    </span>
  );
};

export const PopEditVendor = ({
  id,
  handleEditVendor,
  handleDeleteVendor,
}: PopEditVendorProps) => {
  return (
    <span>
      <Button
        style={{ marginRight: "5px" }}
        onClick={() => handleEditVendor(id)}
        size="small"
        shape="round"
      >
        Edit
      </Button>

      <Popconfirm
        title={`Are you sure delete this Vendor entry?`}
        onConfirm={() => handleDeleteVendor(id)}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ marginRight: "5px" }} size="small" shape="round">
          Delete
        </Button>
      </Popconfirm>
    </span>
  );
};
