import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  CONTACT_CATEGORY,
  ContactCategory as ContactCategoryData,
  ContactCategoryVariables,
} from "../../../../lib/graphql/queries";
import {
  CREATE_CONTACT_CATEGORY,
  CreateContactCategory as CreateContactCategoryData,
  CreateContactCategoryVariables,
  UPDATE_CONTACT_CATEGORY,
  UpdateContactCategory as UpdateContactCategoryData,
  UpdateContactCategoryVariables,
} from "../../../../lib/graphql/mutations/codes";
import { CodeForm, SetCodeFormValues } from "../../components"

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

export const ContactCategoryFormBase = ({ title, form }: Props & FormComponentProps) => {
  const codeType = "ContactCategory";
  const codeTypePlural = "ContactCategories";
  const { id } = useParams<MatchParams>();

  const { loading, error } = useQuery<ContactCategoryData, ContactCategoryVariables>(
    CONTACT_CATEGORY,
    {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
      skip: id === "0",
      onCompleted: (data) => {
        if(!data)return;

        SetCodeFormValues(form, data.contactCategory);
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  const [createCode, { loading: createRunning }] = useMutation<
    CreateContactCategoryData,
    CreateContactCategoryVariables
  >(CREATE_CONTACT_CATEGORY, {
    onCompleted: (data) => {
      message.success("Record successfully created.");
    },
    onError: (data) => {
      console.log(`Create error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to create record!");
    },
  });

  const [updateCode, { loading: updateRunning }] = useMutation<
    UpdateContactCategoryData,
    UpdateContactCategoryVariables
  >(UPDATE_CONTACT_CATEGORY, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
    },
  });

  return (
    <CodeForm
      form={form} 
      title={title} 
      codeType={codeType} 
      codeTypePlural={codeTypePlural}
      isNew={(id === '0')}
      codeMaxLength={2}
      hasDescription={false}
      queryLoading={loading} 
      queryError={error} 
      createCode={createCode} 
      createRunning={createRunning} 
      updateCode={updateCode} 
      updateRunning={updateRunning}
    />
  );
};

export const ContactCategoryForm = Form.create<Props & FormComponentProps>({
  name: "admin_code_contact_category_form",
})(ContactCategoryFormBase);
