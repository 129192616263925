import { useMutation } from "@apollo/react-hooks";
import { useHistory, Link } from "react-router-dom";
import React from "react";
import { Helmet } from "react-helmet";
import { message, Button, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment-timezone";
import {
  AUTH_GENERATE_PASSWORD_TOKEN,
  AuthGeneratePasswordToken as AuthGeneratePasswordTokenData,
  AuthGeneratePasswordTokenVariables,
} from "../../lib/graphql/mutations";

import loginLogo from "../Login/assets/login-logo.png";

interface Props {
  title: string;
}

moment.tz.setDefault("America/New_York");

export const ForgotPasswordBase = ({ title, form }: Props & FormComponentProps) => {
  const history = useHistory();
  const homeUrl = "/";

  const [authGeneratePasswordToken, { loading: forgotPasswordRunning }] = useMutation<
    AuthGeneratePasswordTokenData,
    AuthGeneratePasswordTokenVariables
  >(AUTH_GENERATE_PASSWORD_TOKEN, {
    onCompleted: (data) => {
      message.success("Password reset sent to your email on record.");
      gotoHome();
    },
    onError: (data) => {
      console.log(`Forgot password error: ${JSON.stringify(data, null, 2)}`);
      gotoHome();
    },
  });

  const gotoHome = () => {
    history.push(homeUrl);
  };

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!values.username && !values.email) {
        message.error("Please provide either Login ID or email!");
        return;
      }

      const input = {
        ...values,
      };

      authGeneratePasswordToken({ variables: input });

    });
  };

  return (
    <div>
      <Helmet title="Forgot Password" />
      <div
        className="d-flex mt-auto justify-content-center"
        style={{ paddingTop: "80px" }}
      >
        <div className="app-sider__logo">
          <img src={loginLogo} alt="Christie Campus Health" />
        </div>
      </div>
      <div className="pb-5 pr-3 d-flex mt-auto justify-content-center">
        <div className={`pl-5 pr-5 pb-5 bg-white`}>
          <div className="app-login text-dark font-size-30 mb-2 text-center">
            Reset Password
          </div>
          <Form
            layout="vertical"
            hideRequiredMark
            onSubmit={handleForgotPassword}
            className="mb-4"
          >
            <Form.Item>
              {form.getFieldDecorator("username", {
                rules: [
                  {
                    required: false,
                    message: "Please enter your Login ID",
                  },
                ],
              })(<Input size="large" placeholder="Enter Login ID" />)}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator("email", {
                rules: [
                  { 
                    required: false, 
                    message: "Please enter your email" 
                  },
                ],
              })(<Input size="large" placeholder="Enter Email" />)}
            </Form.Item>
            <Button
              type="primary"
              size="large"
              className="text-center btn btn-success w-100 font-weight-bold font-size-18"
              htmlType="submit"
              loading={forgotPasswordRunning}
            >
              Reset Password
            </Button>
          </Form>
          <div className="text-center">
            <Link
              to="/"
              className="text-blue font-weight-bold font-size-18"
            >
              Return to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ForgotPassword = Form.create<Props & FormComponentProps>({
  name: "forgot_password_form",
})(ForgotPasswordBase);
