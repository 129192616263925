import { gql } from "apollo-boost";
 
export const CREATE_INSURANCE = gql`
  mutation CreateInsurance($data: CreateInsuranceInput!) {
    createInsurance(data: $data) {
      id
      isActive
      insuranceCarrier
      idNumber
      policyNumber
      plan
      coverageLevel
      startOfCoverageDate
      endOfCoverageDate
      isSubscriber

      insuranceTypeId
      insuranceType {
        code
        name
      }

      insuredContactId
      insuredContact {
        id
        firstName
        middleName
        lastName
        addressLine1
        addressLine2
        city
        stateId
        state {
          code
          name
        }
        zipCode
        primaryPhone
        email
      }
      insuredPersonTypeId
      insuredDateOfBirth
      insuredSsn

      insuredGenderId
      insuredGender {
        code
        name
      }
    }
  }
`;
