import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Button, Select, Table } from "antd";
import moment from "moment-timezone";

import {
  DateSorter,
  DateOnlySorter,
  StringSorter,
  DefaultSorter,
  SettingsContext,
} from "../../../lib/utils";

import {
  RECENT_CALL_FOLLOWUPS,
  RecentCallFollowups as RecentFollowupsData,
  USERS,
  Users as NavigatorsData,
} from "../../../lib/graphql/queries";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../lib/utils";
import { useState } from "react";

interface Props {
  title: string;
}

export const RecentFollowups = ({ title }: Props) => {
  const { settings } = useContext(SettingsContext);
  moment.tz.setDefault(settings.moment ? settings.moment.timezone : "America/New_York");
  const dateFormat = settings.moment ? settings.moment.dateOnlyFormat : "MM/DD/YYYY";
  const dateTimeFormat = settings.moment ? settings.moment.dateTimeFormat : "MM/DD/YYYY hh:mm:ss a";
 
  let [filterNavigatorId, setFilterNavigatorId] = useState("0");
  const { Option } = Select;
  const history = useHistory();
  const studentUrl = "/student";

  const { data: navigatorsData } = useQuery<NavigatorsData>(USERS, {
    variables: {
      navigatorsOnly: true,
    },
    fetchPolicy: "no-cache",
  });

  const { data } = useQuery<RecentFollowupsData>(RECENT_CALL_FOLLOWUPS, {
    variables: {
      userId: filterNavigatorId,
    },
    fetchPolicy: "no-cache",
  });

  const followups = data ? data.recentCallFollowups : undefined;
  const followupColumns = [
    {
      title: "Last Activity ",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: any, row: any) => {
        return <span>{moment(new Date(text)).format(dateTimeFormat)}</span>;
      },
      sorter: (a: any, b: any) => DateSorter(a.dateDue, b.dateDue),
    },
    {
      title: "Due Date",
      dataIndex: "dateDue",
      key: "dateDue",
      render: (text: any, row: any) => {
        return <span>{moment(new Date(text)).format(dateFormat)}</span>;
      },
      sorter: (a: any, b: any) => DateOnlySorter(a.dateDue, b.dateDue),
    },
    {
      title: "Status",
      dataIndex: "followupStatus.name",
      key: "followupStatus.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.followupStatus.name, b.followupStatus.name),
    },
    {
      title: "#",
      dataIndex: "followupNumber",
      key: "followupNumber",
      sorter: (a: any, b: any) =>
        DefaultSorter(a.followupNumber, b.followupNumber),
    },
    {
      title: "Call Type",
      dataIndex: "call.callType.name",
      key: "call.callType.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.call.callType.name, b.call.callType.name),
    },
    {
      title: "Followup Type",
      dataIndex: "followupType.name",
      key: "followupType.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.followupType.name, b.followupType.name),
    },
    {
      title: "University",
      dataIndex: "call.student.university.shortName",
      key: "call.student.university.shortName",
      render: (text: any, row: any) => (
        <span>
          {row.call.student.university.shortName}
          {row.call.student.universityCampus
            ? ", " + row.call.student.universityCampus.name
            : ""}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.student.university.shortName +
          (a.call.student.universityCampus
            ? ", " + a.call.student.universityCampus.name
            : "");
        let strB =
          b.call.student.university.shortName +
          (b.call.student.universityCampus
            ? ", " + b.call.student.universityCampus.name
            : "");

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Student",
      dataIndex: "call.student.contact.firstName",
      key: "call.student.contact.firstName",
      render: (text: any, row: any) => (
        <span>
          {row.call.student.contact.firstName}
          {row.call.student.contact.middleName
            ? " " + row.call.student.contact.middleName
            : ""}
          &nbsp;
          {row.call.student.contact.lastName}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.student.contact.firstName +
          (a.call.student.contact.middleName
            ? " " + a.call.student.contact.middleName
            : "") +
          " " +
          a.call.student.contact.lastName;
        let strB =
          b.call.student.contact.firstName +
          (b.call.student.contact.middleName
            ? " " + b.call.student.contact.middleName
            : "") +
          " " +
          b.call.student.contact.lastName;

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Navigator",
      dataIndex: "call.navigatorUser.firstName",
      key: "call.navigatorUser.firstName",
      render: (text: any, row: any) => (
        <span>
          {row.call.navigatorUser.firstName}
          {row.call.navigatorUser.middleName
            ? " " + row.call.navigatorUser.middleName
            : ""}
          &nbsp;
          {row.call.navigatorUser.lastName}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.navigatorUser.firstName +
          (a.call.navigatorUser.middleName
            ? " " + a.call.navigatorUser.middleName
            : "") +
          " " +
          a.call.navigatorUser.lastName;
        let strB =
          b.call.navigatorUser.firstName +
          (b.call.navigatorUser.middleName
            ? " " + b.call.navigatorUser.middleName
            : "") +
          " " +
          b.call.navigatorUser.lastName;

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Action",
      dataIndex: "call.studentId",
      key: "call.studentId",
      render: (text: any, row: any) => (
        <span>
          <Button
            onClick={() => handleOpenfollowup(text)}
            size="small"
            shape="round"
          >
            Open
          </Button>
        </span>
      ),
    },
  ];

  const handleSelectChange = (value: string) => {
    setFilterNavigatorId(value);
  };

  const handleOpenfollowup = (id: string) => {
    history.push(`${studentUrl}/${id}`);
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Navigator"),
          hasAuthRole("AccountManager"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <div>
          <Helmet title="Navigator" />
          <div className="air__utils__heading">
            <h5>Recent Follow-ups</h5>
          </div>

          <div className="card">
            <div className="card-header card-header-flex">
              <div className="d-flex flex-column justify-content-center mr-auto">
                <h4 className="mb-0">
                  <strong>Recently Updated Follow-ups</strong>
                </h4>
              </div>
              <div className="d-flex flex-column justify-content-center">
                <Select
                  placeholder="Select a option and change input text above"
                  onChange={handleSelectChange}
                  defaultValue="0"
                  style={{ width: "140px" }}
                >
                  <Option value="0">(all users)</Option>
                  {navigatorsData &&
                    navigatorsData.users.map((x) => {
                      return (
                        <Option key={`${x.id}`} value={`${x.id}`}>
                          {x.firstName +
                            (x.middleName ? " " + x.middleName : "") +
                            " " +
                            x.lastName}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </div>
            <div className="card-body">
              <div className="air__utils__scrollTable">
                <Table
                  rowKey="id"
                  columns={followupColumns}
                  dataSource={followups}
                />
              </div>
            </div>
          </div>
        </div>
      </AuthVisible>

      <AuthVisible when={anyAuth(hasAuthRole("Administrator"))}>
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};
