import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Empty, Layout, Typography } from "antd";

const { Content } = Layout;
const { Text } = Typography;

export const Logout = () => {
  return (
    <Content className="not-found">
      <Empty
        description={
          <Fragment>
            <Text className="not-found__description-title">
              Logout
              <br />
              (This is a placeholder page.)
              <br />
              <Link to={`/login`}>Click here to Login</Link>
            </Text>
          </Fragment>
        }
      />
    </Content>
  );
};
