import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { message, Affix, Layout } from "antd";
import { MenuItems } from "./components";

const { Header } = Layout;

const displayErrorMessage = (error: string) => {
  return message.error(error);
};

export const AppHeader = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [search, setSearch] = useState("");

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const pathnameSubStrings = pathname.split("/");

    if (!pathname.includes("/listings")) {
      setSearch("");
      return;
    }

    if (pathname.includes("/listings") && pathnameSubStrings.length === 3) {
      setSearch(pathnameSubStrings[2]);
      return;
    }
  }, [location]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSearch = (value: string) => {
    const trimmedValue = value.trim();

    if (trimmedValue) {
      history.push(`/listings/${trimmedValue}`);
    } else {
      displayErrorMessage("Please enter a valid search!");
    }
  };

  return (
    <Affix offsetTop={0} className="app__affix-header">
      <Header className="app-header">
        <div className="app-header__logo-search-section">
          <div className="app-header__logo">&nbsp;</div>
          {/*<div className="app-header__search-input">
            <Search
              placeholder="Search 'Student'"
              enterButton
              value={search}
              onChange={(evt) => setSearch(evt.target.value)}
              onSearch={onSearch}
            />
          </div>*/}
        </div>
        <div className="app-header__menu-section">
          <MenuItems />
        </div>
      </Header>
    </Affix>
  );
};
