import { useMutation, useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router";
import React from "react";
import { Helmet } from "react-helmet";
import { message, Button, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment-timezone";
import {
  AUTH_RESET_PASSWORD,
  AuthResetPassword as AuthResetPasswordData,
  AuthResetPasswordVariables
} from "../../lib/graphql/mutations";
import {
  AUTH_PASSWORD_EXPIRED,
  AuthPasswordExpired as AuthPasswordExpiredData
} from "../../lib/graphql/queries";

import loginLogo from "../Login/assets/login-logo.png";

interface Props {
  title: string;
}

moment.tz.setDefault("America/New_York");

export const PasswordExpiredBase = ({ title, form }: Props & FormComponentProps) => {
  const history = useHistory();
  const homeUrl = "/";

  useQuery<
    AuthPasswordExpiredData
  >(AUTH_PASSWORD_EXPIRED, 
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        console.log(`Password expiration: ${JSON.stringify(data, null, 2)}`);

        if(!data.authPasswordExpired)
          gotoHome();
      },
      onError: (data) => {
        console.log(`Password token error: ${JSON.stringify(data, null, 2)}`);
      }
    }
  );

  const [authResetPassword, { loading: resetPasswordRunning }] = useMutation<
    AuthResetPasswordData,
    AuthResetPasswordVariables
  >(AUTH_RESET_PASSWORD, {
    onCompleted: (data) => {
      message.success("Password reset successful.");

      gotoHome();
    },
    onError: (data) => {
      console.log(`Reset password error: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to reset password!`);
    },
  });

  const gotoHome = () => {
    history.push(homeUrl);
  };

  const handleResetPassword = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      if (values.password !== values.confirmPassword) {
        message.error("Passwords do not match!");
        return;
      }

      authResetPassword({ variables: { resetToken: "", newPassword: values.password } });

    });
  };

  return (
    <div>
      <Helmet title="Password Expired" />
      <div
        className="d-flex mt-auto justify-content-center"
        style={{ paddingTop: "80px" }}
      >
        <div className="app-sider__logo">
          <img src={loginLogo} alt="Christie Campus Health" />
        </div>
      </div>
      <div className="pb-5 pr-3 d-flex mt-auto justify-content-center">
        <div className={`pl-5 pr-5 pb-5 bg-white`}>
          <div className="app-login text-dark font-size-30 mb-2 text-center">
            Password Expired
          </div>
          <Form
            layout="vertical"
            hideRequiredMark
            onSubmit={handleResetPassword}
            className="mb-4"
          >
            <Form.Item>
              {form.getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ],
              })(<Input.Password size="large" placeholder="Enter Password" />)}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator("confirmPassword", {
                rules: [
                  { 
                    required: true, 
                    message: "Please confirm your password" 
                  },
                ],
              })(<Input.Password size="large" placeholder="Confirm Password" />)}
            </Form.Item>
            <Button
              type="primary"
              size="large"
              className="text-center btn btn-success w-100 font-weight-bold font-size-18"
              htmlType="submit"
              loading={resetPasswordRunning}
            >
              Update Password
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export const PasswordExpired = Form.create<Props & FormComponentProps>({
  name: "reset_password_form",
})(PasswordExpiredBase);
