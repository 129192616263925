import { gql } from "apollo-boost";

export const UNIVERSITY_CAMPUSES = gql`
  query UniversityCampuses($universityId: String, $includeDeleted: Boolean) {
    universityCampuses(universityId: $universityId, includeDeleted: $includeDeleted) {
      id
      universityId
      campusKey
      name
      description
      isDefault
      isDeleted
    }
  }
`;
