import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  GENDER,
  Gender as GenderData,
  GenderVariables,
} from "../../../../lib/graphql/queries";
import {
  CREATE_GENDER,
  CreateGender as CreateGenderData,
  CreateGenderVariables,
  UPDATE_GENDER,
  UpdateGender as UpdateGenderData,
  UpdateGenderVariables,
} from "../../../../lib/graphql/mutations/codes";
import { CodeForm, SetCodeFormValues } from "../../components"

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

export const GenderFormBase = ({ title, form }: Props & FormComponentProps) => {
  const codeType = "Gender";
  const codeTypePlural = "Genders";
  const { id } = useParams<MatchParams>();

  const { loading, error } = useQuery<GenderData, GenderVariables>(
    GENDER,
    {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
      skip: id === "0",
      onCompleted: (data) => {
        if(!data)return;

        SetCodeFormValues(form, data.gender);
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  const [createCode, { loading: createRunning }] = useMutation<
    CreateGenderData,
    CreateGenderVariables
  >(CREATE_GENDER, {
    onCompleted: (data) => {
      message.success("Record successfully created.");
    },
    onError: (data) => {
      console.log(`Create error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to create record!");
    },
  });

  const [updateCode, { loading: updateRunning }] = useMutation<
    UpdateGenderData,
    UpdateGenderVariables
  >(UPDATE_GENDER, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
    },
  });

  return (
    <CodeForm
      form={form} 
      title={title} 
      codeType={codeType} 
      codeTypePlural={codeTypePlural}
      isNew={(id === '0')}
      codeMaxLength={2}
      hasDescription={false}
      queryLoading={loading} 
      queryError={error} 
      createCode={createCode} 
      createRunning={createRunning} 
      updateCode={updateCode} 
      updateRunning={updateRunning}
    />
  );
};

export const GenderForm = Form.create<Props & FormComponentProps>({
  name: "admin_code_gender_form",
})(GenderFormBase);
