import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { CodesSkeleton, CodeListManager } from "../../components";

import { ROLES, Roles as RolesData } from "../../../../lib/graphql/queries";
import {
  DELETE_ROLE,
  DeleteRole as DeleteRoleData,
  DeleteRoleVariables,
  UNDELETE_ROLE,
  UndeleteRole as UndeleteRoleData,
  UndeleteRoleVariables,
} from "../../../../lib/graphql/mutations/codes";

import "../../styles/admin.css";

interface Props {
  title: string;
}

export const RoleList = ({ title }: Props) => {
  const codeType = "Role";
  const codeTypePlural = "Roles";
  const canAddCodes = true;

  const { data, loading, error, refetch } = useQuery<RolesData>(ROLES, {
    variables: {
      includeDeleted: true,
    },
    fetchPolicy: "no-cache",
  });
  const codes = data ? data.roles : undefined;

  const [deleteCode, { loading: deleteRunning }] = useMutation<
    DeleteRoleData,
    DeleteRoleVariables
  >(DELETE_ROLE, {
    onCompleted: (data) => {
      message.success("Record successfully deleted.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to delete record!");
    },
  });

  const [undeleteCode, { loading: undeleteRunning }] = useMutation<
    UndeleteRoleData,
    UndeleteRoleVariables
  >(UNDELETE_ROLE, {
    onCompleted: (data) => {
      message.success("Record successfully restored.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to restore record!");
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} error />
      </div>
    );
  }

  return (
    <CodeListManager
      codeType={codeType}
      codeTypePlural={codeTypePlural}
      canAddCodes={canAddCodes}
      codes={codes}
      deleteRunning={deleteRunning}
      undeleteRunning={undeleteRunning}
      deleteCode={deleteCode}
      undeleteCode={undeleteCode}
    />
  );
};
