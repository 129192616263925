import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Modal } from "antd";
import moment from "moment-timezone";

import {
  STUDENT,
  Student as StudentData,
  StudentVariables,
  CALL,
  Call as CallData,
  Call_call as CallRecord,
  Call_call_callNotes as CallNoteRecord,
  Call_call_callFollowups as FollowupRecord,
  Call_call_callFollowups_followupNotes as FollowupNoteRecord,
  CallVariables,
} from "../../../lib/graphql/queries";

import { ViewStudentCallSkeleton } from "../ViewStudentCallSkeleton";

interface CallProps {
  call: CallRecord | null;
}

interface CallNotesProps {
  callNotes: CallNoteRecord[] | undefined;
}

interface FollowupsProps {
  followups: FollowupRecord[] | undefined;
}

interface FollowupNotesProps {
  followupNotes: FollowupNoteRecord[] | undefined;
}

interface FullNameFields {
  firstName: String | null | undefined;
  middleName: String | null | undefined;
  lastName: String | null | undefined;
}

interface Props {
  callId: string | null;
  studentId: string | null;
  visible: boolean;
  setVisible: (input: any) => void;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY hh:mm A";

export const ViewStudentCallModal = ({
  callId,
  studentId,
  visible,
  setVisible,
}: Props) => {
  const { data: studentData } = useQuery<StudentData, StudentVariables>(
    STUDENT,
    {
      variables: {
        id: studentId ?? "0",
      },
      fetchPolicy: "no-cache",
      skip: studentId === null,
    }
  );
  const student = studentData?.student ? studentData.student : null;
  console.log("ViewStudentCall - student: ", student);

  const { data: callData, loading, error } = useQuery<CallData, CallVariables>(
    CALL,
    {
      variables: {
        id: callId ?? "0",
      },
      fetchPolicy: "no-cache",
      skip: callId === null,
    }
  );

  let call = callData ? callData.call : null;
  // console.log("ViewStudentCall - after useQuery: ", data);

  if (loading) {
    return (
      <div className="admin">
        <ViewStudentCallSkeleton title={"Student Call"} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <ViewStudentCallSkeleton title={"Student Call"} error />
      </div>
    );
  }
  const fullName = (fields: FullNameFields) => {
    return (
      (fields && fields.firstName ? fields.firstName : "") +
      (fields && fields.middleName ? " " + fields.middleName : "") +
      (fields && fields.lastName ? " " + fields.lastName : "")
    );
  };

  const CallItem = ({ call }: CallProps) => {
    return (
      <div>
        {call && (
          <div key={"call-" + call.id}>
            <div className="d-flex flex-nowrap align-items-start pt-4">
              <div className="air__utils__avatar air__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
                <img
                  src="/resources/images/icons/call.jpg"
                  alt="Student Call"
                />
              </div>
              <div className="flex-grow-1">
                <div>
                  <div className="d-flex flex-wrap mb-2">
                    <div className="mr-auto">
                      <div className="text-gray-6">
                        <span className="text-dark font-weight-bold">
                          <i className="icmn-radio-unchecked mr-1 text-blue" />
                          {moment(call.caseOpenedAt).format(dateFormat)}
                        </span>
                        <br />
                        <span className="text-dark font-weight-bold">
                          {call.callType.name}
                        </span>
                      </div>
                      <div>{fullName(call.navigatorUser)}</div>
                    </div>
                  </div>

                  <CallNoteItems callNotes={call.callNotes} />

                  <FollowupItems followups={call.callFollowups} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const CallNoteItems = ({ callNotes }: CallNotesProps) => {
    return (
      <div>
        {callNotes &&
          callNotes.map((callNote) => {
            return (
              <div className="mb-3" key={"callNote-" + callNote.id}>
                <div>
                  <u>
                    <i>
                      {callNote.noteType.name} by{" "}
                      {fullName(callNote.updatedByUser)} on{" "}
                      {moment(callNote.updatedAt).format(dateFormat)}
                    </i>
                  </u>
                </div>
                {callNote.note}
              </div>
            );
          })}
      </div>
    );
  };

  const FollowupItems = ({ followups }: FollowupsProps) => {
    return (
      <div>
        {followups &&
          followups.map((followup) => {
            return (
              <div
                className="d-flex flex-nowrap align-items-start pt-4"
                key={"followup-" + followup.id}
              >
                <div className="air__utils__avatar air__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
                  <img
                    src="/resources/images/icons/follow-up.jpg"
                    alt="Follow-up Required"
                  />
                </div>
                <div className="flex-grow-1">
                  <div>
                    <div className="d-flex flex-wrap mb-2">
                      <div className="mr-auto">
                        <div className="text-gray-6">
                          <span className="text-dark font-weight-bold">
                            <i className="icmn-radio-unchecked mr-1 text-blue" />
                            {moment(followup.createdAt).format(dateFormat)}
                          </span>
                          <br />
                          <span className="text-dark font-weight-bold">
                            {followup.followupType.name}
                          </span>
                        </div>
                        <div>{fullName(followup.createdByUser)}</div>
                      </div>
                    </div>

                    <FollowupNoteItems followupNotes={followup.followupNotes} />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const FollowupNoteItems = ({ followupNotes }: FollowupNotesProps) => {
    return (
      <div>
        {followupNotes &&
          followupNotes.map((followupNote) => {
            return (
              <div className="mb-3" key={"followupNote-" + followupNote.id}>
                <div>
                  <u>
                    <i>
                      {followupNote.noteType.name} by{" "}
                      {fullName(followupNote.updatedByUser)} on{" "}
                      {moment(followupNote.updatedAt).format(dateFormat)}
                    </i>
                  </u>
                </div>
                {followupNote.note}
              </div>
            );
          })}
      </div>
    );
  };

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      title={"Student Call"}
      cancelText={"Close"}
      onCancel={handleClose}
      okButtonProps={{ style: { display: "none" } }}
      width={800}
    >
      <div>
        <div className="row">
          <div className="col-lg-7">
            <div>
              <b>
                {student && student.contact ? student.contact.firstName : ""}
                {student && student.contact && student.contact.middleName
                  ? " " + student.contact.middleName
                  : ""}
                &nbsp;
                {student && student.contact ? student.contact.lastName : ""}
              </b>
            </div>
            <div>
              <b>Status: </b>
              <i className="icmn-checkmark mr-1 text-success" />
              Active
            </div>
            <div>
              <b>Student ID: </b>
              {student ? student.studentIdentifier : ""}
            </div>
            <div>
              <b>Coverage: </b>
              <span>
                {student && student.coverageEffectiveDate
                  ? moment(student.coverageEffectiveDate).format(dateFormat)
                  : "--not defined--"}
              </span>{" "}
              to{" "}
              {student && student.coverageTerminationDate
                ? moment(student.coverageTerminationDate).format(dateFormat)
                : "--not defined--"}
            </div>
          </div>

          <div className="col-lg-5">
            <div>
              <b>
                {student && student.contact ? student.contact.primaryPhone : ""}
              </b>
            </div>
            <div>
              <b>{student && student.contact ? student.contact.email : ""}</b>
            </div>
            <div>
              {student && student.contact ? student.contact.addressLine1 : ""}
            </div>
            <div>
              {student && student.contact ? student.contact.city : ""}{" "}
              {student && student.contact && student.contact.state
                ? student.contact.state.code
                : ""}{" "}
              {student && student.contact ? student.contact.zipCode : ""}
            </div>
          </div>
        </div>
        <hr/>
        <CallItem call={call} />
      </div>
    </Modal>
  );
};
