import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
// import { Helmet } from "react-helmet";
import { Button, Select, Table } from "antd";
import moment from "moment-timezone";

import {
  DateOnlySorter,
  StringSorter,
  DefaultSorter,
  SettingsContext
} from "../../../../lib/utils";

import {
  OPEN_CALL_FOLLOWUPS,
  OpenCallFollowups as OpenFollowupsData,
  USERS,
  Users as NavigatorsData,
} from "../../../../lib/graphql/queries";
import { useState } from "react";

interface Props {
  title: string;
}

export const OpenFollowups = ({ title }: Props) => {
  const { settings } = useContext(SettingsContext);
  // console.log("AppRoutes() - Settings: ", settings);

  moment.tz.setDefault(settings.moment ? settings.moment.timezone : "America/New_York");
  const dateFormat = settings.moment ? settings.moment.dateOnlyFormat : "MM/DD/YYYY";

  let [filterNavigatorId, setFilterNavigatorId] = useState("0");
  const { Option } = Select;
  const history = useHistory();
  const studentUrl = "/student";

  const { data: navigatorsData } = useQuery<NavigatorsData>(USERS, {
    variables: {
      navigatorsOnly: true,
    },
    fetchPolicy: "no-cache",
  });

  const { data } = useQuery<OpenFollowupsData>(
    OPEN_CALL_FOLLOWUPS,
    { 
      variables: {
        userId: filterNavigatorId,
        openOnly: true,
      },
        fetchPolicy: "no-cache" }
  );

  const followups = data ? data.openCallFollowups : undefined;
  const taskPriority = settings.taskPriority;
  const followupColumns = [
    {
      title: "Priority",
      dataIndex: "daysToDueDate",
      key: "priority",
      render: (text: any, row: any) => {
        let days = +text;
        let labelText = taskPriority ? taskPriority.current.labelText : "Current";
        let labelClasses = taskPriority ? taskPriority.current.labelClasses : "font-size-12 badge badge-success";
      
        if (days < 0) {
          labelText = taskPriority ? taskPriority.overdue.labelText : "Overdue";
          labelClasses = taskPriority ? taskPriority.overdue.labelClasses : "font-size-12 badge badge-danger";
                }
        if (days === 0) {
          labelText = taskPriority ? taskPriority.dueToday.labelText : "Due Today";
          labelClasses = taskPriority ? taskPriority.dueToday.labelClasses : "font-size-12 badge badge-warning";
        }
        return <span className={labelClasses}>{labelText}</span>;
      },
      sorter: (a: any, b: any) => DateOnlySorter(a.dateDue, b.dateDue),
    },
    {
      title: "Due Date",
      dataIndex: "dateDue",
      key: "dateDue",
      render: (text: any, row: any) => {
        return <span>{moment(new Date(text)).format(dateFormat)}</span>;
      },
      sorter: (a: any, b: any) => DateOnlySorter(a.dateDue, b.dateDue),
    },
    {
      title: "#",
      dataIndex: "followupNumber",
      key: "followupNumber",
      sorter: (a: any, b: any) =>
        DefaultSorter(a.followupNumber, b.followupNumber),
    },
    {
      title: "Call Type",
      dataIndex: "call.callType.name",
      key: "call.callType.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.call.callType.name, b.call.callType.name),
    },
    {
      title: "Followup Type",
      dataIndex: "followupType.name",
      key: "followupType.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.followupType.name, b.followupType.name),
    },
    {
      title: "Status",
      dataIndex: "followupStatus.name",
      key: "followupStatus.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.followupStatus.name, b.followupStatus.name),
    },
    {
      title: "University",
      dataIndex: "call.student.university.shortName",
      key: "call.student.university.shortName",
      render: (text: any, row: any) => (
        <span>
          {row.call.student.university.shortName}
          {row.call.student.universityCampus
            ? ", " + row.call.student.universityCampus.name
            : ""}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.student.university.shortName +
          (a.call.student.universityCampus
            ? ", " + a.call.student.universityCampus.name
            : "");
        let strB =
          b.call.student.university.shortName +
          (b.call.student.universityCampus
            ? ", " + b.call.student.universityCampus.name
            : "");

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Student",
      dataIndex: "call.student.contact.firstName",
      key: "call.student.contact.firstName",
      render: (text: any, row: any) => (
        <span>
          {row.call.student.contact.firstName}
          {row.call.student.contact.middleName
            ? " " + row.call.student.contact.middleName
            : ""}
          &nbsp;
          {row.call.student.contact.lastName}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.student.contact.firstName +
          (a.call.student.contact.middleName
            ? " " + a.call.student.contact.middleName
            : "") +
          " " +
          a.call.student.contact.lastName;
        let strB =
          b.call.student.contact.firstName +
          (b.call.student.contact.middleName
            ? " " + b.call.student.contact.middleName
            : "") +
          " " +
          b.call.student.contact.lastName;

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Navigator",
      dataIndex: "call.navigatorUser.firstName",
      key: "call.navigatorUser.firstName",
      render: (text: any, row: any) => (
        <span>
          {row.call.navigatorUser.firstName}
          {row.call.navigatorUser.middleName
            ? " " + row.call.navigatorUser.middleName
            : ""}
          &nbsp;
          {row.call.navigatorUser.lastName}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.navigatorUser.firstName +
          (a.call.navigatorUser.middleName
            ? " " + a.call.navigatorUser.middleName
            : "") +
          " " +
          a.call.navigatorUser.lastName;
        let strB =
          b.call.navigatorUser.firstName +
          (b.call.navigatorUser.middleName
            ? " " + b.call.navigatorUser.middleName
            : "") +
          " " +
          b.call.navigatorUser.lastName;

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Action",
      dataIndex: "call.studentId",
      key: "call.studentId",
      render: (text: any, row: any) => (
        <span>
          <Button
            onClick={() => handleOpenfollowup(text)}
            size="small"
            shape="round"
          >
            Open
          </Button>
        </span>
      ),
    },
  ];

  const handleSelectChange = (value: string) => {
    setFilterNavigatorId(value);
  };

  const handleOpenfollowup = (id: string) => {
    history.push(`${studentUrl}/${id}`);
  };

  return (
    <div className="card">
      <div className="card-header card-header-flex">
        <div className="d-flex flex-column justify-content-center mr-auto">
          <h4 className="mb-0">
            <strong>Open Followups</strong>
          </h4>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <Select
            placeholder="Select a option and change input text above"
            onChange={handleSelectChange}
            defaultValue="0"
            style={{ width: "140px" }}
          >
            <Option value="0">(all users)</Option>
            {navigatorsData &&
              navigatorsData.users.map((x) => {
                return (
                  <Option key={`${x.id}`} value={`${x.id}`}>
                    {x.firstName +
                      (x.middleName ? " " + x.middleName : "") +
                      " " +
                      x.lastName}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
      <div className="card-body">
        <div className="air__utils__scrollTable">
          <Table rowKey="id" columns={followupColumns} dataSource={followups} />
        </div>
      </div>
    </div>
  );
};
