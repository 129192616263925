import React, { Fragment } from "react";
import { Empty, Layout, Typography } from "antd";

const { Content } = Layout;
const { Text } = Typography;

export const HelpDashboard = () => {
  return (
    <Content className="not-found">
      <Empty
        description={
          <Fragment>
            <Text className="not-found__description-title">
              Help Dashboard
              <br />
              (This is a placeholder page.)
            </Text>
          </Fragment>
        }
      />
    </Content>
  );
};
