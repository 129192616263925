import { gql } from "apollo-boost";
 
export const STUDENT_TYPES = gql`
  query StudentTypes($includeDeleted: Boolean) {
    studentTypes(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
