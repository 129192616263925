import { gql } from "apollo-boost";

export const SEARCH_STUDENTS = gql`
query SearchStudents($searchTerms: String!, $universityId: Int!, $universityCampusId: Int!, $orderBy: String!) {
  searchStudents(searchTerms: $searchTerms, universityId: $universityId, universityCampusId: $universityCampusId, orderBy: $orderBy ) {
    id
    studentIdentifier
    dateOfBirth
    contact{
      id
      firstName
      middleName
      lastName
      primaryPhone
      email
    },
    university{
      id
      shortName
      name
      universityKey
    },
    universityCampus{
      id
      name
      campusKey
    }
  }
}
`;
