import { gql } from "apollo-boost";
 
export const CONTACT_CATEGORY = gql`
  query ContactCategory($id: String!) {
    contactCategory(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
