import { gql } from "apollo-boost";
 
export const FOLLOWUP_STATUS = gql`
  query FollowupStatus($id: String!) {
    followupStatus(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
