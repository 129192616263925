import { gql } from "apollo-boost";
 
export const COUNTRY = gql`
  query Country($id: String!) {
    country(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
