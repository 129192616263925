import { gql } from "apollo-boost";

export const STATE = gql`
  query State($id: String!) {
    state(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
