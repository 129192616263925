import { gql } from "apollo-boost";
 
export const CONTACT_METHODS = gql`
  query ContactMethods($includeDeleted: Boolean) {
    contactMethods(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
