import { gql } from "apollo-boost";

export const AUTH_LOGIN = gql`
  mutation AuthLogin($username: String!, $password: String!) {
    authLogin(data:{username: $username, password: $password}) {
      id
      userStatusId
      userStatus{
        id
        code
        name
      }
      roleId
      role{
        id
        code
        name
      }
      email
      altEmail
      firstName
      middleName
      lastName
      primaryPhone
      canSmsPrimaryPhone
      altPhone
      canSmsAltPhone
      avatar
      userCredential{
        id
        username
        lastLoginAt
      }
      isDeleted
    }
  }
`;
