import { gql } from "apollo-boost";

export const ROLES = gql`
  query Roles($includeDeleted: Boolean) {
    roles(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
