import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link, useHistory, useParams } from "react-router-dom";
import { Form, Select, Spin, Alert, DatePicker, message } from "antd";
import { Helmet } from "react-helmet";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment-timezone";

import styles from "./style.module.scss";

import {
  REPORT_PROFILE,
  ReportProfile as ReportProfileData,
  ReportProfile_reportProfile as ReportProfileRecord,
  ReportProfileVariables,
  UNIVERSITIES,
  Universities as UniversitiesData,
  UNIVERSITY_CAMPUSES,
  UniversityCampuses as UniversityCampusesData,
  USERS,
  Users as NavigatorsData,
} from "../../../lib/graphql/queries";

import {
  CREATE_REPORT,
  CreateReport as CreateReportData,
  CreateReportVariables,
} from "../../../lib/graphql/mutations";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../lib/utils";

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

const { Option } = Select;
const { RangePicker } = DatePicker;

moment.tz.setDefault("America/New_York");

const ReportRunnerBase = ({ title, form }: Props & FormComponentProps) => {
  const { id } = useParams<MatchParams>();
  const history = useHistory();
  const dashUrl = `/reporting`;
  let reportViewerBaseUrl = "/reporting/view-report";
  let [isReportImplemented, setIsReportImplemented] = useState(true);
  let [reportProfile, setReportProfile] = useState<ReportProfileRecord | null>(
    null
  );
  let [reportTitle, setReportTitle] = useState("Sample Report Title");
  let [universityId, setUniversityId] = useState<string | null>(null);
  let [universityCampusId, setUniversityCampusId] = useState<string | null>(
    null
  );
  let [navigatorUserId, setNavigatorUserId] = useState<string | null>(null);
  let [startDate, setStartDate] = useState<string | null>(null);
  let [endDate, setEndDate] = useState<string | null>(null);

  const { data: universitiesData } = useQuery<UniversitiesData>(UNIVERSITIES, {
    fetchPolicy: "no-cache",
  });
  const { data: universityCampusesData } = useQuery<UniversityCampusesData>(
    UNIVERSITY_CAMPUSES,
    {
      variables: {
        universityId: "",
      },
      fetchPolicy: "no-cache",
    }
  );

  const { data: navigatorsData } = useQuery<NavigatorsData>(USERS, {
    variables: {
      navigatorsOnly: true,
    },
    fetchPolicy: "no-cache",
  });

  useQuery<ReportProfileData, ReportProfileVariables>(REPORT_PROFILE, {
    variables: {
      id,
    },
    fetchPolicy: "no-cache",
    skip: id === "0",
    onCompleted: (data) => {
      if (data) {
        const reportProfile = data.reportProfile ? data.reportProfile : null;
        if (reportProfile) {
          // console.log("ReportProfile: ", reportProfile);
          setIsReportImplemented(
            reportProfile.description !== "(not yet implemented)"
          );
          setReportProfile(reportProfile);
          setReportTitle(reportProfile.title);
        }
      }
    },
    onError: (data) => {
      console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to fetch the record.`);
    },
  });

  const [createReport, { loading: reportRunning }] = useMutation<
    CreateReportData,
    CreateReportVariables
  >(CREATE_REPORT, {
    onCompleted: (data) => {
      message.success("Report created successfully.");
      console.log(`ReportRunnerBase -data: ${JSON.stringify(data, null, 2)}`);

      if (reportProfile && data.createReport) {
        reportViewerBaseUrl = reportProfile.viewerRoute
          ? reportProfile.viewerRoute
          : reportViewerBaseUrl;
        history.push(reportViewerBaseUrl + "/" + data.createReport + "/new");
      }
    },
    onError: (data) => {
      console.log(
        `ReportRunnerBase - Report create error - data: ${JSON.stringify(
          data,
          null,
          2
        )}`
      );
      message.error("Failed to create the report!");
    },
  });

  const handleUniverityChange = (value: string) => {
    console.log(`ReportRunnerBase - handleUniverityChange() - value: ${value}`);

    if (value === "0") {
      setUniversityId(null);
    } else {
      setUniversityId(value);
    }
  };
  const handleUniverityCampusChange = (value: string) => {
    console.log(
      `ReportRunnerBase - handleUniverityCampusChange() - value: ${value}`
    );

    if (value === "0") {
      setUniversityCampusId(null);
    } else {
      setUniversityCampusId(value);
    }
  };
  const handleNavigatorUserChange = (value: string) => {
    console.log(
      `ReportRunnerBase - handleNavigatorUserChange() - value: ${value}`
    );

    if (value === "0") {
      setNavigatorUserId(null);
    } else {
      setNavigatorUserId(value);
    }
  };
  const handleDateRangeChange = (
    dates: RangePickerValue,
    dateStrings: [string, string]
  ) => {
    console.log(
      `ReportRunnerBase - handleDateRangeChange() - dates: ${JSON.stringify(
        dates
      )}`
    );
    console.log(
      `ReportRunnerBase - handleDateRangeChange() - dateStrings: ${JSON.stringify(
        dateStrings
      )}`
    );
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const handleRunReport = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (reportProfile) {
        createReport({
          variables: {
            data: {
              reportProfileId: +reportProfile.id,
              params: {
                universityId,
                universityCampusId,
                startDate,
                endDate,
                navigatorUserId,
              },
            },
          },
        });
      }
    });
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Navigator"),
          hasAuthRole("AccountManager"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <div>
          <Helmet title={reportTitle} />
          <div className="air__utils__heading">
            <h5>
              <Link to={`${dashUrl}`}>Reports</Link>&nbsp;:&nbsp;
              {reportTitle}
            </h5>
          </div>

          <div className="card">
            <div className="card-header">
              <h4 className="mb-4">
                <strong>{reportTitle}</strong>
              </h4>

              <Spin spinning={reportRunning}>
                <Form labelAlign="left" colon={false}>
                  <div className="row">
                    {reportProfile?.params?.filters?.universityId === 1 && (
                      <div className="col-md-3">
                        <Form.Item label="University">
                          {form.getFieldDecorator("universityId", {
                            initialValue: "0",
                          })(
                            <Select onChange={handleUniverityChange}>
                              <Option value="0">(all universities)</Option>
                              {universitiesData &&
                                universitiesData.universities.map((x) => {
                                  return (
                                    <Option key={`${x.id}`} value={`${x.id}`}>
                                      {x.name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          )}
                        </Form.Item>
                      </div>
                    )}

                    {reportProfile?.params?.filters?.universityCampusId ===
                      1 && (
                      <div className="col-md-3">
                        <Form.Item label="Campus">
                          {form.getFieldDecorator("universityCampusId", {
                            initialValue: "0",
                          })(
                            <Select onChange={handleUniverityCampusChange}>
                              <Option value="0">(all campuses)</Option>
                              {universityCampusesData &&
                                universityCampusesData.universityCampuses
                                  .filter((x) => {
                                    return (
                                      "" + x.universityId ===
                                      form.getFieldValue("universityId")
                                    );
                                  })
                                  .map((y) => {
                                    return (
                                      <Option key={`${y.id}`} value={`${y.id}`}>
                                        {y.name}
                                      </Option>
                                    );
                                  })}
                            </Select>
                          )}
                        </Form.Item>
                      </div>
                    )}

                    {reportProfile?.params?.filters?.dataRange === 1 && (
                      <div className="col-md-3">
                        <Form.Item label="Date Range">
                          {form.getFieldDecorator("dateRange")(
                            <RangePicker onChange={handleDateRangeChange} />
                          )}
                        </Form.Item>
                      </div>
                    )}

                    {reportProfile?.params?.filters?.navigatorId === 1 && (
                      <div className="col-md-3">
                        <Form.Item label="Navigator">
                          {form.getFieldDecorator("navigatorUserIdId", {
                            initialValue: "0",
                          })(
                            <Select onChange={handleNavigatorUserChange}>
                              <Option value="0">(all users)</Option>
                              {navigatorsData &&
                                navigatorsData.users.map((x) => {
                                  return (
                                    <Option key={`${x.id}`} value={`${x.id}`}>
                                      {x.firstName +
                                        (x.middleName
                                          ? " " + x.middleName
                                          : "") +
                                        " " +
                                        x.lastName}
                                    </Option>
                                  );
                                })}
                            </Select>
                          )}
                        </Form.Item>
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Form.Item>
                        {form.getFieldDecorator("runReport")(
                          <button
                            type="button"
                            className="btn btn-success px-5"
                            onClick={handleRunReport}
                            disabled={!isReportImplemented}
                          >
                            {isReportImplemented
                              ? "Run Report"
                              : "(not yet implemented)"}
                          </button>
                        )}
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </Spin>
            </div>

            {reportRunning && (
              <div>
                <Spin spinning={reportRunning}>
                  <Alert
                    className={styles.warning}
                    message="Running your report..."
                    type="info"
                  />
                </Spin>
              </div>
            )}
          </div>
        </div>
      </AuthVisible>

      <AuthVisible when={anyAuth(hasAuthRole("Administrator"))}>
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};

export const ReportRunner = Form.create<Props & FormComponentProps>({
  name: "report_runner_form",
})(ReportRunnerBase);
