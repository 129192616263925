import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { CodesSkeleton, CodeListManager } from "../../components";

import {
  NOTE_TYPES,
  NoteTypes as NoteTypesData,
} from "../../../../lib/graphql/queries";
import {
  DELETE_NOTE_TYPE,
  DeleteNoteType as DeleteNoteTypeData,
  DeleteNoteTypeVariables,
  UNDELETE_NOTE_TYPE,
  UndeleteNoteType as UndeleteNoteTypeData,
  UndeleteNoteTypeVariables,
} from "../../../../lib/graphql/mutations/codes";

import "../../styles/admin.css";

interface Props {
  title: string;
}

export const NoteTypeList = ({ title }: Props) => {
  const codeType = "NoteType";
  const codeTypePlural = "NoteTypes";
  const canAddCodes = true;

  const { data, loading, error, refetch } = useQuery<NoteTypesData>(
    NOTE_TYPES,
    {
      variables: {
        includeDeleted: true,
      },
      fetchPolicy: "no-cache",
    }
  );
  const codes = data ? data.noteTypes : undefined;

  const [deleteCode, { loading: deleteRunning }] = useMutation<
    DeleteNoteTypeData,
    DeleteNoteTypeVariables
  >(DELETE_NOTE_TYPE, {
    onCompleted: (data) => {
      message.success("Record successfully deleted.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to delete record!");
    },
  });

  const [undeleteCode, { loading: undeleteRunning }] = useMutation<
    UndeleteNoteTypeData,
    UndeleteNoteTypeVariables
  >(UNDELETE_NOTE_TYPE, {
    onCompleted: (data) => {
      message.success("Record successfully restored.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to restore record!");
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} error />
      </div>
    );
  }

  return (
    <CodeListManager
      codeType={codeType}
      codeTypePlural={codeTypePlural}
      canAddCodes={canAddCodes}
      codes={codes}
      deleteRunning={deleteRunning}
      undeleteRunning={undeleteRunning}
      deleteCode={deleteCode}
      undeleteCode={undeleteCode}
    />
  );
};
