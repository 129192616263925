import React /*, { useState }*/ from "react";
import { useHistory, Link } from "react-router-dom";
import { Icon, Layout, Menu } from "antd";

import siderLogo from "./assets/sider-logo.png";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../lib/utils";

const { Sider } = Layout;
const { SubMenu } = Menu;

export const AppLeftMenu = () => {
  const history = useHistory();
  // const [collapsed, setCollapsed] = useState(false);

  // const onCollapse = (collapsed: boolean) => {
  //   console.log(collapsed);
  //   setCollapsed(collapsed);
  // };

  const navigateTo = (destUrl: string) => {
    history.push(destUrl);
  };

  return (
    <Sider
      width="250"
      className="app-sider__Background" /*collapsible collapsed={collapsed} onCollapse={onCollapse}*/
    >
      <div className="app-sider__logo">
        <Link to="/">
          <img src={siderLogo} alt="Christie Campus Health" />
        </Link>
      </div>
      <div>
        <AuthVisible
          when={anyAuth(
            hasAuthRole("Navigator"),
            hasAuthRole("AccountManager")
          )}
        >
          <div>
            <Menu
              className="app-sider__Background"
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
            >
              <Menu.Item key="1" onClick={() => navigateTo("/")}>
                <span>
                  <Icon type="home" />
                  <span>Dashboard</span>
                </span>
              </Menu.Item>

              <SubMenu
                className="app-sider__Background"
                key="S3"
                title={
                  <span>
                    <Icon type="tool" />
                    <span>Tools</span>
                  </span>
                }
              >
                <Menu.Item
                  key="10"
                  onClick={() => navigateTo("/tools/recent-followups")}
                >
                  Recent Followups
                </Menu.Item>
                <Menu.Item
                  key="9"
                  onClick={() => navigateTo("/tools/duplicateStudent/0/0")}
                >
                  Merge Duplicate Students
                </Menu.Item>
              </SubMenu>

              <SubMenu
                className="app-sider__Background"
                key="S2"
                title={
                  <span>
                    <Icon type="appstore-o" />
                    <span>Reporting</span>
                  </span>
                }
              >
                <Menu.Item key="6" onClick={() => navigateTo("/reporting")}>
                  Reporting Dashboard
                </Menu.Item>
                <Menu.Item
                  key="7"
                  onClick={() => navigateTo("/reporting/history")}
                >
                  Reporting History
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="8" onClick={() => navigateTo("/help")}>
                <span>
                  <Icon type="question-circle-o" />
                  <span>Help</span>
                </span>
              </Menu.Item>
            </Menu>
          </div>
        </AuthVisible>
      </div>
      <div>
        <AuthVisible
          when={anyAuth(hasAuthRole("Auditor"))}
        >
          <div>
            <Menu
              className="app-sider__Background"
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
            >
              <Menu.Item key="1" onClick={() => navigateTo("/")}>
                <span>
                  <Icon type="home" />
                  <span>Dashboard</span>
                </span>
              </Menu.Item>

              <SubMenu
                className="app-sider__Background"
                key="S3"
                title={
                  <span>
                    <Icon type="tool" />
                    <span>Tools</span>
                  </span>
                }
              >
                <Menu.Item
                  key="10"
                  onClick={() => navigateTo("/tools/recent-followups")}
                >
                  Recent Followups
                </Menu.Item>
              </SubMenu>

              <SubMenu
                className="app-sider__Background"
                key="S2"
                title={
                  <span>
                    <Icon type="appstore-o" />
                    <span>Reporting</span>
                  </span>
                }
              >
                <Menu.Item key="6" onClick={() => navigateTo("/reporting")}>
                  Reporting Dashboard
                </Menu.Item>
                <Menu.Item
                  key="7"
                  onClick={() => navigateTo("/reporting/history")}
                >
                  Reporting History
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="8" onClick={() => navigateTo("/help")}>
                <span>
                  <Icon type="question-circle-o" />
                  <span>Help</span>
                </span>
              </Menu.Item>
            </Menu>
          </div>
        </AuthVisible>
      </div>
      <div>
        <AuthVisible
          when={anyAuth(
            hasAuthRole("PortalManager")
          )}
        >
          <div>
            <Menu
              className="app-sider__Background"
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
            >
              <Menu.Item key="1" onClick={() => navigateTo("/")}>
                <span>
                  <Icon type="home" />
                  <span>Dashboard</span>
                </span>
              </Menu.Item>

              <SubMenu
                className="app-sider__Background"
                key="S1"
                title={
                  <span>
                    <Icon type="setting" />
                    <span>Admin Pages</span>
                  </span>
                }
              >
                <Menu.Item
                  key="2"
                  onClick={() => navigateTo("/admin/system/users")}
                >
                  Manage Users
                </Menu.Item>
                <Menu.Item
                  key="3"
                  onClick={() => navigateTo("/admin/app/universities")}
                >
                  Manage Universities
                </Menu.Item>
                <Menu.Item
                  key="4"
                  onClick={() => navigateTo("/admin/app/vendors")}
                >
                  Manage Vendors
                </Menu.Item>
                <Menu.Item key="5" onClick={() => navigateTo("/admin/codes")}>
                  Manage Codes
                </Menu.Item>
              </SubMenu>

              <SubMenu
                className="app-sider__Background"
                key="S3"
                title={
                  <span>
                    <Icon type="tool" />
                    <span>Tools</span>
                  </span>
                }
              >
                <Menu.Item
                  key="10"
                  onClick={() => navigateTo("/tools/recent-followups")}
                >
                  Recent Followups
                </Menu.Item>
                <Menu.Item
                  key="9"
                  onClick={() => navigateTo("/tools/duplicateStudent/0/0")}
                >
                  Merge Duplicate Students
                </Menu.Item>
              </SubMenu>

              <SubMenu
                className="app-sider__Background"
                key="S2"
                title={
                  <span>
                    <Icon type="appstore-o" />
                    <span>Reporting</span>
                  </span>
                }
              >
                <Menu.Item key="6" onClick={() => navigateTo("/reporting")}>
                  Reporting Dashboard
                </Menu.Item>
                <Menu.Item
                  key="7"
                  onClick={() => navigateTo("/reporting/history")}
                >
                  Reporting History
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="8" onClick={() => navigateTo("/help")}>
                <span>
                  <Icon type="question-circle-o" />
                  <span>Help</span>
                </span>
              </Menu.Item>
            </Menu>
          </div>
        </AuthVisible>
      </div>
      <div>
        <AuthVisible when={anyAuth(hasAuthRole("Administrator"))}>
          <div>
          <Menu
              className="app-sider__Background"
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
            >
              <SubMenu
                className="app-sider__Background"
                key="S1"
                title={
                  <span>
                    <Icon type="setting" />
                    <span>Admin Pages</span>
                  </span>
                }
              >
                <Menu.Item
                  key="2"
                  onClick={() => navigateTo("/admin/system/users")}
                >
                  Manage Users
                </Menu.Item>
                <Menu.Item
                  key="3"
                  onClick={() => navigateTo("/admin/app/universities")}
                >
                  Manage Universities
                </Menu.Item>
                <Menu.Item
                  key="4"
                  onClick={() => navigateTo("/admin/app/vendors")}
                >
                  Manage Vendors
                </Menu.Item>
                <Menu.Item key="5" onClick={() => navigateTo("/admin/codes")}>
                  Manage Codes
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="8" onClick={() => navigateTo("/help")}>
                <span>
                  <Icon type="question-circle-o" />
                  <span>Help</span>
                </span>
              </Menu.Item>
            </Menu>
          </div>
        </AuthVisible>
      </div>
    </Sider>
  );
};
