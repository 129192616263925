import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import { message, Layout, Tabs } from "antd";

import moment from "moment-timezone";

import {
  InsuranceList,
  StudentCallHistory,
  StudentDetailForm,
  StudentFormSkeleton,
  StudentInsuranceForm,
  StudentOpenCalls,
  VendorInteractionList,
} from "./components";

import {
  STUDENT,
  Student as StudentData,
  Student_student as StudentRecord,
  Student_student_insurances as InsuranceRecord,
  StudentVariables,
} from "../../lib/graphql/queries";
import { ApolloError } from "apollo-boost";
import { AuthVisible, hasAuthRole, anyAuth } from "../../lib/utils";

const { Content } = Layout;
const { TabPane } = Tabs;

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

export const StudentForm = ({ title }: Props) => {
  const { id } = useParams<MatchParams>();

  const [, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);
  console.log("Refreshing student sidebar");

  let [tabKey, setTabKey] = useState("3");
  let [insuranceId, setInsuranceId] = useState("");
  let [student, setStudent] = useState<StudentRecord | undefined>(undefined);
  let [insurances, setInsurances] = useState<InsuranceRecord[]>([]);

  console.log("Studentform() - Loading StudentForm - id: ", id);

  const refreshStudentSidebar = () => {
    console.log("In refreshStudentSidebar() - id: ", id);
    loadStudent({ variables: { id } });
    forceUpdate();
  };

  const handleStudentOnCompleted = (data: StudentData) => {
    console.log("useQuery<StudentData, StudentVariables>.onCompleted()");
    if (data) {
      const studentRecord = data.student ? data.student : null;
      console.log(
        "useQuery<StudentData, StudentVariables>.onCompleted() - studentRecord:",
        studentRecord
      );
      if (studentRecord) {
        console.log(
          "useQuery<StudentData, StudentVariables>.onCompleted() - setStudentRecord()"
        );
        setStudent(studentRecord);
        if (studentRecord.insurances.length > 0) {
          studentRecord.insurances = studentRecord.insurances.filter(function(
            item
          ) {
            return !item.isDeleted;
          });
          console.log(
            "Studentform() - studentRecord.insurances: ",
            studentRecord.insurances
          );
          setInsurances(studentRecord.insurances);
        } else {
          console.log("Studentform() - no insurances");
          setInsurances([]);
        }
        if (insuranceId !== "") {
          if (studentRecord.insurances.length > 0) {
            setInsuranceId(
              studentRecord.insurances[studentRecord.insurances.length - 1].id
            );
          }
        }
      }
    }
  };

  const handleStudentOnError = (data: ApolloError) => {
    console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
    message.error(`Failed to fetch the record.`);
  };

  const { loading, error } = useQuery<StudentData, StudentVariables>(STUDENT, {
    variables: { id },
    fetchPolicy: "no-cache",
    skip: id === "0",
    onCompleted: handleStudentOnCompleted,
    onError: handleStudentOnError,
  });

  const [loadStudent] = useLazyQuery<StudentData, StudentVariables>(STUDENT, {
    fetchPolicy: "no-cache",
    onCompleted: handleStudentOnCompleted,
    onError: handleStudentOnError,
  });

  if (loading) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={title} error />
      </div>
    );
  }

  const changeTab = (tabKeyValue: string) => {
    setTabKey(tabKeyValue);
  };

  const changeInsuranceId = (insuranceId: string) => {
    setInsuranceId(insuranceId);
    changeTab("2");
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Navigator"),
          hasAuthRole("AccountManager"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Helmet title="Navigator: Students" />
            <div className="air__utils__heading">
              <h5>Navigator: Students</h5>
            </div>

            <div className="row">
              <div className="col-xl-4 col-lg-12">
                <div className="card">
                  <div className="d-flex flex-wrap flex-column">
                    <div className="air__utils__avatar air__utils__avatar--size64 ml-4 mt-2 mb-0">
                      <img src={`/avatar/university/${student?.universityId}`}
                        alt={
                          student && student.university
                            ? student.university.name
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <div className="text-dark font-weight-bold font-size-18 ml-4">
                        {student && student.university
                          ? student.university.name
                          : ""}
                        {student && student.universityCampus
                          ? ", " + student.universityCampus.name
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div>
                      <b>
                        {student && student.contact
                          ? student.contact.firstName
                          : ""}
                        {student &&
                        student.contact &&
                        student.contact.middleName
                          ? " " + student.contact.middleName
                          : ""}
                        &nbsp;
                        {student && student.contact
                          ? student.contact.lastName
                          : ""}
                      </b>
                    </div>
                    <div>
                      <b>
                        {student && student.contact
                          ? student.contact.primaryPhone
                          : ""}
                      </b>
                    </div>
                    <div>
                      <b>
                        {student && student.contact
                          ? student.contact.email
                          : ""}
                      </b>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <b>Status: </b>
                      <i className="icmn-checkmark mr-1 text-success" />
                      Active
                    </div>
                    <div>
                      <b>Student ID: </b>
                      {student ? student.studentIdentifier : ""}
                    </div>
                    <div>
                      <b>Coverage: </b>
                      <span>
                        {student && student.coverageEffectiveDate
                          ? moment(student.coverageEffectiveDate).format(
                              dateFormat
                            )
                          : "(n/a)"}
                      </span>{" "}
                      to{" "}
                      {student && student.coverageTerminationDate
                        ? moment(student.coverageTerminationDate).format(
                            dateFormat
                          )
                        : "(n/a)"}
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <b>Address: </b>
                    </div>
                    <div>
                      {student && student.contact
                        ? student.contact.addressLine1
                        : ""}
                    </div>
                    <div>
                      {student && student.contact ? student.contact.city : ""}{" "}
                      {student && student.contact && student.contact.state
                        ? student.contact.state.code
                        : ""}{" "}
                      {student && student.contact
                        ? student.contact.zipCode
                        : ""}
                    </div>
                  </div>
                </div>

                <InsuranceList
                  title="Student Insurance Information"
                  insurances={insurances}
                  changeInsuranceId={changeInsuranceId}
                  refreshStudentSidebar={refreshStudentSidebar}
                />
              </div>
              <div className="col-xl-8 col-lg-12">
                <div className="card">
                  <div className="card-header card-header-flex flex-column">
                    <Tabs
                      activeKey={tabKey}
                      className="mr-auto air-tabs-bold"
                      onChange={changeTab}
                    >
                      <TabPane tab="Student Profile" key="1" />
                      <TabPane tab="Insurance" key="2" />
                      <TabPane tab="Open Calls" key="3" />
                      <TabPane tab="Call History" key="4" />
                      <TabPane tab="24/7 Support Calls" key="5" />
                    </Tabs>
                  </div>
                  <div className="card-body">
                    {tabKey === "1" && (
                      <StudentDetailForm
                        studentId={student ? student.id : undefined}
                        refreshStudentSidebar={refreshStudentSidebar}
                      />
                    )}
                    {tabKey === "2" && (
                      <StudentInsuranceForm
                        studentId={student ? student.id : ""}
                        insuranceId={insuranceId}
                        refreshStudentSidebar={refreshStudentSidebar}
                        setInsuranceId={setInsuranceId}
                        setTabKey={setTabKey}
                      />
                    )}
                    {tabKey === "3" && (
                      <StudentOpenCalls
                        studentId={student ? student.id : undefined}
                      />
                    )}
                    {tabKey === "4" && (
                      <StudentCallHistory
                        studentId={student ? student.id : undefined}
                      />
                    )}
                    {tabKey === "5" && (
                      <VendorInteractionList
                        studentId={student ? student.id : undefined}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible when={anyAuth(hasAuthRole("Administrator"))}>
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};
