import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { message } from "antd";

import moment from "moment-timezone";

import { StudentFormSkeleton } from "../StudentFormSkeleton";

import {
  STUDENT_CALLS,
  StudentCalls as StudentCallsData,
  StudentCalls_studentCalls as CallRecord,
  StudentCalls_studentCalls_callNotes as CallNoteRecord,
  StudentCalls_studentCalls_callFollowups as FollowupRecord,
  StudentCalls_studentCalls_callFollowups_followupNotes as FollowupNoteRecord,
  StudentCallsVariables,
} from "../../../../lib/graphql/queries";
import { IdSorter } from "../../../../lib/utils";

const dateFormat = "MM/DD/YYYY hh:mm A";

interface Props {
  studentId: string | undefined;
}

interface CallsProps {
  calls: CallRecord[] | undefined;
}

interface CallNotesProps {
  callNotes: CallNoteRecord[] | undefined;
}

interface FollowupsProps {
  followups: FollowupRecord[] | undefined;
}

interface FollowupNotesProps {
  followupNotes: FollowupNoteRecord[] | undefined;
}

interface FullNameFields {
  firstName: String | null | undefined;
  middleName: String | null | undefined;
  lastName: String | null | undefined;
}

moment.tz.setDefault("America/New_York");

export const StudentCallHistory = ({ studentId }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [studentCalls, setStudentCalls] = useState<CallRecord[] | undefined>(
    undefined
  );

  const sortForCallHistory = (calls: CallRecord[]) => {
    calls.sort(IdSorter);

    for (let i = 0; i < calls.length; i++) {
      let call = calls[i];
      call.callNotes.sort(IdSorter);
      call.callFollowups.sort(IdSorter);

      for (let j = 0; j < call.callFollowups.length; j++) {
        let followup = call.callFollowups[j];
        followup.followupNotes.sort(IdSorter);
      }
    }
  };

  const { loading, error } = useQuery<StudentCallsData, StudentCallsVariables>(
    STUDENT_CALLS,
    {
      variables: {
        studentId: studentId ?? "0",
        openOnly: false,
      },
      fetchPolicy: "no-cache",
      skip: studentId === undefined,
      onCompleted: (data) => {
        if (data) {
          const studentCallRecords = data.studentCalls
            ? data.studentCalls
            : null;
          if (studentCallRecords) {
            sortForCallHistory(studentCallRecords);
            setStudentCalls(studentCallRecords);
          }
        }
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  if (loading) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={"Student Call History"} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={"Student Call History"} error />
      </div>
    );
  }

  const fullName = (fields: FullNameFields) => {
    return (
      (fields && fields.firstName ? fields.firstName : "") +
      (fields && fields.middleName ? " " + fields.middleName : "") +
      (fields && fields.lastName ? " " + fields.lastName : "")
    );
  };

  const CallItems = ({ calls }: CallsProps) => {
    return (
      <div>
        {studentCalls &&
          studentCalls.map((call) => {
            return (
              <div key={"call-" + call.id}>
                <div className="d-flex flex-nowrap align-items-start pt-4">
                  <div className="air__utils__avatar air__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
                    <img
                      src="/resources/images/icons/call.jpg"
                      alt="Student Call"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <div className="d-flex flex-wrap mb-2">
                        <div className="mr-auto">
                          <div className="text-gray-6">
                            <span className="text-dark font-weight-bold">
                              <i className="icmn-radio-unchecked mr-1 text-blue" />
                              {moment(call.caseOpenedAt).format(dateFormat)}
                            </span>
                            <br />
                            <span className="text-dark font-weight-bold">
                              {call.callType.name}
                            </span>
                          </div>
                          <div>{fullName(call.navigatorUser)}</div>
                        </div>
                      </div>

                      <CallNoteItems callNotes={call.callNotes} />
                      <div className="d-flex flex-wrap justify-content-start align-items-start mb-3 border-bottom">
                        &nbsp;
                      </div>
                      <FollowupItems followups={call.callFollowups} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const CallNoteItems = ({ callNotes }: CallNotesProps) => {
    return (
      <div>
        {callNotes &&
          callNotes.map((callNote) => {
            return (
              <div className="mb-3" key={"callNote-" + callNote.id}>
                <div>
                  <u>
                    <i>
                      {callNote.noteType.name} added by{" "}
                      {fullName(callNote.updatedByUser)} on{" "}
                      {moment(callNote.updatedAt).format(dateFormat)}
                    </i>
                  </u>
                </div>
                {callNote.note}
              </div>
            );
          })}
      </div>
    );
  };

  const FollowupItems = ({ followups }: FollowupsProps) => {
    return (
      <div>
        {followups &&
          followups.map((followup) => {
            return (
              <div
                className="d-flex flex-nowrap align-items-start pt-4"
                key={"followup-" + followup.id}
              >
                <div className="air__utils__avatar air__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
                  <img
                    src="/resources/images/icons/follow-up.jpg"
                    alt="Follow-up Required"
                  />
                </div>
                <div className="flex-grow-1">
                  <div>
                    <div className="d-flex flex-wrap mb-2">
                      <div className="mr-auto">
                        <div className="text-gray-6">
                          <span className="text-dark font-weight-bold">
                            <i className="icmn-radio-unchecked mr-1 text-blue" />
                            {moment(followup.createdAt).format(dateFormat)}
                          </span>
                          <br />
                          <span className="text-dark font-weight-bold">
                            {followup.followupType.name}
                          </span>
                        </div>
                        <div>{fullName(followup.createdByUser)}</div>
                      </div>
                    </div>

                    <FollowupNoteItems followupNotes={followup.followupNotes} />

                    <div className="d-flex flex-wrap justify-content-start align-items-start mb-3 border-bottom">
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const FollowupNoteItems = ({ followupNotes }: FollowupNotesProps) => {
    return (
      <div>
        {followupNotes &&
          followupNotes.map((followupNote) => {
            return (
              <div className="mb-3" key={"followupNote-" + followupNote.id}>
                <div>
                  <u>
                    <i>
                      {followupNote.noteType.name} added by{" "}
                      {fullName(followupNote.updatedByUser)} on{" "}
                      {moment(followupNote.updatedAt).format(dateFormat)}
                    </i>
                  </u>
                </div>
                {followupNote.note}
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <h5 className="text-black mt-0">
        <strong>Call History</strong>
      </h5>

      <CallItems calls={studentCalls} />
    </div>
  );
};
