import { gql } from "apollo-boost";
 
export const FOLLOWUP_RESOLUTION_TYPES = gql`
  query FollowupResolutionTypes($includeDeleted: Boolean) {
    followupResolutionTypes(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
