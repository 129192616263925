import React from "react";
import { Layout, Table, Button } from "antd";
import { Helmet } from "react-helmet";
import { StringSorter, DateSorter, DefaultSorter } from "../../../lib/utils";
import moment from "moment-timezone";
import { useQuery } from "@apollo/react-hooks";
import {
  REPORT_RESULTS,
  ReportResults as ReportResultsData,
} from "../../../lib/graphql/queries";
import { useHistory } from "react-router-dom";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../lib/utils";

const { Content } = Layout;

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY hh:mm a z";

export const ReportingHistory = () => {
  const { data } = useQuery<ReportResultsData>(REPORT_RESULTS, {
    variables: {
      includeDeleted: false,
    },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log(`Report Results: ${JSON.stringify(data, null, 2)}`);
    },
    onError: (data) => {
      console.log(`Report Results: ${JSON.stringify(data, null, 2)}`);
    },
  });
  const reportResults = data ? data.reportResults : undefined;
  const editUrl = `/reporting/view-report`;
  const history = useHistory();

  const handleViewReport = async (id: string, viewerRoute: string | null) => {
    console.log("viewerRoute: ", viewerRoute);
    let url = viewerRoute ? viewerRoute : editUrl;
    history.push(`${url}/${id}/history`);
  };

  const columns: any = [
    {
      title: "Created By",
      dataIndex: "runByUser.lastName",
      key: "runByUser.lastName",
      render: (text: any, row: any) => (
        <span>
          {row.runByUser.firstName}&nbsp;
          {row.runByUser.lastName}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA = a.runByUser.firstName + " " + a.runByUser.lastName;
        let strB = b.runByUser.firstName + " " + b.runByUser.lastName;

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Report Title",
      dataIndex: "title",
      key: "title",
      sorter: (a: any, b: any) => StringSorter(a.title, b.title),
    },
    {
      title: "Filter Criteria",
      dataIndex: "paramsDescription",
      key: "paramsDescription",
      sorter: (a: any, b: any) =>
        StringSorter(a.paramsDescription, b.paramsDescription),
    },
    {
      title: "Record Count",
      dataIndex: "resultCount",
      key: "resultCount",
      sorter: (a: any, b: any) => DefaultSorter(a.resultCount, b.resultCount),
    },
    {
      title: "Created On",
      dataIndex: "runAt",
      key: "runAt",
      align: "center",
      render: (text: any) => (
        <span>
          {text ? moment(new Date(text)).format(dateFormat) : "-- never --"}
        </span>
      ),
      sorter: (a: any, b: any) =>
        DateSorter(a.runAt ? a.runAt : null, b.runAt ? b.runAt : null),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any) => (
        <Button
          style={{ marginRight: "5px" }}
          onClick={() => handleViewReport(text, row.viewerRoute)}
          size="small"
          shape="round"
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Navigator"),
          hasAuthRole("AccountManager"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Helmet title={`Reporting History`} />
            <div className="air__utils__heading">
              <h5>Reporting History</h5>
            </div>

            <div className="card">
              <div className="card-header card-header-flex">
                <div className="d-flex flex-column justify-content-center mr-auto">
                  <h4 className="mb-0">
                    <strong>Reports List</strong>
                  </h4>
                </div>
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ marginTop: "25px" }}
                >
                  &nbsp;
                </div>
              </div>
              <div className="card-body">
                <div className="air__utils__scrollTable">
                  <Table
                    rowKey="id"
                    columns={columns}
                    dataSource={reportResults}
                  />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible when={anyAuth(hasAuthRole("Administrator"))}>
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};
