import React from "react";
import { Modal, Table } from "antd";
import moment from "moment-timezone";

import { StudentVendorInteractions_studentVendorInteractions as StudentVendorInteractionsRecord } from "../../../../lib/graphql/queries";

interface Props {
  interaction: StudentVendorInteractionsRecord | null;
  visible: boolean;
  setVisible: (input: any) => void;
}

moment.tz.setDefault("America/New_York");

export const ViewVendorInteractionModal = ({
  interaction,
  visible,
  setVisible,
}: Props) => {
  const displayFields = [
    "caseIdentifier",
    "referredBy",
    "categoryName",
    "subCategoryName",
    "studentID",
    "conditionName",
    "caseDescription",
    "closed",
    "casePriority",
    "caller",
    "dateOpened",
    "dateClosed",
    "source",
    "reasonName",
    "organizationName",
    "studentName",
    "callerName",
    "memberDateofBirth",
    "eAP_Priority",
    "risk",
    "treatment",
    "attendees",
    "presentingProblem",
    "isMemberWorried",
    "worriedDetails",
    "isMemberHarmful",
    "isMemberHarmfultoSelf",
    "isMemberHarmfultoOthers",
    "harmfulDetails",
    "isMemberConcerned",
    "isConcernedaboutDrugs",
    "isConcernedaboutAlcohol",
    "concernDetails",
    "clinicalImpressions",
    "sessionType",
    "sessionDate",
    "sessionTime",
    "method",
    "duration",
    "counselor",
    "status",
    "locationState",
    "locationPhone",
    "locationCity",
    "affiliateName",
    "lastChangeAt",
    "studentPhone",
    "studentAddress1",
    "studentAddress2",
    "city",
    "state",
    "zipCode",
    "studentEmail",
  ];

  const displayLabels: any = {
    caseIdentifier: { title: "CaseID" },
    referredBy: { title: "Referred By" },
    categoryName: { title: "Category" },
    subCategoryName: { title: "Sub-category" },
    studentID: { title: "Student ID" },
    conditionName: { title: "Condition" },
    caseDescription: { title: "Description" },
    closed: { title: "Status" },
    casePriority: { title: "Case Priority " },
    caller: { title: "Caller" },
    dateOpened: { title: "Date Opened" },
    dateClosed: { title: "Date Closed" },
    source: { title: "Case Source" },
    reasonName: { title: "Reason Case Closed" },
    organizationName: { title: "University Name" },
    studentName: { title: "Student Name" },
    callerName: { title: "Caller Name" },
    memberDateofBirth: { title: "Student Date of Birth" },
    eAP_Priority: { title: "EAP Priority" },
    risk: { title: "Risk Code" },
    treatment: { title: "Treatment" },
    attendees: { title: "Attendees" },
    presentingProblem: { title: "Presenting Problem" },
    isMemberWorried: { title: "Is Member Worried" },
    worriedDetails: { title: "Worried Details" },
    isMemberHarmful: { title: "Is Member Harmful" },
    isMemberHarmfultoSelf: { title: "Is Harmful To Self" },
    isMemberHarmfultoOthers: { title: "Is Harmful To Others" },
    harmfulDetails: { title: "Harmful Details" },
    isMemberConcerned: { title: "Is Member Concerned" },
    isConcernedaboutDrugs: { title: "Is Concerned About Drugs" },
    isConcernedaboutAlcohol: { title: "Is Concerned About Alcohol" },
    concernDetails: { title: "Concern Details" },
    clinicalImpressions: { title: "Clinical Impressions" },
    sessionType: { title: "Session Type" },
    sessionDate: { title: "Session Date" },
    sessionTime: { title: "Session Time" },
    method: { title: "Method" },
    duration: { title: "Duration " },
    counselor: { title: "Counselor" },
    status: { title: "Status " },
    locationState: { title: "Location State" },
    locationPhone: { title: "Location Phone" },
    locationCity: { title: "Location City" },
    affiliateName: { title: "Affiliate Name" },
    lastChangeAt: { title: "Last Change" },
    studentPhone: { title: "Student Phone" },
    studentAddress1: { title: "Student Address 1" },
    studentAddress2: { title: "Student Address 2" },
    city: { title: "City" },
    state: { title: "State" },
    zipCode: { title: "Zip Code" },
    studentEmail: { title: "Student Email" },
  };

  // TODO - Remove the following helper code and replace with use of vendorFeedMap record
  const displayUnmappedFields = [
    // "University Name",
    "CONSENT",
    "EAP Member ID#",
    // "Service Request ID#",
    "Request Date",
    "Information Source",
    // "Presenting Problem - Main",
    // "Student ID",
    // "Presenting Problem - Subcategory",
    // "Presenting Problem Notes",
    "Acuity",
    // "Date Opened",
    // "Date Closed",
    "Contact Method",
    // "Resolved Status",
    // "Student Name",
    // "Caller Name",
    // "Student Date of Birth",
    // "Gender",
    "Service/Referral - EAP Network Counselor - Face to Face",
    "Service/Referral - EAP Network Counselor - Phone",
    "Service/Referral - EAP Network Counselor - LHO",
    "Service/Referral - Staff Telephonic Counseling",
    "Service/Referral - Worklife - Legal Services",
    "Service/Referral - Worklife - Financial Services",
    "Service/Referral - Worklife - Child Care Resources",
    "Service/Referral - Worklife - Adult Care Resources",
    "Service/Referral - Worklife - Other",
    "Service/Referral - MyStrength",
    "Service/Referral - Community Resources",
    "Service/Referral - Service Inquiry Only",
    "Service/Referral - EAP Website / Member Center",
    "Service/Referral - Wellness Service/Program",
    "Service/Referral - BH General",
    "Service/Referral - Medical General",
    "Primary Screen - EAP request related to alcohol/drugs",
    "Primary Screen - Thoughts of harming self or others",
    "Primary Screen - Child/elder abuse or domestic violence part of problem",
    "Red Flag - Crying",
    "Red Flag - Speech: slurred, rapid or incoherent",
    "Red Flag - Anger: yelling, screaming, threatening, demanding",
    "Red Flag - Thoughts: very hesitant or trouble expressing",
    "Red Flag - Resistance: refuse to give information, suspicious, paranoia",
    // "Harmful To Self",
    // "Harmful To Others",
    "Danger - Safety Plan",
    "Danger - Symptoms",
    // "Concerned About Drugs",
    // "Concerned About Alcohol",
    "Note 1",
    "Note 2",
    "Note 3",
    "Note 4",
    "Note 5",
    "Note 6",
    "Note 7",
    "Note 8",
    "Note 9",
    "Note 10",
    "Note 11",
    "Note 12",
    "Note 13",
    "Note 14",
    "Session Date 1",
    "Session Date 2",
    "Session Date 3",
    "Session Date 4",
    "Session Date 5",
    "Session Date 6",
    "Session Date 7",
    "Session Date 8",
    "Session Date 9",
    "Session Date 10",
    "Counselor Name",
    "First Appt Date Scheduled",
    "Record Status",
    "Update Date",
    "Group Type",
    "Cell Phone",
    "Home Phone",
    "Work Phone",
    "Extension",
    "Email",
  ];

  let sourceData: any = interaction;
  let tableData: any = [];

  if (sourceData) {
    for (let i = 0; i < displayFields.length; i++) {
      let value = sourceData[displayFields[i]];
      let label = displayLabels[displayFields[i]].title;

      tableData.push({ label, value });
    }

    if(sourceData.unmapped){
      for (let i = 0; i < displayUnmappedFields.length; i++) {
        let label = displayUnmappedFields[i];
        let value = sourceData.unmapped[label];
  
        tableData.push({ label, value });
      }
    }
  }

  const tableColumns: any = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      width: "200px",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      title={"24/7 Support Line Call"}
      cancelText={"Close"}
      onCancel={handleClose}
      okButtonProps={{ style: { display: 'none' } }}
      width={800}
    >
      <Table
        bordered={true}
        columns={tableColumns}
        dataSource={tableData}
        pagination={false}
        rowKey="label"
        scroll={{y:400}}
        size="small"
      />
    </Modal>
  );
};
