import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Button, Card, Table } from "antd";
import moment from "moment-timezone";

import {
  StringSorter,
  DateOnlySorter,
  IdSorterDesc,
} from "../../../../lib/utils";
import { VendorInteractionListSkeleton } from "../VendorInteractionListSkeleton";
import { ViewVendorInteractionModal } from "../ViewVendorInteraction";
import {
  STUDENT_VENDOR_INTERACTIONS,
  StudentVendorInteractions as StudentVendorInteractionsData,
  StudentVendorInteractions_studentVendorInteractions as StudentVendorInteractionsRecord,
  StudentVendorInteractionsVariables,
} from "../../../../lib/graphql/queries";

interface Props {
  studentId: string | undefined;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

export const VendorInteractionList = ({ studentId }: Props) => {
  const title = "24/7 Support Line Calls";
  let [visible, setVisible] = useState(false);
  let [
    viewInteraction,
    setViewInteraction,
  ] = useState<StudentVendorInteractionsRecord | null>(null);
  const { data, loading, error } = useQuery<
    StudentVendorInteractionsData,
    StudentVendorInteractionsVariables
  >(STUDENT_VENDOR_INTERACTIONS, {
    variables: {
      studentId,
    },
    fetchPolicy: "no-cache",
    skip: studentId === "0",
  });
  const interactions = data ? data.studentVendorInteractions : [];
  interactions.sort(IdSorterDesc);

  const handleViewInteraction = (id: string) => {
    const interaction = interactions.find((entry) => entry.id === id);
    if (interaction) {
      setViewInteraction(interaction);
      setVisible(true);
    }
  };

  const interactionColumns: any = [
    {
      title: "Case ID",
      dataIndex: "caseIdentifier",
      key: "caseIdentifier",
      sorter: (a: any, b: any) =>
        StringSorter(a.caseIdentifier, b.caseIdentifier),
    },
    {
      title: "Date Opened",
      dataIndex: "dateOpened",
      key: "dateOpened",
      align: "center",
      render: (text: any, row: any) => (
        <span>
          {text
            ? moment(new Date(text)).format(dateFormat)
            : "-- not defined --"}
        </span>
      ),
      sorter: (a: any, b: any) => DateOnlySorter(a.dateOpened, b.dateOpened),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: (a: any, b: any) => StringSorter(a.category, b.category),
    },
    {
      title: "Sub-Category",
      dataIndex: "subCategory",
      key: "subCategory",
      sorter: (a: any, b: any) => StringSorter(a.subCategory, b.subCategory),
    },
    {
      title: "Condition",
      dataIndex: "condition",
      key: "condition",
      sorter: (a: any, b: any) => StringSorter(a.condition, b.condition),
    },
    {
      title: "Case Resolution",
      dataIndex: "caseResolution",
      key: "caseResolution",
      sorter: (a: any, b: any) =>
        StringSorter(a.caseResolution, b.caseResolution),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any) => (
        <span>
          <Button
            onClick={() => handleViewInteraction(text)}
            size="small"
            shape="round"
          >
            View
          </Button>
        </span>
      ),
    },
  ];

  if (loading) {
    return (
      <div className="admin">
        <VendorInteractionListSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <VendorInteractionListSkeleton title={title} error />
      </div>
    );
  }

  return (
    <Card size="small" title={title}>
      <Table
        rowKey="id"
        columns={interactionColumns}
        dataSource={interactions}
        size="middle"
      />

      <ViewVendorInteractionModal
        interaction={viewInteraction}
        visible={visible}
        setVisible={setVisible}
      />
    </Card>
  );
};
