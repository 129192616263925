import { gql } from "apollo-boost";

export const VENDOR = gql`
  query Vendor($id: String!) {
    vendor(id: $id) {
      id
      name
      shortName
      avatar
      contactId
      contact{
        id
        firstName
        middleName
        lastName
        namePrefix
        nameSuffix
        email
        altEmail
        primaryPhone
        canSmsPrimaryPhone
        altPhone
        canSmsAltPhone
        addressTypeId
        countryId
        addressLine1
        addressLine2
        city
        stateId
        province
        zipCode
        freeFormAddress    
      }
      coverageStartDate
      coverageTerminationDate
      isDeleted
    }
  }
`;

// TODO: Get/Insert/Update/Delete Contact record
