import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useQuery } from "@apollo/react-hooks";
import {
  AUTH_PASSWORD_EXPIRED,
  AuthPasswordExpired as AuthPasswordExpiredData
} from "../../lib/graphql/queries";
import { OpenFollowups, StudentLookup } from "./components";
import { ChangePasswordModalForm } from "../admin/system/UserForm/components";
import { AuthVisible, hasAuthRole, anyAuth } from "../../lib/utils";

interface Props {
  title: string;
}

export const Home = ({ title }: Props) => {
  const expiryWarning = 5;
  let modalTitle = "Password Expiring - Please Update";
  let userDataJson = sessionStorage.getItem("user");
  let userData = userDataJson ? JSON.parse(userDataJson) : null;

  let [passwordExpired, setPasswordExpired] = useState<Boolean>();
  let [hideCancel, setHideCancel] = useState<boolean>();

  useQuery<AuthPasswordExpiredData>(
    AUTH_PASSWORD_EXPIRED,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        // console.log(`Password expiration: ${JSON.stringify(data, null, 2)}`);

        if (
          -data.authPasswordExpired < expiryWarning &&
          data.authPasswordExpired < 0
        ) {
          setPasswordExpired(true);
        } else if (data.authPasswordExpired >= 0) {
          setPasswordExpired(true);
          setHideCancel(true);
        } else {
          setPasswordExpired(false);
        }
      },
      onError: (data) => {
        console.log(`Password token error: ${JSON.stringify(data, null, 2)}`);
      },
    }
  );

  const closable = false;
  const [visible, setVisible] = useState(true);

  // Don't render if no userData.
  if (!userData) return <div></div>;

  let userId = userData.id;

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Navigator"),
          hasAuthRole("AccountManager"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <div>
          <Helmet title="Navigator" />
          <div className="air__utils__heading">
            <h5>Navigator Dashboard</h5>
          </div>

          <StudentLookup title="Student Lookup" />

          <OpenFollowups title="Open Followups" />

          {passwordExpired && (
            <ChangePasswordModalForm
              userId={userId}
              visible={visible}
              setVisible={setVisible}
              maskClosable={closable}
              closable={closable}
              hideCancel={hideCancel}
              title={modalTitle}
            />
          )}
        </div>
      </AuthVisible>

      <AuthVisible when={anyAuth(hasAuthRole("Administrator"))}>
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};
