import { gql } from "apollo-boost";

export const REPORT_RESULT = gql`
  query ReportResult($id: String!) {
    reportResult(id: $id) {
      id
      reportProfileId
      runByUser {
        firstName
        lastName
      }
      runAt
      title
      heading
      description
      params
      paramsDescription
      viewerRoute
      resultColumns
      resultCount
      resultData
      pdfFormatter
    }
  }
`;
