export * from "./CloseCallFollowup";
export * from "./CreateCall";
export * from "./CreateCallFollowup";
export * from "./CreateCallNote";
export * from "./CreateInsurance";
export * from "./CreateReport";
export * from "./CreateStudent";
export * from "./MergeStudents";
export * from "./CreateUniversity";
export * from "./CreateUniversityCampus";
export * from "./CreateVendor";
export * from "./DeleteInsurance";
export * from "./DeleteUniversity";
export * from "./DeleteUniversityCampus";
export * from "./UndeleteUniversity";
export * from "./UndeleteUniversityCampus";
export * from "./DeleteVendor";
export * from "./UndeleteVendor";
export * from "./UpdateCall";
export * from "./UpdateCallFollowup";
export * from "./UpdateCallNote";
export * from "./UpdateInsurance";
export * from "./UpdateStudent";
export * from "./UpdateUniversity";
export * from "./UpdateUniversityCampus";
export * from "./UpdateVendor";
