import { ApolloError } from "apollo-boost";
import React from "react";
import { useHistory, Link } from "react-router-dom";
import { message, Button, Form, Input, Spin } from "antd";
import { Helmet } from "react-helmet";

import { CodeFormSkeleton } from "../CodeFormSkeleton";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../../lib/utils";

interface Props {
  form: WrappedFormUtils<any>;
  title: string;
  codeType: string;
  codeTypePlural: string;
  isNew: boolean;
  codeMaxLength: number;
  hasDescription: boolean;
  queryLoading: boolean;
  queryError: ApolloError | undefined;
  createRunning: boolean;
  updateRunning: boolean;
  createCode: (input: any) => void;
  updateCode: (input: any) => void;
}

export const CodeForm = ({
  form,
  title,
  codeType,
  codeTypePlural,
  codeMaxLength,
  isNew,
  hasDescription,
  queryLoading,
  queryError,
  createCode,
  createRunning,
  updateCode,
  updateRunning,
}: Props) => {
  const history = useHistory();

  //  useScrollToTop();

  const dashUrl = `/admin/codes`;
  const listUrl = `/admin/codes/${codeTypePlural.toLowerCase()}`;

  if (queryLoading) {
    return (
      <div className="admin">
        <CodeFormSkeleton title={title} />
      </div>
    );
  }

  if (queryError) {
    return (
      <div className="admin">
        <CodeFormSkeleton title={title} error />
      </div>
    );
  }

  const gotoList = () => {
    history.push(listUrl);
  };

  const handleCancel = (e: any) => {
    message.info("No changes saved.");
    gotoList();
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      const input = {
        ...values,
      };

      if (input.id) {
        delete input.id;
        await updateCode({
          variables: {
            id: values.id,
            data: input,
          },
        });
      } else {
        delete input.id;
        input.isDefault = input.isDefault ? input.isDefault : null;
        await createCode({
          variables: {
            data: input,
          },
        });
      }

      gotoList();
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <div className="admin">
          <Spin spinning={createRunning || updateRunning}>
            <div>
              <Helmet
                title={`Manage Codes: ${codeTypePlural} : ${
                  isNew ? "<new>" : form.getFieldValue("name")
                }`}
              />
              <div className="air__utils__heading">
                <h5>
                  <Link to={dashUrl}>Manage Codes</Link>&nbsp;:&nbsp;
                  <Link to={listUrl}>{codeTypePlural}</Link>&nbsp;:&nbsp;
                  {isNew ? "<new>" : form.getFieldValue("name")}
                </h5>
              </div>

              <div className="card">
                <div className="card-body">
                  <h4 className="mb-4">
                    <strong>{codeType} Form</strong>
                  </h4>
                  <Form {...formItemLayout} labelAlign="left">
                    <div className="row">
                      <div className="col-md-8">
                        <Form.Item label="Code">
                          {form.getFieldDecorator("code", {
                            rules: [{ required: true }],
                          })(<Input placeholder="Code" />)}
                        </Form.Item>
                      </div>
                      <div className="col-md-4">&nbsp;</div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <Form.Item label="Name">
                          {form.getFieldDecorator("name", {
                            rules: [{ required: true }],
                          })(<Input placeholder="Name" />)}
                        </Form.Item>
                      </div>
                      <div className="col-md-4">&nbsp;</div>
                    </div>
                    {hasDescription && (
                      <div className="row">
                        <div className="col-md-8">
                          <Form.Item label="Description">
                            {form.getFieldDecorator("description")(
                              <Input placeholder="Description" />
                            )}
                          </Form.Item>
                        </div>
                        <div className="col-md-4">&nbsp;</div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-2">&nbsp;</div>
                      <div className="col-md-6">
                        {/*
                        // TODO: Implement the default values when functionality fully defined
                        <Form.Item>
                          {form.getFieldDecorator("isDefault", {
                            valuePropName: "checked",
                          })(
                            <Checkbox onChange={handleCheckIsDefault}>
                              Is default record
                            </Checkbox>
                          )}
                        </Form.Item>
                        */}
                      </div>
                      <div className="col-md-4">
                        {!hasDescription && (
                          <Form.Item>
                            {form.getFieldDecorator("description")(
                              <Input type="hidden" />
                            )}
                          </Form.Item>
                        )}
                        <Form.Item>
                          {form.getFieldDecorator("id")(
                            <Input type="hidden" />
                          )}
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-actions col-md-12">
                        <Button
                          style={{ width: 140 }}
                          type="primary"
                          onClick={handleSave}
                          className="mr-3"
                        >
                          {isNew ? "Create" : "Update"}
                        </Button>

                        <Button onClick={handleCancel}>Cancel</Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(hasAuthRole("Navigator"), hasAuthRole("AccountManager"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};
