import { WrappedFormUtils } from "antd/lib/form/Form";

export const SetCodeFormValues = (form: WrappedFormUtils<any>, record: any) => {
  if (record) {
    form.setFieldsValue({
      id: record.id,
      code: record.code,
      name: record.name,
      description: record.description,
      isDefault: record.isDefault,
    });
  }
};
