import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Icon, Layout } from "antd";

import { AuthVisible, hasAuthRole, anyAuth } from "../../../../lib/utils";

import style from "./style.module.scss";

const { Content } = Layout;

export const CodesDashboard = () => {
  const generateCodeColumns = (columnNum: number) => {
    const codeData = [
      {
        icon: "bars",
        key: "addressTypes",
        summary: "Codes for address formats",
        title: "Address Types",
        url: "/admin/codes/addressTypes",
      },
      {
        icon: "bars",
        key: "callResolutionTypes",
        summary: "Codes for call resolutions",
        title: "Call Resolutions",
        url: "/admin/codes/callResolutions",
      },
      {
        icon: "bars",
        key: "callStatuses",
        summary: "Codes for call statuses",
        title: "Call Statuses",
        url: "/admin/codes/callStatuses",
      },
      {
        icon: "bars",
        key: "callTypes",
        summary: "Codes for the types of calls",
        title: "Call Types",
        url: "/admin/codes/callTypes",
      },
      {
        icon: "bars",
        key: "contactCategory",
        summary: "Codes for contact categories",
        title: "Contact Categories",
        url: "/admin/codes/contactCategories",
      },
      {
        icon: "bars",
        key: "contactMethod",
        summary: "Codes for the methods of contact",
        title: "Contact Methods",
        url: "/admin/codes/contactMethods",
      },
      {
        icon: "bars",
        key: "contactTypes",
        summary: "Codes for the types of contacts",
        title: "Contact Types",
        url: "/admin/codes/contactTypes",
      },
      {
        icon: "bars",
        key: "countries",
        summary: "Codes for countries",
        title: "Countries",
        url: "/admin/codes/countries",
      },
      {
        icon: "bars",
        key: "followupResolutionTypes",
        summary: "Codes for followup resolutions",
        title: "Followup Resolution Types",
        url: "/admin/codes/followupResolutionTypes",
      },
      {
        icon: "bars",
        key: "followupStatuses",
        summary: "Codes for followup statuses",
        title: "Followup Statuses",
        url: "/admin/codes/followupStatuses",
      },
      {
        icon: "bars",
        key: "followupTypes",
        summary: "Codes for the types of followups",
        title: "Followup Types",
        url: "/admin/codes/followupTypes",
      },
      {
        icon: "bars",
        key: "genders",
        summary: "Codes for genders",
        title: "Genders",
        url: "/admin/codes/genders",
      },
      {
        icon: "bars",
        key: "insuranceTypes",
        summary: "Codes for the types of insurances",
        title: "Insurance Types",
        url: "/admin/codes/insuranceTypes",
      },
      {
        icon: "bars",
        key: "languages",
        summary: "Codes for languages",
        title: "Languages",
        url: "/admin/codes/languages",
      },
      {
        icon: "bars",
        key: "noteTypes",
        summary: "Codes for the types of notes",
        title: "Note Types",
        url: "/admin/codes/noteTypes",
      },
      {
        icon: "bars",
        key: "roles",
        summary: "Codes for user roles",
        title: "Roles",
        url: "/admin/codes/roles",
      },
      {
        icon: "bars",
        key: "states",
        summary: "Codes for US states",
        title: "States",
        url: "/admin/codes/states",
      },
      {
        icon: "bars",
        key: "studentTypes",
        summary: "Codes for the types of students",
        title: "Student Types",
        url: "/admin/codes/studentTypes",
      },
      {
        icon: "bars",
        key: "userStatuses",
        summary: "Codes for user statuses",
        title: "User Statuses",
        url: "/admin/codes/userStatuses",
      },
    ];

    const columnData = codeData
      .slice(columnNum - 1)
      .filter(function filterColumns(value, index) {
        return value !== null && index % 3 === 0;
      });

    return columnData.map((item) => {
      const { icon, key, summary, title, url } = item;
      return (
        <li key={key} className={style.item}>
          <Link to={`${url}`} className={style.itemLink}>
            <div className={style.itemPic}>
              <Icon type={`${icon}`} />
            </div>
            <div className="mr-2">
              <div>{title}</div>
              <div className="text-muted">{summary}</div>
            </div>
            <div className={style.itemAction}>
              <span />
            </div>
          </Link>
        </li>
      );
    });
  };

  const column1 = generateCodeColumns(1);
  const column2 = generateCodeColumns(2);
  const column3 = generateCodeColumns(3);

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Helmet title="Codes" />
            <div className="air__utils__heading">
              <h5>Codes Dashboard</h5>
            </div>
            <div className="card">
              <div className="card-header card-header-flex">
                <div className="d-flex flex-column justify-content-center mr-auto">
                  <h5 className="mb-0">Dashboard of System Codes</h5>
                </div>
              </div>

              <div className="card-body">
                <div className="air__utils__scrollTable">
                  <div className="row">
                    <div className="col-lg-4">
                      <div>
                        <ul className="list-unstyled">{column1}</ul>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div>
                        <ul className="list-unstyled">{column2}</ul>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div>
                        <ul className="list-unstyled">{column3}</ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(hasAuthRole("Navigator"), hasAuthRole("AccountManager"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};
