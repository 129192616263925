import { gql } from "apollo-boost";
 
export const INSURANCE_TYPE = gql`
  query InsuranceType($id: String!) {
    insuranceType(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
