import { gql } from "apollo-boost";

export const REPORT_GROUP = gql`
  query ReportGroup($id: String!) {
    reportGroup(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
