import { gql } from "apollo-boost";

export const CALL_FOLLOWUPS_FROM_IDS = gql`
  query CallFollowupsFromIds($idCsv: String!) {
    callFollowupsFromIds(idCsv: $idCsv) {
      id
      callId
      call{
        callType{
          name
        }
        studentId
        student{
          contact{
            firstName
            middleName
            lastName
          }
          university{
            shortName
          }
          universityCampus{
            name
          }
        }
        navigatorUserId
        navigatorUser{
          firstName
          middleName
          lastName
        }
      }
      followupNumber
      followupStatus{
        name
      }
      followupType{
        name
      }
      dateDue
    }
  }
`;
