import { gql } from "apollo-boost";
 
export const CALL_RESOLUTIONS = gql`
  query CallResolutions($includeDeleted: Boolean) {
    callResolutions(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
