import { gql } from "apollo-boost";

export const CALL = gql`
  query Call($id: String!) {
    call(id: $id) {
      id
      studentId
      contactMethodId
      contactMethod {
        code
        name
      }
      callTypeId
      callType {
        code
        name
      }
      callStatusId
      callStatus {
        code
        name
      }
      navigatorUserId
      navigatorUser {
        firstName
        middleName
        lastName
      }
      callResolutionId
      callResolution {
        code
        name
      }
      caseOpenedAt
      caseClosedAt
      caseLastActivityAt
      callNotes {
        id
        callId
        callFollowupId
        noteTypeId
        noteType {
          code
          name
        }
        updatedAt
        updatedByUserId
        updatedByUser {
          firstName
          middleName
          lastName
        }
        note
      }
      callFollowups {
        id
        callId
        followupStatusId
        followupStatus {
          code
          name
        }
        followupTypeId
        followupType {
          code
          name
        }
        followupResolutionTypeId
        followupResolutionType {
          code
          name
        }
        followupNotes {
          id
          callId
          callFollowupId
            noteTypeId
          noteType {
            code
            name
          }
          updatedAt
          updatedByUserId
          updatedByUser {
            firstName
            middleName
            lastName
          }
          note
        }
        createdAt
        createdByUserId
        createdByUser {
          firstName
          middleName
          lastName
        }
      }
      updatedAt
      updatedByUserId
      updatedByUser {
        firstName
        middleName
        lastName
      }
    }
  }
`;
