import { gql } from "apollo-boost";
 
export const LANGUAGE = gql`
  query Language($id: String!) {
    language(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
