import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { CodesSkeleton, CodeListManager } from "../../components";

import {
  USER_STATUSES,
  UserStatuses as UserStatusesData,
} from "../../../../lib/graphql/queries";
import {
  DELETE_USER_STATUS,
  DeleteUserStatus as DeleteUserStatusData,
  DeleteUserStatusVariables,
  UNDELETE_USER_STATUS,
  UndeleteUserStatus as UndeleteUserStatusData,
  UndeleteUserStatusVariables,
} from "../../../../lib/graphql/mutations/codes";

import "../../styles/admin.css";

interface Props {
  title: string;
}

export const UserStatusList = ({ title }: Props) => {
  const codeType = "UserStatus";
  const codeTypePlural = "UserStatuses";
  const canAddCodes = true;

  const { data, loading, error, refetch } = useQuery<UserStatusesData>(
    USER_STATUSES,
    {
      variables: {
        includeDeleted: true,
      },
      fetchPolicy: "no-cache",
    }
  );
  const codes = data ? data.userStatuses : undefined;

  const [deleteCode, { loading: deleteRunning }] = useMutation<
    DeleteUserStatusData,
    DeleteUserStatusVariables
  >(DELETE_USER_STATUS, {
    onCompleted: (data) => {
      message.success("Record successfully deleted.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to delete record!");
    },
  });

  const [undeleteCode, { loading: undeleteRunning }] = useMutation<
    UndeleteUserStatusData,
    UndeleteUserStatusVariables
  >(UNDELETE_USER_STATUS, {
    onCompleted: (data) => {
      message.success("Record successfully restored.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to restore record!");
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} error />
      </div>
    );
  }

  return (
    <CodeListManager
      codeType={codeType}
      codeTypePlural={codeTypePlural}
      canAddCodes={canAddCodes}
      codes={codes}
      deleteRunning={deleteRunning}
      undeleteRunning={undeleteRunning}
      deleteCode={deleteCode}
      undeleteCode={undeleteCode}
    />
  );
};
