import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { message, Button, Form, Input, Modal, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  USER_UPDATE_PASSWORD,
  UserUpdatePassword as UserUpdatePasswordData,
  UserUpdatePasswordVariables,
} from "../../../../../../lib/graphql/mutations";

interface FormProps {
  userId: number;
  visible: boolean;
  setVisible: (input: any) => void;
  maskClosable?: boolean;
  closable?: boolean;
  hideCancel?: boolean;
  title?: string;
}

interface ButtonProps {
  userId: number;
}

const ChangePasswordModalFormBase = ({
  userId,
  visible,
  setVisible,
  maskClosable,
  closable,
  hideCancel,
  title,
  form,
}: FormProps & FormComponentProps) => {
  let [confirmPasswordDirty, setConfirmPasswordDirty] = useState(false);
  let modalTitle = title ? title : "Change the Password";

  const [userUpdatePassword, { loading: updateRunning }] = useMutation<
    UserUpdatePasswordData,
    UserUpdatePasswordVariables
  >(USER_UPDATE_PASSWORD, {
    onCompleted: (data) => {
      message.success("Password changed successfully.");

      form.resetFields();
      setVisible(false);
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update the password!");
    },
  });

  const handleConfirmBlur = (e: any) => {
    const { value } = e.target;
    setConfirmPasswordDirty(confirmPasswordDirty || !!value);
  };

  const compareToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue("newPassword")) {
      callback("Password does not match your confirmation password!");
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: any, callback: any) => {
    if (value && confirmPasswordDirty) {
      form.validateFields(["confirmNewPassword"], { force: true });
    }
    callback();
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      userUpdatePassword({
        variables: {
          id: `${userId}`,
          newPassword: values.newPassword,
        },
      });
    });
  };

  let display = !hideCancel ? 'true' : 'none';

  return (
    <Modal
      visible={visible}
      title={modalTitle}
      okText="Update"
      onCancel={handleCancel}
      onOk={handleSubmit}
      maskClosable={maskClosable}
      closable={closable}
      cancelButtonProps={{ style: { display: display } }}
    >
      <Spin spinning={updateRunning}>
        <Form layout="vertical">
          <Form.Item label="New Password" hasFeedback>
            {form.getFieldDecorator("newPassword", {
              rules: [
                {
                  required: true,
                  min: 8,
                  max: 50,
                  message: "Enter a new password of 8 characters or more!",
                },
                {
                  validator: validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm New Password" hasFeedback>
            {form.getFieldDecorator("confirmNewPassword", {
              rules: [
                {
                  required: true,
                  max: 50,
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={handleConfirmBlur} />)}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export const ChangePasswordModalForm = Form.create<
  FormProps & FormComponentProps
>({
  name: "admin_update_username_form",
})(ChangePasswordModalFormBase);

export const ChangePasswordButton = ({ userId }: ButtonProps) => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: "inline-block", marginTop: "15px" }}>
      <Button onClick={() => showModal()} size="small" shape="round">
        Change Password
      </Button>
      <ChangePasswordModalForm
        userId={userId}
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  );
};
