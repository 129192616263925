import { gql } from "apollo-boost";
 
export const CONTACT_TYPE = gql`
  query ContactType($id: String!) {
    contactType(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
