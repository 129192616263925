import { gql } from "apollo-boost";
 
export const FOLLOWUP_RESOLUTION_TYPE = gql`
  query FollowupResolutionType($id: String!) {
    followupResolutionType(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
