import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { message, Button, Card, Icon, Popconfirm, Table } from "antd";
import moment from "moment-timezone";

import { StringSorter, DateOnlySorter, IdSorterDesc } from "../../../../lib/utils";

import {
  DELETE_INSURANCE,
  DeleteInsurance as DeleteInsuranceData,
  DeleteInsuranceVariables,
} from "../../../../lib/graphql/mutations";
import {
  Student_student_insurances as InsuranceRecord,
} from "../../../../lib/graphql/queries";

interface Props {
  title: string;
  insurances: InsuranceRecord[];
  changeInsuranceId: (insuranceId: string) => void;
  refreshStudentSidebar: () => void;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

export const InsuranceList = ({
  title,
  insurances,
  changeInsuranceId,
  refreshStudentSidebar,
}: Props) => {
  insurances.sort(IdSorterDesc);

  const handleAddInsurance = () => {
    changeInsuranceId("0");
  };

  const handleEditInsurance = (insuranceId: string) => {
    changeInsuranceId(insuranceId);
  };

  const [deleteInsurance] = useMutation<
    DeleteInsuranceData,
    DeleteInsuranceVariables
  >(DELETE_INSURANCE, {
    onCompleted: (data) => {
      message.success("Insurance successfully deleted.");
    },
    onError: (data) => {
      message.error("Failed to delete insurance!");
    },
  });

  const handleDeleteInsurance = async (id: string) => {
    await deleteInsurance({ variables: { id } });
    refreshStudentSidebar();
  };

  const insuranceColumns: any = [
    {
      title: "Insurance Carrier",
      dataIndex: "insuranceCarrier",
      key: "insuranceCarrier",
      ellipsis: true,
      sorter: (a: any, b: any) =>
        StringSorter(a.insuranceCarrier, b.insuranceCarrier),
    },
    {
      title: "Start Date",
      dataIndex: "startOfCoverageDate",
      key: "startOfCoverageDate",
      align: "center",
      width: "100px",
      render: (text: any, row: any) => (
        <span>
          {text
            ? moment(new Date(text)).format(dateFormat)
            : "-- not defined --"}
        </span>
      ),
      sorter: (a: any, b: any) =>
        DateOnlySorter(a.startOfCoverageDate, b.startOfCoverageDate),
    },
    {
      title: "End Date",
      dataIndex: "endOfCoverageDate",
      key: "endOfCoverageDate",
      align: "center",
      width: "100px",
      render: (text: any, row: any) => (
        <span>
          {text
            ? moment(new Date(text)).format(dateFormat)
            : "-- not defined --"}
        </span>
      ),
      sorter: (a: any, b: any) =>
        DateOnlySorter(a.endOfCoverageDate, b.endOfCoverageDate),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: "60px",
      align: "center",
      render: (text: any, row: any) => (
        <span>
        <Icon type="edit" onClick={() => handleEditInsurance(text)} />
        &nbsp;&nbsp;
        <Popconfirm
          title={`Are you sure delete this Campus entry?`}
          onConfirm={() => handleDeleteInsurance(text)}
          okText="Yes"
          cancelText="No"
        >
          <Icon type="delete" />
        </Popconfirm>
      </span>

      ),
    },
  ];

  return (
    <Card
      size="small"
      title={title}
      extra={
        <Button
          type="primary"
          size="small"
          shape="round"
          onClick={() => handleAddInsurance()}
        >
          Add new insurance
        </Button>
      }
    >
      <Table
        rowKey="id"
        columns={insuranceColumns}
        dataSource={insurances}
        size="middle"
      />
    </Card>
  );
};
