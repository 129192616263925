import { gql } from "apollo-boost";

export const UNIVERSITY_CAMPUS = gql`
  query UniversityCampus($id: String!) {
    universityCampus(id: $id) {
      id
      campusKey
      name
      description
      isDefault
    }
  }
`;
