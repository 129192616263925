import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message, Button, Icon, Popconfirm, Spin, Table } from "antd";

import { StringSorter } from "../../../../../../lib/utils";
import { ShowDeletableRecordText } from "../../../../components";
import { UniversityCampusListSkeleton } from "../UniversityCampusListSkeleton";

import {
  AddUniversityCampusButton,
  EditUniversityCampusIcon,
} from "../UniversityCampus";

import {
  DELETE_UNIVERSITY_CAMPUS,
  DeleteUniversityCampus as DeleteUniversityCampusData,
  DeleteUniversityCampusVariables,
  UNDELETE_UNIVERSITY_CAMPUS,
  UndeleteUniversityCampus as UndeleteUniversityCampusData,
  UndeleteUniversityCampusVariables,
} from "../../../../../../lib/graphql/mutations";
import {
  UNIVERSITY_CAMPUSES,
  UniversityCampuses as UniversityCampusesData,
  UniversityCampusesVariables,
} from "../../../../../../lib/graphql/queries";

interface Props {
  universityId: string;
}

export const UniversityCampusList = ({ universityId }: Props) => {
  const title = "University Campuses";

  const { data, loading, error, refetch } = useQuery<
    UniversityCampusesData,
    UniversityCampusesVariables
  >(UNIVERSITY_CAMPUSES, {
    variables: {
      universityId,
      includeDeleted: true,
    },
    fetchPolicy: "no-cache",
    skip: universityId === "0",
  });
  const campuses = data ? data.universityCampuses : [];

  const [deleteUniversityCampus, { loading: deleteRunning }] = useMutation<
    DeleteUniversityCampusData,
    DeleteUniversityCampusVariables
  >(DELETE_UNIVERSITY_CAMPUS, {
    onCompleted: (data) => {
      message.success("Campus successfully deleted.");
    },
    onError: (data) => {
      message.error("Failed to delete campus!");
    },
  });

  const [undeleteUniversityCampus, { loading: undeleteRunning }] = useMutation<
    UndeleteUniversityCampusData,
    UndeleteUniversityCampusVariables
  >(UNDELETE_UNIVERSITY_CAMPUS, {
    onCompleted: (data) => {
      message.success("Campus successfully restored.");
    },
    onError: (data) => {
      message.error("Failed to restore campus!");
    },
  });

  const handleDeleteCampus = async (id: string) => {
    await deleteUniversityCampus({ variables: { id } });
    refetch();
  };

  const handleUndeleteCampus = async (id: string) => {
    await undeleteUniversityCampus({ variables: { id } });
    refetch();
  };

  const campusColumns = [
    {
      title: "Campus Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) => StringSorter(a.name, b.name),
    },
    {
      title: "Suffix",
      dataIndex: "campusKey",
      key: "campusKey",
      width: "50px",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) => StringSorter(a.campusKey, b.campusKey),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: "120px",
      // TODO: Fix the center, it currently won;t xcompile
      // align: 'center',
      render: (text: any, row: any) =>
        row.isDeleted ? (
          <span>
            <Popconfirm
              title={`Are you sure restore this Campus entry?`}
              onConfirm={() => handleUndeleteCampus(text)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" shape="round">
                Restore
              </Button>
            </Popconfirm>
          </span>
        ) : (
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <EditUniversityCampusIcon
              id={+text}
              universityId={universityId}
              listRefetch={refetch}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Popconfirm
              title={`Are you sure delete this Campus entry?`}
              onConfirm={() => handleDeleteCampus(text)}
              okText="Yes"
              cancelText="No"
            >
              <Icon type="delete" />
            </Popconfirm>
          </span>
        ),
    },
  ];

  if (loading) {
    return (
      <div className="admin">
        <UniversityCampusListSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <UniversityCampusListSkeleton title={title} error />
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <div>
          <b>{title}</b>
          <div
            style={{
              display: "inline-block",
              float: "right",
            }}
          >
            <AddUniversityCampusButton
              universityId={universityId}
              listRefetch={refetch}
            />
          </div>
          <hr />
          <Spin spinning={deleteRunning || undeleteRunning}>
            <div className="air__utils__scrollTable">
              <Table
                rowKey="id"
                columns={campusColumns}
                dataSource={campuses}
              />
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};
