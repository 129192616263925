import React from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Layout, Popconfirm, Spin, Table } from "antd";

import { StringSorter } from "../../../../lib/utils";
import { ShowDeletableRecordText } from "../../components";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../../lib/utils";

const { Content } = Layout;

interface Props {
  codeType: string;
  codeTypePlural: string;
  canAddCodes: boolean;
  codes: any;
  deleteRunning: boolean;
  undeleteRunning: boolean;
  deleteCode: (options?: any) => any;
  undeleteCode: (options?: any) => any;
}

interface PopEditCodeProps {
  codeType: string;
  id: string;
  isSystemCode: Boolean;
  handleEditCode: (id: string) => Promise<void>;
  handleDeleteCode: (id: string) => Promise<void>;
}

interface PopUndeleteCodeProps {
  codeType: string;
  id: string;
  isSystemCode: Boolean;
  handleUndeleteCode: (id: string) => Promise<void>;
}

export const CodeListManager = ({
  codeType,
  codeTypePlural,
  canAddCodes,
  codes,
  deleteRunning,
  undeleteRunning,
  deleteCode,
  undeleteCode,
}: Props) => {
  const dashUrl = "/admin/codes";
  const addUrl =
    canAddCodes && codes.length > 0 && !codes[0].isSystemCode
      ? `/admin/codes/${codeType.toLowerCase()}/0`
      : null;
  const editUrl = `/admin/codes/${codeType.toLowerCase()}`;
  const history = useHistory();

  const handleEditCode = async (id: string) => {
    history.push(`${editUrl}/${id}`);
  };

  const handleAddCode = async () => {
    history.push(`${addUrl}`);
  };

  const handleDeleteCode = async (id: string) => {
    await deleteCode({ variables: { id } });
  };

  const handleUndeleteCode = async (id: string) => {
    await undeleteCode({ variables: { id } });
  };

  const columns: any = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) => StringSorter(a.code, b.code),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText text={text} isRecordDeleted={row.isDeleted} />
      ),
      sorter: (a: any, b: any) => StringSorter(a.name, b.name),
    },
// TODO: Implement the default values when functionality fully defined
/*    {
      title: "Is Default",
      dataIndex: "isDefault",
      key: "isDefault",
      align: "center",
      render: (text: any, row: any) => (
        <ShowDeletableRecordText
          text={text ? "true" : ""}
          isRecordDeleted={row.isDeleted}
        />
      ),
      sorter: (a: any, b: any) => DefaultSorter(a.isDefault, b.isDefault),
    },
*/    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any) =>
        row.isDeleted ? (
          <PopUndeleteCode
            codeType={codeType}
            id={text}
            isSystemCode={row.isSystemCode}
            handleUndeleteCode={handleUndeleteCode}
          />
        ) : (
          <PopEditCode
            codeType={codeType}
            id={text}
            isSystemCode={row.isSystemCode}
            handleEditCode={handleEditCode}
            handleDeleteCode={handleDeleteCode}
          />
        ),
    },
  ];

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Spin spinning={deleteRunning || undeleteRunning}>
              <div>
                <Helmet title={`Manage Codes: ${codeTypePlural}`} />
                <div className="air__utils__heading">
                  <h5>
                    <Link to={`${dashUrl}`}>Manage Codes</Link> :{" "}
                    {codeTypePlural}
                  </h5>
                </div>

                <div className="card">
                  <div className="card-header card-header-flex">
                    <div className="d-flex flex-column justify-content-center mr-auto">
                      <h4 className="mb-0">
                        <strong>{codeType} List</strong>
                      </h4>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{ marginTop: "25px" }}
                    >
                      {addUrl && (
                        <span>
                          <Button
                            onClick={() => handleAddCode()}
                            size="small"
                            shape="round"
                          >
                            Add
                          </Button>
                        </span>
                      )}
                      &nbsp;
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="air__utils__scrollTable">
                      <Table rowKey="id" columns={columns} dataSource={codes} />
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(hasAuthRole("Navigator"), hasAuthRole("AccountManager"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};

export const PopUndeleteCode = ({
  codeType,
  id,
  isSystemCode,
  handleUndeleteCode,
}: PopUndeleteCodeProps) => {
  return (
    <span>
      {!isSystemCode && (
        <Popconfirm
          title={`Are you sure restore this deleted ${codeType} entry?`}
          onConfirm={() => handleUndeleteCode(id)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="small" shape="round">
            Restore
          </Button>
        </Popconfirm>
      )}
    </span>
  );
};

export const PopEditCode = ({
  codeType,
  id,
  isSystemCode,
  handleEditCode,
  handleDeleteCode,
}: PopEditCodeProps) => {
  return (
    <span>
      <Button
        style={{ marginRight: "5px" }}
        onClick={() => handleEditCode(id)}
        size="small"
        shape="round"
      >
        Edit
      </Button>
      {!isSystemCode && (
        <Popconfirm
          title={`Are you sure you want to delete this ${codeType} entry?`}
          onConfirm={() => handleDeleteCode(id)}
          okText="Yes"
          cancelText="No"
        >
          <Button size="small" shape="round">
            Delete
          </Button>
        </Popconfirm>
      )}
    </span>
  );
};
