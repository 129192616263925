import { gql } from "apollo-boost";
 
export const CREATE_CALL_NOTE = gql`
  mutation CreateCallNote($data: CreateCallNoteInput!) {
    createCallNote(data: $data) {
        id
        noteTypeId
        noteType {
          code
          name
        }
        updatedAt
        updatedByUserId
        updatedByUser {
          firstName
          middleName
          lastName
        }
        note
    }
  }
`;
