import { gql } from "apollo-boost";
 
export const USER_STATUS = gql`
  query UserStatus($id: String!) {
    userStatus(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
