import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Empty, Form } from "antd";

import moment from "moment-timezone";

import { FormComponentProps } from "antd/lib/form";

import { AddStudentCallButton } from "../StudentCall";
import { CloseStudentCallFollowupIcon } from "../StudentCallFollowup";
import { AddStudentCallNoteButton } from "../StudentCallNote";
import { StudentFormSkeleton } from "../StudentFormSkeleton";

// import { ROLES, Roles as RolesData } from "../../lib/graphql/queries";
import {
  STUDENT_CALLS,
  StudentCalls as StudentCallsData,
  StudentCalls_studentCalls as CallRecord,
  StudentCalls_studentCalls_callNotes as CallNoteRecord,
  StudentCalls_studentCalls_callFollowups as FollowupRecord,
  StudentCalls_studentCalls_callFollowups_followupNotes as FollowupNoteRecord,
  StudentCallsVariables,
} from "../../../../lib/graphql/queries";
import { IdSorter, IdSorterDesc } from "../../../../lib/utils";

interface Props {
  studentId: string | undefined;
}

interface CallsProps {
  calls: CallRecord[] | undefined;
}

interface CallNotesProps {
  callId: string | undefined;
  callNotes: CallNoteRecord[] | undefined;
}

interface FollowupsProps {
  followups: FollowupRecord[] | undefined;
}

interface FollowupNotesProps {
  followupId: string | undefined;
  followupNotes: FollowupNoteRecord[] | undefined;
}

interface FullNameFields {
  firstName: String | null | undefined;
  middleName: String | null | undefined;
  lastName: String | null | undefined;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY hh:mm A";

export const StudentOpenCallsBase = ({
  studentId,
  form,
}: Props & FormComponentProps) => {
  let [showCallNotes, setShowCallNotes] = useState<any>({});
  let [showNotes, setShowNotes] = useState<boolean>(false);

  const sortForOpenCalls = (calls: CallRecord[]) => {
    calls.sort(IdSorter);

    for (let i = 0; i < calls.length; i++) {
      let call = calls[i];
      call.callNotes.sort(IdSorter);
      call.callFollowups.sort(IdSorterDesc);

      for (let j = 0; j < call.callFollowups.length; j++) {
        let followup = call.callFollowups[j];
        followup.followupNotes.sort(IdSorter);
      }
    }
  };

  // Saved the code below before added tbe useEffect code in an attempt to solve the react state bug
  /*
  const { data, loading, error, refetch } = useQuery<StudentCallsData, StudentCallsVariables>(
    STUDENT_CALLS,
    {
      variables: {
        studentId: studentId ?? "0",
        openOnly: true,
      },
      fetchPolicy: "no-cache",
      skip: studentId === undefined
    }
  );
  console.log("StudentOpenCallsBase - after useQuery: ", data);
  const studentCalls = data ? data.studentCalls : [];
*/

  // Implemented useLazyQuery & useEffect code in an attempt to solve the react state bug
  const [getStudentCalls, { data, loading, error, refetch }] = useLazyQuery<
    StudentCallsData,
    StudentCallsVariables
  >(STUDENT_CALLS, {
    variables: {
      studentId: studentId ?? "0",
      openOnly: true,
    },
    fetchPolicy: "no-cache",
    // skip: studentId === undefined
  });

  // console.log("StudentOpenCallsBase - before useEffect.");
  useEffect(() => {
    getStudentCalls();
    // console.log("StudentOpenCallsBase - in useEffect.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let studentCalls = data ? data.studentCalls : [];
  sortForOpenCalls(studentCalls);
  // console.log("StudentOpenCallsBase - after useQuery: ", data);

  if (loading) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={"Student Call History"} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={"Student Call History"} error />
      </div>
    );
  }
  const fullName = (fields: FullNameFields) => {
    return (
      (fields && fields.firstName ? fields.firstName : "") +
      (fields && fields.middleName ? " " + fields.middleName : "") +
      (fields && fields.lastName ? " " + fields.lastName : "")
    );
  };

  const toggleNotes = (showNoteLabel: string) => {
    console.log(
      "toggleNotes() - showCallNotes[" + showNoteLabel + "]=",
      showCallNotes[showNoteLabel]
    );

    if (showCallNotes[showNoteLabel]) {
      console.log("toggleNotes() - 1");
      showCallNotes[showNoteLabel] = !showCallNotes[showNoteLabel];
    } else {
      console.log("toggleNotes() - 2");
      showCallNotes[showNoteLabel] = true;
    }

    setShowCallNotes(showCallNotes);
    setShowNotes(!showNotes);
  };

  const CallItems = ({ calls }: CallsProps) => {
    return (
      <div>
        {studentCalls &&
          studentCalls.map((call) => {
            return (
              <div key={"call-" + call.id}>
                <div className="d-flex flex-nowrap align-items-start pt-4">
                  <div className="air__utils__avatar air__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
                    <img
                      src="/resources/images/icons/call.jpg"
                      alt="Student Call"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <div className="d-flex flex-wrap mb-2">
                        <div className="mr-auto">
                          <div className="text-gray-6">
                            <span className="text-dark font-weight-bold">
                              <i className="icmn-radio-unchecked mr-1 text-blue" />
                              {moment(call.caseOpenedAt).format(dateFormat)}
                            </span>
                            <br />
                            <span className="text-dark font-weight-bold">
                              {call.callType.name}
                            </span>
                          </div>
                          <div>{fullName(call.navigatorUser)}</div>
                        </div>
                      </div>

                      <CallNoteItems
                        callId={call.id}
                        callNotes={call.callNotes}
                      />
                      <div className="d-flex flex-wrap justify-content-start align-items-start mb-3 border-bottom">
                        {/*
                        <Button
                          type="link"
                          onClick={() => showCloseCallModal()}
                        >
                          <Icon type="check" />
                          &nbsp;close call
                        </Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        */}
                        <AddStudentCallNoteButton
                          callId={call.id}
                          callFollowupId={null}
                          listRefetch={refetch}
                        />
                        {/*
                        <Button type="link" onClick={() => showFollowUpModal()}>
                          <Icon type="history" />
                          &nbsp;add followup
                        </Button>
                        */}
                      </div>
                      <FollowupItems followups={call.callFollowups} />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const CallNoteItems = ({ callId, callNotes }: CallNotesProps) => {
    let lastIndex = callNotes ? callNotes.length - 1 : 0;
    return (
      <div>
        {callNotes &&
          callNotes.map((callNote, index) => {
            return (
              <div
                className="mb-3"
                key={"callNote-" + callNote.id}
                style={{
                  display:
                    index === lastIndex || showCallNotes["call" + callId]
                      ? "block"
                      : "none",
                }}
              >
                <div>
                  <u>
                    <i>
                      {callNote.noteType.name} by{" "}
                      {fullName(callNote.updatedByUser)} on{" "}
                      {moment(callNote.updatedAt).format(dateFormat)}
                    </i>
                  </u>
                </div>
                <div style={{ whiteSpace: "pre-line" }}>{callNote.note}</div>
              </div>
            );
          })}
        <div style={{ width: "100%", textAlign: "center" }}>
          {callNotes &&
            callNotes?.length > 1 &&
            !showCallNotes["call" + callId] && (
              <Button
                type="link"
                className="text-blue"
                style={{ padding: 0, margin: 0 }}
                onClick={() => toggleNotes("call" + callId)}
              >
                Show more
              </Button>
            )}
          {callNotes &&
            callNotes?.length > 1 &&
            showCallNotes["call" + callId] && (
              <Button
                type="link"
                className="text-blue"
                style={{ padding: 0, margin: 0 }}
                onClick={() => toggleNotes("call" + callId)}
              >
                Show less
              </Button>
            )}
        </div>
      </div>
    );
  };

  const FollowupItems = ({ followups }: FollowupsProps) => {
    return (
      <div>
        {followups &&
          followups.map((followup) => {
            return (
              <div
                className="d-flex flex-nowrap align-items-start pt-4"
                key={"followup-" + followup.id}
              >
                <div className="air__utils__avatar air__utils__avatar--size64 mr-4 flex-shrink-0 align-self-start">
                  <img
                    src="/resources/images/icons/follow-up.jpg"
                    alt="Follow-up Required"
                  />
                </div>
                <div className="flex-grow-1">
                  <div>
                    <div className="d-flex flex-wrap mb-2">
                      <div className="mr-auto">
                        <div className="text-gray-6">
                          <span className="text-dark font-weight-bold">
                            <i className="icmn-radio-unchecked mr-1 text-blue" />
                            {moment(followup.createdAt).format(dateFormat)}
                          </span>
                          <br />
                          <span className="text-dark font-weight-bold">
                            {followup.followupType.name}
                          </span>
                        </div>
                        <div>{fullName(followup.createdByUser)}</div>
                      </div>
                    </div>

                    <FollowupNoteItems
                      followupId={followup.id}
                      followupNotes={followup.followupNotes}
                    />

                    {followup.followupStatusId <= 3 && (
                      <div className="d-flex flex-wrap justify-content-start align-items-start mb-3 border-bottom">
                        <CloseStudentCallFollowupIcon
                          followupId={followup.id}
                          listRefetch={refetch}
                        />
                        <span style={{ paddingLeft: 100 }}>
                          <AddStudentCallNoteButton
                            callId={"" + followup.callId}
                            callFollowupId={followup.id}
                            listRefetch={refetch}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const FollowupNoteItems = ({
    followupId,
    followupNotes,
  }: FollowupNotesProps) => {
    let lastIndex = followupNotes ? followupNotes.length - 1 : 0;
    return (
      <div>
        {followupNotes &&
          followupNotes.map((followupNote, index) => {
            return (
              <div
                className="mb-3"
                key={"followupNote-" + followupNote.id}
                style={{
                  display:
                    index === lastIndex || showCallNotes["followup" + followupId]
                      ? "block"
                      : "none",
                }}
              >
                <div>
                  <u>
                    <i>
                      {followupNote.noteType.name} by{" "}
                      {fullName(followupNote.updatedByUser)} on{" "}
                      {moment(followupNote.updatedAt).format(dateFormat)}
                    </i>
                  </u>
                </div>
                <div style={{ whiteSpace: "pre-line" }}>
                  {followupNote.note}
                </div>
              </div>
            );
          })}
        <div style={{ width: "100%", textAlign: "center" }}>
          {followupNotes &&
            followupNotes?.length > 1 &&
            !showCallNotes["followup" + followupId] && (
              <Button
                type="link"
                className="text-blue"
                style={{ padding: 0, margin: 0 }}
                onClick={() => toggleNotes("followup" + followupId)}
              >
                Show more
              </Button>
            )}
          {followupNotes &&
            followupNotes?.length > 1 &&
            showCallNotes["followup" + followupId] && (
              <Button
                type="link"
                className="text-blue"
                style={{ padding: 0, margin: 0 }}
                onClick={() => toggleNotes("followup" + followupId)}
              >
                Show less
              </Button>
            )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="card-header card-header-flex">
        <div className="d-flex flex-column justify-content-center mr-auto">
          <h4 className="mb-0">
            <strong>Open Calls</strong>
          </h4>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <span>
            <AddStudentCallButton
              studentId={studentId ? studentId : ""}
              listRefetch={refetch}
              buttonSize={"small"}
            />
          </span>
        </div>
      </div>

      <CallItems calls={studentCalls} />
      {studentCalls.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>No open calls</span>}
        >
          <span>
            <AddStudentCallButton
              studentId={studentId ? studentId : ""}
              listRefetch={refetch}
              buttonSize={"default"}
            />
          </span>
        </Empty>
      )}
    </div>
  );
};

export const StudentOpenCalls = Form.create<Props & FormComponentProps>({
  name: "student_open_calls_form",
})(StudentOpenCallsBase);
