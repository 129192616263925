import { gql } from "apollo-boost";
 
export const GENDER = gql`
  query Gender($id: String!) {
    gender(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
