import { gql } from "apollo-boost";

export const CALL_NOTE = gql`
  query CallNote($id: String!) {
    callNote(id: $id) {
        id
        noteTypeId
        noteType {
          code
          name
        }
        updatedAt
        updatedByUserId
        updatedByUser {
          firstName
          middleName
          lastName
        }
        note
    }
  }
`;
