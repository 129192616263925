import React, { useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import { message, Button, Form, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment-timezone";

import {
  AUTH_LOGIN,
  AuthLogin as AuthLoginData,
  AuthLoginVariables,
} from "../../lib/graphql/mutations";

import { UserContext } from "../../lib/utils";
import loginLogo from "./assets/login-logo.png";

interface Props {
  title: string;
}

moment.tz.setDefault("America/New_York");

export const LoginBase = ({ title, form }: Props & FormComponentProps) => {
  const history = useHistory();
  const homeUrl = "/dashboard";
  const { setUser } = useContext(UserContext);

  const [authLogin, { loading: loginRunning }] = useMutation<
    AuthLoginData,
    AuthLoginVariables
  >(AUTH_LOGIN, {
    onCompleted: (data) => {
      message.success("Logged in successfully.");

      setUser(data.authLogin);
      sessionStorage.setItem("user", JSON.stringify(data.authLogin));     
      if(data.authLogin.userCredential)
        gotoHome(data.authLogin.userCredential?.username);
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to login, please check your credentials!");

      sessionStorage.removeItem("user");
    },
  });

  const gotoHome = (username: string) => {
    history.push(`${homeUrl}/${username}`);
  };


  const handleLogin = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      const input = {
        ...values,
      };

      authLogin({ variables: input });
    });
  };

  return (
    <div>
      <Helmet title="Login" />
      <div
        className="d-flex mt-auto justify-content-center"
        style={{ paddingTop: "80px" }}
      >
        <div className="app-sider__logo">
          <img src={loginLogo} alt="Christie Campus Health" />
        </div>
      </div>
      <div className="pb-5 pr-3 d-flex mt-auto justify-content-center">
        <div className={`pl-5 pr-5 pb-5 bg-white`}>
          <div className="app-login text-dark font-size-30 mb-2 text-center">
            Log In
          </div>
          <Form
            layout="vertical"
            hideRequiredMark
            onSubmit={handleLogin}
            className="mb-4"
          >
            <Form.Item>
              {form.getFieldDecorator("username", {
                rules: [
                  {
                    required: true,
                    message: "Please input your Login ID",
                  },
                ],
              })(<Input size="large" placeholder="Login ID" />)}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator("password", {
                rules: [
                  { 
                    required: true, 
                    message: "Please input your password" 
                  },
                ],
              })(<Input.Password size="large" placeholder="Password" />)}
            </Form.Item>
            <Button
              type="primary"
              size="large"
              className="text-center btn btn-success w-100 font-weight-bold font-size-18"
              htmlType="submit"
              loading={loginRunning}
            >
              Log In
            </Button>
          </Form>
          <div className="text-center">
            <Link
              to="/system/forgot-password"
              className="text-blue font-weight-bold font-size-18"
            >
              Forgot password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Login = Form.create<Props & FormComponentProps>({
  name: "login_form",
})(LoginBase);
