import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Button, Form, Icon, Input, Modal } from 'antd';

const AdditionalContactModalForm = Form.create({ name: 'change_additional_contact_form_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { /*parentId, id,*/ visible, recordId, onCancel, onSubmit, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title={!recordId ? "Add an Additional Contact" : "Update an Additional Contact"}
          okText={!recordId ? "Add" : "Update"}
          onCancel={onCancel}
          onOk={onSubmit}
        >
          <Form layout="vertical">
            <Form.Item label="First Name">
              {getFieldDecorator('firstName', {
                rules: [{ required: true, max: 100, message: 'Please enter the contact first name' }],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Middle">
              {getFieldDecorator('middleName', {
                rules: [{ max: 100 }],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Last Name">
              {getFieldDecorator('lastName', {
                rules: [{ required: true, max: 100, message: 'Please enter the contact last name' }],
              })(<Input />)}
            </Form.Item>

            {/* TODO: add additional contact fields */}

          </Form>
        </Modal>
      );
    }
  },
);

export class AddAdditionalContactButton extends React.Component {
  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleSubmit = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { parentId, id } = this.props;

    return (
      <div style={{display:"inline-block"}}>
        <Button onClick={this.showModal} size="small" shape="round">
          Add
        </Button>
        <AdditionalContactModalForm
          wrappedComponentRef={this.saveFormRef}
          id={id}
          parentId={parentId}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

export class EditAdditionalContactIcon extends React.Component {
  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleSubmit = () => {
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      form.resetFields();
      this.setState({ visible: false });
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    const { parentId, id } = this.props;

    return (
      <div style={{marginLeft: "15px", display:"inline-block"}}>
        <Icon type="edit" onClick={this.showModal} />
        <AdditionalContactModalForm
          wrappedComponentRef={this.saveFormRef}
          id={id}
          parentId={parentId}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}
