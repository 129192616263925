import { gql } from "apollo-boost";

export const CALL_NOTES = gql`
  query CallNotes($callId: String!, $callFollowupId: String!) {
    callNotes(callId: $callId, callFollowupId: $callFollowupId) {
        id
        noteTypeId
        noteType {
          code
          name
        }
        updatedAt
        updatedByUserId
        updatedByUser {
          firstName
          middleName
          lastName
        }
        note
    }
  }
`;
