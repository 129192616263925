import { createContext, useContext } from "react";

export interface AuthUser {
    id: string;
    username: String;
    userStatusId: number;
    userStatusName: string;
    roleId: number;
    roleName: string;
    email: string;
    altEmail: string | null;
    firstName: string;
    middleName: string | null;
    lastName: string;
    primaryPhone: string | null;
    canSmsPrimaryPhone: boolean | null;
    altPhone: string | null;
    canSmsAltPhone: boolean | null;
    avatar: string | null;
  }

export const UserContext = createContext<any>(null);

export const AuthVisible = ({ when, children }:any) =>{
  const { user } = useContext(UserContext);
  // console.log("AuthVisible() - user: ", user);

  return Boolean(when(user)) && children;
}

export const isAuthUser = (l:any) => (u:any) => (u?.userCredential?.username === l);
export const hasAuthRole = (r:any) => (u:any) => u?.role?.code === r;

export const anyAuth = (...conds:any) => (u:any) => conds.some((c:any) => c(u));
export const allAuth = (...conds:any) => (u:any) => conds.every((c:any) => c(u));

// ================================================================================================
// Syntax Usage Examples
//
// <AuthVisible when={isAuthUser("navigator")}><div>AuthVisible for user: navigator</div></AuthVisible>
// <AuthVisible when={isAuthUser("admin")}><div>AuthVisible for user: admin</div></AuthVisible>
// <AuthVisible when={hasAuthRole("Administrator")}><div>AuthVisible for role: Administrator</div></AuthVisible>
// <AuthVisible when={hasAuthRole("Navigator")}><div>AuthVisible for role: Navigator</div></AuthVisible>
// <AuthVisible when={anyAuth(hasAuthRole("Administrator"), hasAuthRole("Navigator"))}><div>AuthVisible for Role: Administrator or Navigator</div></AuthVisible>
// <AuthVisible when={allAuth(isAuthUser("admin"), hasAuthRole("Administrator"))}><div>AuthVisible for User: admin and Role: Administrator</div></AuthVisible>
// <AuthVisible when={anyAuth(isAuthUser("admin"), hasAuthRole("Administrator"))}><div>AuthVisible for User: admin or Role: Administrator</div></AuthVisible>
// ================================================================================================
