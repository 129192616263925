import React from "react";
import { useMutation, useQuery /*, useMutation*/ } from "@apollo/react-hooks";
import { message, Button, DatePicker, Form, Input, Select } from "antd";

import moment from "moment-timezone";

import { FormComponentProps } from "antd/lib/form";

import { MomentToDateOrNull } from "../../../../lib/utils";
import { StudentFormSkeleton } from "../StudentFormSkeleton";
import {
  UNIVERSITIES,
  Universities as UniversitiesData,
} from "../../../../lib/graphql/queries";
import {
  UNIVERSITY_CAMPUSES,
  UniversityCampuses as UniversityCampusesData,
} from "../../../../lib/graphql/queries";
import {
  GENDERS,
  Genders as GendersData,
} from "../../../../lib/graphql/queries";
import {
  LANGUAGES,
  Languages as LanguagesData,
} from "../../../../lib/graphql/queries";
import { STATES, States as StatesData } from "../../../../lib/graphql/queries";
import {
  STUDENT,
  Student as StudentData,
  StudentVariables,
} from "../../../../lib/graphql/queries";

import {
  UPDATE_STUDENT,
  UpdateStudent as UpdateStudentData,
  UpdateStudentVariables,
} from "../../../../lib/graphql/mutations";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

interface Props {
  studentId: string | undefined;
  refreshStudentSidebar: () => void;
}

moment.tz.setDefault("America/New_York");

export const StudentDetailFormBase = ({
  studentId,
  refreshStudentSidebar,
  form,
}: Props & FormComponentProps) => {
  const { data: universitiesData } = useQuery<UniversitiesData>(UNIVERSITIES, {
    fetchPolicy: "no-cache",
  });
  const { data: universityCampusesData } = useQuery<UniversityCampusesData>(
    UNIVERSITY_CAMPUSES,
    {
      variables: {
        universityId: "",
      },
      fetchPolicy: "no-cache",
    }
  );
  const { data: gendersData } = useQuery<GendersData>(GENDERS, {
    fetchPolicy: "no-cache",
  });
  const { data: languagesData } = useQuery<LanguagesData>(LANGUAGES, {
    fetchPolicy: "no-cache",
  });
  const { data: statesData } = useQuery<StatesData>(STATES, {
    fetchPolicy: "no-cache",
  });

  const { loading, error, refetch } = useQuery<StudentData, StudentVariables>(
    STUDENT,
    {
      variables: {
        id: studentId ?? "0",
      },
      fetchPolicy: "no-cache",
      skip: studentId === undefined,
      onCompleted: (data) => {
        if (data) {
          const studentRecord = data.student ? data.student : null;

          if (studentRecord) {
            form.setFieldsValue({
              universityId: `${studentRecord.universityId}`,
              universityCampusId: `${studentRecord.universityCampusId}`,
              studentIdentifier: studentRecord.studentIdentifier,
              dateOfBirthMoment: studentRecord.dateOfBirth
                ? moment(studentRecord.dateOfBirth)
                : null,
              genderId: `${studentRecord.genderId}`,
              languageId: `${studentRecord.languageId}`,

              coverageStartMoment: studentRecord.coverageStartDate
                ? moment(studentRecord.coverageStartDate)
                : null,
              coverageEffectiveMoment: studentRecord.coverageEffectiveDate
                ? moment(studentRecord.coverageEffectiveDate)
                : null,
              coverageTerminationMoment: studentRecord.coverageTerminationDate
                ? moment(studentRecord.coverageTerminationDate)
                : null,

              firstName: studentRecord.contact.firstName,
              middleName: studentRecord.contact.middleName,
              lastName: studentRecord.contact.lastName,
              primaryPhone: studentRecord.contact.primaryPhone,
              altPhone: studentRecord.contact.altPhone,
              email: studentRecord.contact.email,
              altEmail: studentRecord.contact.altEmail,
              addressLine1: studentRecord.contact.addressLine1,
              addressLine2: studentRecord.contact.addressLine2,
              city: studentRecord.contact.city,
              stateId: `${studentRecord.contact.stateId}`,
              zipCode: studentRecord.contact.zipCode,

              permanent_addressLine1: studentRecord.permanentContact?.addressLine1,
              permanent_addressLine2: studentRecord.permanentContact?.addressLine2,
              permanent_city: studentRecord.permanentContact?.city,
              permanent_stateId: studentRecord.permanentContact?.stateId ? `${studentRecord.permanentContact?.stateId}` : null,
              permanent_zipCode: studentRecord.permanentContact?.zipCode,
            });
          }
        }
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  const [updateStudent /*, { loading: updateRunning }*/] = useMutation<
    UpdateStudentData,
    UpdateStudentVariables
  >(UPDATE_STUDENT, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={"Student Details"} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <StudentFormSkeleton title={"Student Details"} error />
      </div>
    );
  }

  const handleCancel = (e: any) => {
    refetch();
    message.info("Changes discarded.");
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      const student = {
        universityId: +values.universityId,
        universityCampusId: values.universityCampusId
          ? +values.universityCampusId
          : null,
        studentIdentifier: values.studentIdentifier,
        dateOfBirth: MomentToDateOrNull(values.dateOfBirthMoment),
        genderId: values.genderId ? +values.genderId : null,
        languageId: values.languageId ? +values.languageId : null,
        coverageStartDate: MomentToDateOrNull(values.coverageStartMoment),
        coverageEffectiveDate: MomentToDateOrNull(
          values.coverageEffectiveMoment
        ),
        coverageTerminationDate: MomentToDateOrNull(
          values.coverageTerminationMoment
        ),
        contact: {
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          primaryPhone: values.primaryPhone,
          altPhone: values.altPhone,
          email: values.email,
          altEmail: values.altEmail,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values.city,
          stateId: values.stateId ? +values.stateId : null,
          zipCode: values.zipCode,
        },
        permanentContact: {
          addressLine1: values.permanent_addressLine1,
          addressLine2: values.permanent_addressLine2,
          city: values.permanent_city,
          stateId: values.permanent_stateId ? +values.permanent_stateId : null,
          zipCode: values.permanent_zipCode,
        },
      };

      await updateStudent({
        variables: {
          id: `${studentId}`,
          data: student,
        },
      });
      refreshStudentSidebar();
    });
  };

  const handleSelectUniversityChange = () => {
    form.setFieldsValue({ universityCampusId: null });
  };

  return (
    <div>
      <Form className="student-detail-form">
        <h5 className="text-black mt-0">
          <strong>Student Profile</strong>
        </h5>

        <div className="row">
          <div className="col-md-6">
            <Form.Item label="University">
              {form.getFieldDecorator("universityId", {
                rules: [
                  { required: true },
                  { message: "Select student university!" },
                ],
              })(
                <Select
                  placeholder="Select a option and change input text above"
                  onChange={handleSelectUniversityChange}
                >
                  {universitiesData &&
                    universitiesData.universities.map((x) => {
                      return (
                        <Option key={`${x.id}`} value={`${x.id}`}>
                          {x.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Campus">
              {form.getFieldDecorator("universityCampusId", {
                rules: [{ message: "Select student university campus!" }],
              })(
                <Select placeholder="Select a option and change input text above">
                  {universityCampusesData &&
                    universityCampusesData.universityCampuses
                      .filter((x) => {
                        return (
                          "" + x.universityId ===
                          form.getFieldValue("universityId")
                        );
                      })
                      .map((y) => {
                        return (
                          <Option key={`${y.id}`} value={`${y.id}`}>
                            {y.name}
                          </Option>
                        );
                      })}
                </Select>
              )}
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Student ID">
              {form.getFieldDecorator("studentIdentifier")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Date of Birth">
              {form.getFieldDecorator("dateOfBirthMoment")(
                <DatePicker format={dateFormat} />
              )}
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Gender">
              {form.getFieldDecorator("genderId", {
                rules: [{ message: "Select student gender!" }],
              })(
                <Select placeholder="Select a option and change input text above">
                  {gendersData &&
                    gendersData.genders.map((x) => {
                      return (
                        <Option key={`${x.id}`} value={`${x.id}`}>
                          {x.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Language">
              {form.getFieldDecorator("languageId", {
                rules: [
                  {
                    message: "Select student's preferred language!",
                  },
                ],
              })(
                <Select placeholder="Select a option and change input text above">
                  {languagesData &&
                    languagesData.languages.map((x) => {
                      return (
                        <Option key={`${x.id}`} value={`${x.id}`}>
                          {x.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </div>

          <div className="col-md-4">
            <Form.Item label="First Name">
              {form.getFieldDecorator("firstName")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="Middle Name">
              {form.getFieldDecorator("middleName")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="Last Name">
              {form.getFieldDecorator("lastName")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Primary Phone">
              {form.getFieldDecorator("primaryPhone")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Alternate Phone">
              {form.getFieldDecorator("altPhone")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Email">
              {form.getFieldDecorator("email", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    message: "Please input your E-mail!",
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Alternate Email">
              {form.getFieldDecorator("altEmail", {
                rules: [
                  {
                    type: "email",
                    message: "The input is not valid Email!",
                  },
                  {
                    message: "Please input your Email!",
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </div>
          <div className="border-top col-12">
            <b>Coverage Dates:</b>
          </div>
          <div className="col-md-4">
            <Form.Item label="Start">
              {form.getFieldDecorator("coverageStartMoment")(
                <DatePicker format={dateFormat} />
              )}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="Effective">
              {form.getFieldDecorator("coverageEffectiveMoment")(
                <DatePicker format={dateFormat} />
              )}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="Termination">
              {form.getFieldDecorator("coverageTerminationMoment")(
                <DatePicker format={dateFormat} />
              )}
            </Form.Item>
          </div>
          <div className="border-top col-12">&nbsp;</div>
          <div>
            <strong>
              <u>Local Address</u>
            </strong>
          </div>
          <div className="col-12">
            <Form.Item label="Address">
              {form.getFieldDecorator("addressLine1")(
                <Input placeholder="1234 Main St." />
              )}
            </Form.Item>
            <Form.Item label="Address 2">
              {form.getFieldDecorator("addressLine2")(
                <Input placeholder="Apartment, studio, or floor" />
              )}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="City">
              {form.getFieldDecorator("city")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="State">
              {form.getFieldDecorator("stateId", {
                rules: [{ message: "Please select your state!" }],
              })(
                <Select placeholder="Select a option and change input text above">
                  {statesData &&
                    statesData.states.map((x) => {
                      return (
                        <Option key={`${x.id}`} value={`${x.id}`}>
                          {x.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </div>
          <div className="col-md-2">
            <Form.Item label="Zip">
              {form.getFieldDecorator("zipCode")(<Input />)}
            </Form.Item>
          </div>

          <div className="border-top col-12">&nbsp;</div>
          <div>
            <strong>
              <u>Permanent Address</u>
            </strong>
          </div>
          <div className="col-12">
            <Form.Item label="Address">
              {form.getFieldDecorator("permanent_addressLine1")(
                <Input placeholder="1234 Main St." />
              )}
            </Form.Item>
            <Form.Item label="Address 2">
              {form.getFieldDecorator("permanent_addressLine2")(
                <Input placeholder="Apartment, studio, or floor" />
              )}
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="City">
              {form.getFieldDecorator("permanent_city")(<Input />)}
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form.Item label="State">
              {form.getFieldDecorator("permanent_stateId", {
                rules: [{ message: "Please select your state!" }],
              })(
                <Select placeholder="Select a option and change input text above">
                  {statesData &&
                    statesData.states.map((x) => {
                      return (
                        <Option key={`${x.id}`} value={`${x.id}`}>
                          {x.name}
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </div>
          <div className="col-md-2">
            <Form.Item label="Zip">
              {form.getFieldDecorator("permanent_zipCode")(<Input />)}
            </Form.Item>
          </div>
        </div>

        <div className="form-actions">
          <Button
            style={{ width: 200 }}
            type="primary"
            onClick={handleSave}
            className="mr-3"
          >
            Update
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </div>
      </Form>
    </div>
  );
};

export const StudentDetailForm = Form.create<Props & FormComponentProps>({
  name: "student_detsil_form",
})(StudentDetailFormBase);
