import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { CodesSkeleton, CodeListManager } from "../../components";
import { CALL_RESOLUTIONS, CallResolutions as CallResolutionsData } from "../../../../lib/graphql/queries";
import {
  DELETE_CALL_RESOLUTION,
  DeleteCallResolution as DeleteCallResolutionData,
  DeleteCallResolutionVariables,
  UNDELETE_CALL_RESOLUTION,
  UndeleteCallResolution as UndeleteCallResolutionData,
  UndeleteCallResolutionVariables,
} from "../../../../lib/graphql/mutations/codes";

import "../../styles/admin.css";

interface Props {
  title: string;
}

export const CallResolutionList = ({ title }: Props) => {
  const codeType = "CallResolution";
  const codeTypePlural = "CallResolutions";
  const canAddCodes = true;

  const { data, loading, error, refetch } = useQuery<CallResolutionsData>(CALL_RESOLUTIONS, {
    variables: {
      includeDeleted: true,
    },
    fetchPolicy: "no-cache"
  });
  const codes = data ? data.callResolutions : undefined;

  const [
    deleteCode,
    { loading: deleteRunning },
  ] = useMutation<DeleteCallResolutionData, DeleteCallResolutionVariables>(DELETE_CALL_RESOLUTION, {
    onCompleted: (data) => {message.success("Record successfully deleted."); refetch()},
    onError: (data) => {message.error("Failed to delete record!");},
  });

  const [
    undeleteCode,
    { loading: undeleteRunning },
  ] = useMutation<UndeleteCallResolutionData, UndeleteCallResolutionVariables>(UNDELETE_CALL_RESOLUTION, {
    onCompleted: (data) => {message.success("Record successfully restored."); refetch()},
    onError: (data) => {message.error("Failed to restore record!");},
  });

  if (loading) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} error />
      </div>
    );
  }

  return (
    <CodeListManager 
      codeType={codeType} 
      codeTypePlural={codeTypePlural} 
      canAddCodes={canAddCodes}
      codes={codes}
      deleteRunning={deleteRunning}
      undeleteRunning={undeleteRunning}
      deleteCode={deleteCode}
      undeleteCode={undeleteCode}
    />
  );
};
