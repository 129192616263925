import { gql } from "apollo-boost";

export const STUDENT = gql`
query Student($id: String!) {
  student(id: $id) {
    id
    studentIdentifier
    dateOfBirth
    ssn
    coverageStartDate
    coverageEffectiveDate
    coverageTerminationDate
    
    contactId
    permanentContactId
    universityId
    universityCampusId
    contactTypeId
    studentTypeId
    genderId
    languageId

    contact{
      id
      firstName
      middleName
      lastName
      addressLine1
      addressLine2
      city
      stateId
      state{
        code
        name
      }
      zipCode
      primaryPhone
      altPhone
      email
      altEmail
    },

    insurances{
      id
      isActive
      insuranceCarrier
      idNumber
      startOfCoverageDate
      endOfCoverageDate
      isDeleted
    },

    permanentContact{
      id
      addressLine1
      addressLine2
      city
      stateId
      state{
        code
        name
      }
      zipCode
    },

    university{
      shortName
      name
      avatar
    },

    universityCampus{
      name
    },

    contactType{
      code
      name
    },
    gender{
      code
      name
    },
    language{
      code
      name
    },
    studentType{
      code
      name
    }
  }
}
`;
