import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  STATE,
  State as StateData,
  StateVariables,
} from "../../../../lib/graphql/queries";
import {
  CREATE_STATE,
  CreateState as CreateStateData,
  CreateStateVariables,
  UPDATE_STATE,
  UpdateState as UpdateStateData,
  UpdateStateVariables,
} from "../../../../lib/graphql/mutations/codes";
import { CodeForm, SetCodeFormValues } from "../../components"

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

export const StateFormBase = ({ title, form }: Props & FormComponentProps) => {
  const codeType = "State";
  const codeTypePlural = "States";
  const { id } = useParams<MatchParams>();

  const { loading, error } = useQuery<StateData, StateVariables>(
    STATE,
    {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
      skip: id === "0",
      onCompleted: (data) => {
        if(!data)return;
        
        SetCodeFormValues(form, data.state);
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  const [createCode, { loading: createRunning }] = useMutation<
    CreateStateData,
    CreateStateVariables
  >(CREATE_STATE, {
    onCompleted: (data) => {
      message.success("Record successfully created.");
    },
    onError: (data) => {
      console.log(`Create error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to create record!");
    },
  });

  const [updateCode, { loading: updateRunning }] = useMutation<
    UpdateStateData,
    UpdateStateVariables
  >(UPDATE_STATE, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
    },
  });

  return (
    <CodeForm
      form={form} 
      title={title} 
      codeType={codeType} 
      codeTypePlural={codeTypePlural}
      isNew={(id === '0')}
      codeMaxLength={2}
      hasDescription={false}
      queryLoading={loading} 
      queryError={error} 
      createCode={createCode} 
      createRunning={createRunning} 
      updateCode={updateCode} 
      updateRunning={updateRunning}
    />
  );
};

export const StateForm = Form.create<Props & FormComponentProps>({
  name: "admin_code_state_form",
})(StateFormBase);
