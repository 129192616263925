import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
// import { Helmet } from "react-helmet";
import { Button, Table } from "antd";
import moment from "moment-timezone";

import { ViewStudentCallModal } from "../../../../common/ViewStudentCall";

import {
  DateOnlySorter,
  StringSorter,
  DefaultSorter,
  SettingsContext,
} from "../../../../../lib/utils";

// import { ReportFollowupsSkeleton } from "../ReportFollowupsSkeleton";
import {
  CALL_FOLLOWUPS_FROM_IDS,
  CallFollowupsFromIds as FollowupsData,
  CallFollowupsFromIdsVariables,
} from "../../../../../lib/graphql/queries";
import { useState } from "react";

interface Props {
  title: String | null;
  idCsv: string | null;
  setReportTasksIdCsv: (input: any) => void;
}

export const ReportFollowups = ({
  title,
  idCsv,
  setReportTasksIdCsv,
}: Props) => {
  const { settings } = useContext(SettingsContext);
  moment.tz.setDefault(settings.moment ? settings.moment.timezone : "America/New_York");
  const dateFormat = settings.moment ? settings.moment.dateOnlyFormat : "MM/DD/YYYY";

  let [visible, setVisible] = useState(false);
  let [viewCallId, setViewCallId] = useState<string | null>(null);
  let [viewStudentId, setViewStudentId] = useState<string | null>(null);

  console.log("ReportFollowups() - idCsv: ", idCsv);
  let idCsvList = idCsv ? idCsv : "";

  const { data } = useQuery<FollowupsData, CallFollowupsFromIdsVariables>(
    CALL_FOLLOWUPS_FROM_IDS,
    {
      variables: {
        idCsv: idCsvList,
      },
      fetchPolicy: "no-cache",
      skip: idCsvList === "",
    }
  );

  const followups = data ? data.callFollowupsFromIds : undefined;
  const taskPriority = settings.taskPriority;
  const followupColumns = [
    {
      title: "Priority",
      dataIndex: "dateDue",
      key: "priority",
      render: (text: any, row: any) => {
        let days = +text;
        let labelText = taskPriority ? taskPriority.current.labelText : "Current";
        let labelClasses = taskPriority ? taskPriority.current.labelClasses : "font-size-12 badge badge-success";
      
        if (days < 0) {
          labelText = taskPriority ? taskPriority.overdue.labelText : "Overdue";
          labelClasses = taskPriority ? taskPriority.overdue.labelClasses : "font-size-12 badge badge-danger";
                }
        if (days === 0) {
          labelText = taskPriority ? taskPriority.dueToday.labelText : "Due Today";
          labelClasses = taskPriority ? taskPriority.dueToday.labelClasses : "font-size-12 badge badge-warning";
        }
        return <span className={labelClasses}>{labelText}</span>;
      },
      sorter: (a: any, b: any) => DateOnlySorter(a.dateDue, b.dateDue),
    },
    {
      title: "Due Date",
      dataIndex: "dateDue",
      key: "dateDue",
      render: (text: any, row: any) => {
        return <span>{moment(new Date(text)).format(dateFormat)}</span>;
      },
      sorter: (a: any, b: any) => DateOnlySorter(a.dateDue, b.dateDue),
    },
    {
      title: "#",
      dataIndex: "followupNumber",
      key: "followupNumber",
      sorter: (a: any, b: any) =>
        DefaultSorter(a.followupNumber, b.followupNumber),
    },
    {
      title: "Call Type",
      dataIndex: "call.callType.name",
      key: "call.callType.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.call.callType.name, b.call.callType.name),
    },
    {
      title: "Followup Type",
      dataIndex: "followupType.name",
      key: "followupType.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.followupType.name, b.followupType.name),
    },
    {
      title: "Status",
      dataIndex: "followupStatus.name",
      key: "followupStatus.name",
      sorter: (a: any, b: any) =>
        StringSorter(a.followupStatus.name, b.followupStatus.name),
    },
    {
      title: "University",
      dataIndex: "call.student.university.shortName",
      key: "call.student.university.shortName",
      render: (text: any, row: any) => (
        <span>
          {row.call.student.university.shortName}
          {row.call.student.universityCampus
            ? ", " + row.call.student.universityCampus.name
            : ""}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.student.university.shortName +
          (a.call.student.universityCampus
            ? ", " + a.call.student.universityCampus.name
            : "");
        let strB =
          b.call.student.university.shortName +
          (b.call.student.universityCampus
            ? ", " + b.call.student.universityCampus.name
            : "");

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Student",
      dataIndex: "call.student.contact.firstName",
      key: "call.student.contact.firstName",
      render: (text: any, row: any) => (
        <span>
          {row.call.student.contact.firstName}
          {row.call.student.contact.middleName
            ? " " + row.call.student.contact.middleName
            : ""}
          &nbsp;
          {row.call.student.contact.lastName}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.student.contact.firstName +
          (a.call.student.contact.middleName
            ? " " + a.call.student.contact.middleName
            : "") +
          " " +
          a.call.student.contact.lastName;
        let strB =
          b.call.student.contact.firstName +
          (b.call.student.contact.middleName
            ? " " + b.call.student.contact.middleName
            : "") +
          " " +
          b.call.student.contact.lastName;

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Navigator",
      dataIndex: "call.navigatorUser.firstName",
      key: "call.navigatorUser.firstName",
      render: (text: any, row: any) => (
        <span>
          {row.call.navigatorUser.firstName}
          {row.call.navigatorUser.middleName
            ? " " + row.call.navigatorUser.middleName
            : ""}
          &nbsp;
          {row.call.navigatorUser.lastName}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.call.navigatorUser.firstName +
          (a.call.navigatorUser.middleName
            ? " " + a.call.navigatorUser.middleName
            : "") +
          " " +
          a.call.navigatorUser.lastName;
        let strB =
          b.call.navigatorUser.firstName +
          (b.call.navigatorUser.middleName
            ? " " + b.call.navigatorUser.middleName
            : "") +
          " " +
          b.call.navigatorUser.lastName;

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Action",
      dataIndex: "call.callId",
      key: "call.callId",
      render: (text: any, row: any) => (
        <span>
          <Button
            onClick={() => handleViewCall(row.callId+"", row.call.studentId+"")}
            size="small"
            shape="round"
          >
            Open
          </Button>
        </span>
      ),
    },
  ];

  const handleViewCall = (callId: string, studentId: string) => {
    console.log(`handleViewCall() - CallId: ${callId} StudentId: ${studentId}`)
    setViewCallId(callId);
    setViewStudentId(studentId);
    setVisible(true);
  };

  const handleCloseReportFollowups = () => {
    setVisible(false);
    setViewCallId(null);
    setViewStudentId(null);
    setReportTasksIdCsv(null);
  };

  return (
    <div>
      {idCsvList !== "" && (
        <div className="card">
          <div className="card-header card-header-flex">
            <div className="d-flex flex-column justify-content-center mr-auto">
              <h4 className="mb-0">
                <strong>Tasks for {title}</strong>
              </h4>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <Button
                onClick={() => handleCloseReportFollowups()}
                size="small"
                shape="round"
              >
                Close
              </Button>
            </div>
          </div>
          <div className="card-body">
            <div className="air__utils__scrollTable">
              <Table
                rowKey="id"
                columns={followupColumns}
                dataSource={followups}
              />
            </div>
          </div>
        </div>
      )}
      <ViewStudentCallModal
        callId={viewCallId}
        studentId={viewStudentId}
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  );
};
