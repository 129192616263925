import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";

import {
  Home,
  Login,
  MyProfile,
  Logout,
  ForgotPassword,
  ResetPassword,
  NotFound,
  AddressTypeList,
  AddressTypeForm,
  CallResolutionList,
  CallResolutionForm,
  CallStatusList,
  CallStatusForm,
  CallTypeList,
  CallTypeForm,
  CodesDashboard,
  ContactCategoryList,
  ContactCategoryForm,
  ContactMethodForm,
  ContactMethodList,
  ContactTypeList,
  ContactTypeForm,
  CountryList,
  CountryForm,
  MergeDuplicateStudents,
  FollowupResolutionTypeList,
  FollowupResolutionTypeForm,
  FollowupStatusList,
  FollowupStatusForm,
  FollowupTypeList,
  FollowupTypeForm,
  GenderList,
  GenderForm,
  HelpDashboard,
  InsuranceTypeList,
  InsuranceTypeForm,
  LanguageList,
  LanguageForm,
  NoteTypeList,
  NoteTypeForm,
  PasswordExpired,
  RoleList,
  RoleForm,
  StateForm,
  StateList,
  StudentForm,
  StudentTypeList,
  StudentTypeForm,
  UserStatusList,
  UserStatusForm,
  UniversityForm,
  UniversityList,
  VendorForm,
  VendorList,
  UserForm,
  UserList,
  RecentFollowups,
  ReportingDashboard,
  ReportingHistory,
  ReportRunner,
  ReportViewer,
  ReportViewerTasks,
} from "../..";
import { UserContext } from "../../../lib/utils";

export const AppRoutes = () => {
  const { user } = useContext(UserContext);
  // console.log("AppRoutes() - User: ", user);

  if (!user) {
    return (
      <Switch>
        <Route exact path="/system/forgot-password">
          <ForgotPassword title="Forgot Password" />
        </Route>
        <Route exact path="/system/reset-password/:passwordToken">
          <ResetPassword title="Reset Password" />
        </Route>
        <Route>
          <Login title="Login" />
        </Route>
      </Switch>
    );
  }

  if (user.role.code === 'Administrator') {
    return (
      <Switch>
      <Route exact path="/dashboard/:username">
        <CodesDashboard />
      </Route>
      <Route exact path="/">
        <CodesDashboard />
      </Route>
      <Route exact path="/admin/codes">
        <CodesDashboard />
      </Route>
      <Route exact path="/admin/codes/addressTypes">
        <AddressTypeList title="AddressType Codes List" />
      </Route>
      <Route exact path="/admin/codes/addressType/:id">
        <AddressTypeForm title="AddressType Code Form" />
      </Route>
      <Route exact path="/admin/codes/callResolutions">
        <CallResolutionList title="CallResolution Codes List" />
      </Route>
      <Route exact path="/admin/codes/callResolution/:id">
        <CallResolutionForm title="CallResolution Code Form" />
      </Route>
      <Route exact path="/admin/codes/callStatuses">
        <CallStatusList title="CallStatus Codes List" />
      </Route>
      <Route exact path="/admin/codes/callStatus/:id">
        <CallStatusForm title="CallStatus Code Form" />
      </Route>
      <Route exact path="/admin/codes/callTypes">
        <CallTypeList title="CallType Codes List" />
      </Route>
      <Route exact path="/admin/codes/callType/:id">
        <CallTypeForm title="CallType Code Form" />
      </Route>
      <Route exact path="/admin/codes/contactCategories">
        <ContactCategoryList title="ContactCategory Codes List" />
      </Route>
      <Route exact path="/admin/codes/contactCategory/:id">
        <ContactCategoryForm title="ContactCategory Code Form" />
      </Route>
      <Route exact path="/admin/codes/contactMethods">
        <ContactMethodList title="ContactMethod Codes List" />
      </Route>
      <Route exact path="/admin/codes/contactMethod/:id">
        <ContactMethodForm title="ContactMethod Code Form" />
      </Route>
      <Route exact path="/admin/codes/contactTypes">
        <ContactTypeList title="ContactType Codes List" />
      </Route>
      <Route exact path="/admin/codes/contactType/:id">
        <ContactTypeForm title="ContactType Code Form" />
      </Route>
      <Route exact path="/admin/codes/countries">
        <CountryList title="Country Codes List" />
      </Route>
      <Route exact path="/admin/codes/country/:id">
        <CountryForm title="Country Code Form" />
      </Route>
      <Route exact path="/admin/codes/followupResolutionTypes">
        <FollowupResolutionTypeList title="FollowupResolutionType Codes List" />
      </Route>
      <Route exact path="/admin/codes/followupResolutionType/:id">
        <FollowupResolutionTypeForm title="FollowupResolutionType Code Form" />
      </Route>
      <Route exact path="/admin/codes/followupStatuses">
        <FollowupStatusList title="FollowupStatus Codes List" />
      </Route>
      <Route exact path="/admin/codes/followupStatus/:id">
        <FollowupStatusForm title="FollowupStatus Code Form" />
      </Route>
      <Route exact path="/admin/codes/followupTypes">
        <FollowupTypeList title="FollowupType Codes List" />
      </Route>
      <Route exact path="/admin/codes/followupType/:id">
        <FollowupTypeForm title="FollowupType Code Form" />
      </Route>
      <Route exact path="/admin/codes/genders">
        <GenderList title="Gender Codes List" />
      </Route>
      <Route exact path="/admin/codes/gender/:id">
        <GenderForm title="Gender Code Form" />
      </Route>
      <Route exact path="/admin/codes/insuranceTypes">
        <InsuranceTypeList title="InsuranceType Codes List" />
      </Route>
      <Route exact path="/admin/codes/insuranceType/:id">
        <InsuranceTypeForm title="InsuranceType Code Form" />
      </Route>
      <Route exact path="/admin/codes/languages">
        <LanguageList title="Language Codes List" />
      </Route>
      <Route exact path="/admin/codes/language/:id">
        <LanguageForm title="Language Code Form" />
      </Route>
      <Route exact path="/admin/codes/noteTypes">
        <NoteTypeList title="NoteType Codes List" />
      </Route>
      <Route exact path="/admin/codes/noteType/:id">
        <NoteTypeForm title="NoteType Code Form" />
      </Route>
      <Route exact path="/admin/codes/roles">
        <RoleList title="Role Codes List" />
      </Route>
      <Route exact path="/admin/codes/role/:id">
        <RoleForm title="Role Code Form" />
      </Route>
      <Route exact path="/admin/codes/states">
        <StateList title="State Codes List" />
      </Route>
      <Route exact path="/admin/codes/state/:id">
        <StateForm title="State Code Form" />
      </Route>
      <Route exact path="/admin/codes/studentTypes">
        <StudentTypeList title="StudentType Codes List" />
      </Route>
      <Route exact path="/admin/codes/studentType/:id">
        <StudentTypeForm title="StudentType Code Form" />
      </Route>
      <Route exact path="/admin/codes/userStatuses">
        <UserStatusList title="UserStatus Codes List" />
      </Route>
      <Route exact path="/admin/codes/userStatus/:id">
        <UserStatusForm title="UserStatus Code Form" />
      </Route>

      <Route exact path="/admin/app/universities">
        <UniversityList title="University List" />
      </Route>
      <Route exact path="/admin/app/university/:id">
        <UniversityForm title="University Form" />
      </Route>
      <Route exact path="/admin/app/vendors">
        <VendorList title="Vendor List" />
      </Route>
      <Route exact path="/admin/app/vendor/:id">
        <VendorForm title="Vendor Form" />
      </Route>

      <Route exact path="/admin/system/users">
        <UserList title="User List" />
      </Route>
      <Route exact path="/admin/system/user/:id">
        <UserForm title="User Form" />
      </Route>

      <Route exact path="/help">
        <HelpDashboard /*title="Help Dashboard"*/ />
      </Route>

      <Route exact path="/login">
        <Login title="Login" />
      </Route>
      <Route exact path="/system/passwordExpired/:username">
        <PasswordExpired title="Update Password" />
      </Route>
      <Route exact path="/my-profile">
        <MyProfile title="My Profile" />
      </Route>
      <Route exact path="/logout">
        <Logout />
      </Route>

      <Route>
        <NotFound />
      </Route>
    </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/dashboard/:username">
        <Home title="Navigator Dashboard" />
      </Route>
      <Route exact path="/">
        <Home title="Navigator Dashboard" />
      </Route>
      <Route exact path="/student/:id">
        <StudentForm title="Student Record" />
      </Route>
      <Route exact path="/admin/codes">
        <CodesDashboard />
      </Route>
      <Route exact path="/admin/codes/addressTypes">
        <AddressTypeList title="AddressType Codes List" />
      </Route>
      <Route exact path="/admin/codes/addressType/:id">
        <AddressTypeForm title="AddressType Code Form" />
      </Route>
      <Route exact path="/admin/codes/callResolutions">
        <CallResolutionList title="CallResolution Codes List" />
      </Route>
      <Route exact path="/admin/codes/callResolution/:id">
        <CallResolutionForm title="CallResolution Code Form" />
      </Route>
      <Route exact path="/admin/codes/callStatuses">
        <CallStatusList title="CallStatus Codes List" />
      </Route>
      <Route exact path="/admin/codes/callStatus/:id">
        <CallStatusForm title="CallStatus Code Form" />
      </Route>
      <Route exact path="/admin/codes/callTypes">
        <CallTypeList title="CallType Codes List" />
      </Route>
      <Route exact path="/admin/codes/callType/:id">
        <CallTypeForm title="CallType Code Form" />
      </Route>
      <Route exact path="/admin/codes/contactCategories">
        <ContactCategoryList title="ContactCategory Codes List" />
      </Route>
      <Route exact path="/admin/codes/contactCategory/:id">
        <ContactCategoryForm title="ContactCategory Code Form" />
      </Route>
      <Route exact path="/admin/codes/contactMethods">
        <ContactMethodList title="ContactMethod Codes List" />
      </Route>
      <Route exact path="/admin/codes/contactMethod/:id">
        <ContactMethodForm title="ContactMethod Code Form" />
      </Route>
      <Route exact path="/admin/codes/contactTypes">
        <ContactTypeList title="ContactType Codes List" />
      </Route>
      <Route exact path="/admin/codes/contactType/:id">
        <ContactTypeForm title="ContactType Code Form" />
      </Route>
      <Route exact path="/admin/codes/countries">
        <CountryList title="Country Codes List" />
      </Route>
      <Route exact path="/admin/codes/country/:id">
        <CountryForm title="Country Code Form" />
      </Route>
      <Route exact path="/admin/codes/followupResolutionTypes">
        <FollowupResolutionTypeList title="FollowupResolutionType Codes List" />
      </Route>
      <Route exact path="/admin/codes/followupResolutionType/:id">
        <FollowupResolutionTypeForm title="FollowupResolutionType Code Form" />
      </Route>
      <Route exact path="/admin/codes/followupStatuses">
        <FollowupStatusList title="FollowupStatus Codes List" />
      </Route>
      <Route exact path="/admin/codes/followupStatus/:id">
        <FollowupStatusForm title="FollowupStatus Code Form" />
      </Route>
      <Route exact path="/admin/codes/followupTypes">
        <FollowupTypeList title="FollowupType Codes List" />
      </Route>
      <Route exact path="/admin/codes/followupType/:id">
        <FollowupTypeForm title="FollowupType Code Form" />
      </Route>
      <Route exact path="/admin/codes/genders">
        <GenderList title="Gender Codes List" />
      </Route>
      <Route exact path="/admin/codes/gender/:id">
        <GenderForm title="Gender Code Form" />
      </Route>
      <Route exact path="/admin/codes/insuranceTypes">
        <InsuranceTypeList title="InsuranceType Codes List" />
      </Route>
      <Route exact path="/admin/codes/insuranceType/:id">
        <InsuranceTypeForm title="InsuranceType Code Form" />
      </Route>
      <Route exact path="/admin/codes/languages">
        <LanguageList title="Language Codes List" />
      </Route>
      <Route exact path="/admin/codes/language/:id">
        <LanguageForm title="Language Code Form" />
      </Route>
      <Route exact path="/admin/codes/noteTypes">
        <NoteTypeList title="NoteType Codes List" />
      </Route>
      <Route exact path="/admin/codes/noteType/:id">
        <NoteTypeForm title="NoteType Code Form" />
      </Route>
      <Route exact path="/admin/codes/roles">
        <RoleList title="Role Codes List" />
      </Route>
      <Route exact path="/admin/codes/role/:id">
        <RoleForm title="Role Code Form" />
      </Route>
      <Route exact path="/admin/codes/states">
        <StateList title="State Codes List" />
      </Route>
      <Route exact path="/admin/codes/state/:id">
        <StateForm title="State Code Form" />
      </Route>
      <Route exact path="/admin/codes/studentTypes">
        <StudentTypeList title="StudentType Codes List" />
      </Route>
      <Route exact path="/admin/codes/studentType/:id">
        <StudentTypeForm title="StudentType Code Form" />
      </Route>
      <Route exact path="/admin/codes/userStatuses">
        <UserStatusList title="UserStatus Codes List" />
      </Route>
      <Route exact path="/admin/codes/userStatus/:id">
        <UserStatusForm title="UserStatus Code Form" />
      </Route>

      <Route exact path="/admin/app/universities">
        <UniversityList title="University List" />
      </Route>
      <Route exact path="/admin/app/university/:id">
        <UniversityForm title="University Form" />
      </Route>
      <Route exact path="/admin/app/vendors">
        <VendorList title="Vendor List" />
      </Route>
      <Route exact path="/admin/app/vendor/:id">
        <VendorForm title="Vendor Form" />
      </Route>

      <Route exact path="/admin/system/users">
        <UserList title="User List" />
      </Route>
      <Route exact path="/admin/system/user/:id">
        <UserForm title="User Form" />
      </Route>

      <Route exact path="/tools/duplicateStudent/:id/:id2">
        <MergeDuplicateStudents title="Merge Duplicate Students" />
      </Route>
      <Route exact path="/tools/recent-followups">
        <RecentFollowups title="Recent Follow-ups" />
      </Route>

      <Route exact path="/reporting">
        <ReportingDashboard /*title="Reporting Dashboard"*/ />
      </Route>
      <Route exact path="/reporting/history">
        <ReportingHistory /*title="Reporting History"*/ />
      </Route>
      <Route exact path="/reporting/run-report/:id">
        <ReportRunner title="Report" />
      </Route>
      <Route exact path="/reporting/view-report/:id/:src">
        <ReportViewer title="Report" />
      </Route>
      <Route exact path="/reporting/view-task-report/:id/:src">
        <ReportViewerTasks title="Report" />
      </Route>

      <Route exact path="/help">
        <HelpDashboard /*title="Help Dashboard"*/ />
      </Route>

      <Route exact path="/login">
        <Login title="Login" />
      </Route>
      <Route exact path="/system/passwordExpired/:username">
        <PasswordExpired title="Update Password" />
      </Route>
      <Route exact path="/my-profile">
        <MyProfile title="My Profile" />
      </Route>
      <Route exact path="/logout">
        <Logout />
      </Route>

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};
