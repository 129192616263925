import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { message, Button, Form, Icon, Input, Modal, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  UNIVERSITY_CAMPUS,
  UniversityCampus as UniversityCampusData,
  UniversityCampusVariables,
} from "../../../../../../lib/graphql/queries";

import {
  CREATE_UNIVERSITY_CAMPUS,
  CreateUniversityCampus as CreateUniversityCampusData,
  CreateUniversityCampusVariables,
  UPDATE_UNIVERSITY_CAMPUS,
  UpdateUniversityCampus as UpdateUniversityCampusData,
  UpdateUniversityCampusVariables,
} from "../../../../../../lib/graphql/mutations";

interface FormProps {
  id: number;
  universityId: string;
  listRefetch: () => void;
  visible: boolean;
  setVisible: (input: any) => void;
}

interface AddProps {
  universityId: string;
  listRefetch: () => void;
}

interface EditProps {
  id: number;
  universityId: string;
  listRefetch: () => void;
}

const UniversityCampusModalFormBase = ({
  id,
  universityId,
  listRefetch,
  visible,
  setVisible,
  form,
}: FormProps & FormComponentProps) => {
  let isNew = (id === 0);
  const { loading /*, error*/ } = useQuery<
    UniversityCampusData,
    UniversityCampusVariables
  >(UNIVERSITY_CAMPUS, {
    variables: {
      id: `${id}`,
    },
    fetchPolicy: "no-cache",
    skip: isNew,
    onCompleted: (data) => {
      // console.log("UniversityCampusModalFormBase() - Setting Data: ", data);
      if (data) {
        const record = data.universityCampus ? data.universityCampus : null;
        if (record) {
          form.setFieldsValue({
            id: record.id,
            name: record.name,
            description: record.description,
            campusKey: record.campusKey,
          });
        }
      }
    },
    onError: (data) => {
      console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
      message.error(`Failed to fetch the record.`);
    },
  });

  const [createUniversityCampus, { loading: createRunning }] = useMutation<
    CreateUniversityCampusData,
    CreateUniversityCampusVariables
  >(CREATE_UNIVERSITY_CAMPUS, {
    onCompleted: (data) => {
      message.success("Campus added successfully.");

      form.resetFields();
      setVisible(false);

      listRefetch();
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update the Login ID!");
    },
  });

  const [updateUniversityCampus, { loading: updateRunning }] = useMutation<
    UpdateUniversityCampusData,
    UpdateUniversityCampusVariables
  >(UPDATE_UNIVERSITY_CAMPUS, {
    onCompleted: (data) => {
      message.success("Campus added successfully.");

      form.resetFields();
      setVisible(false);

      listRefetch();
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update the campus!");
    },
  });

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      const campus = {
        universityId: +universityId,
        campusKey: values.campusKey,
        name: values.name,
        description: values.description,
        isDefault: false,
      };

      if (id) {
        delete campus.universityId;

        updateUniversityCampus({
          variables: {
            id: `${id}`,
            data: campus,
          },
        });
      } else {
        createUniversityCampus({
          variables: {
            data: campus,
          },
        });
      }
    });
  };

  return (
    <Modal
      visible={visible}
      title={isNew ? "Add a University Campus" : "Update a University Campus"}
      okText={isNew ? "Create" : "Update"}
      onCancel={handleCancel}
      onOk={handleSubmit}
    >
      <Spin spinning={loading || createRunning || updateRunning}>
        <Form layout="vertical">
          <Form.Item label="Campus Name">
            {form.getFieldDecorator("name", {
              rules: [
                {
                  required: true,
                  max: 100,
                  message: "Please enter the name of the Campus.",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Campus Description">
            {form.getFieldDecorator("description", {
              rules: [
                {
                  required: true,
                  max: 200,
                  message: "Please enter the name of the Campus.",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Campus Suffix">
            {form.getFieldDecorator("campusKey", {
              rules: [
                {
                  required: true,
                  min: 3,
                  max: 3,
                  message: "Enter a 3 digit Campus Suffix",
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            {form.getFieldDecorator("id")(<Input type="hidden" />)}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export const UniversityCampusModalForm = Form.create<
  FormProps & FormComponentProps
>({
  name: "admin_university_campus_form",
})(UniversityCampusModalFormBase);

export const EditUniversityCampusIcon = ({
  id,
  universityId,
  listRefetch
}: EditProps) => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Icon type="edit" onClick={() => showModal()} />
      {visible && (
        <UniversityCampusModalForm
          id={id}
          universityId={universityId}
          listRefetch={listRefetch}
          visible={visible}
          setVisible={setVisible}
        />
      )}
    </div>
  );
};

export const AddUniversityCampusButton = ({
  universityId,
  listRefetch
}: AddProps) => {
  let [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  return (
    <div style={{ display: "inline-block" }}>
      <Button onClick={() => showModal()} size="small" shape="round">
        Add
      </Button>
      {visible && (
      <UniversityCampusModalForm
        id={0}
        universityId={universityId}
        listRefetch={listRefetch}
        visible={visible}
        setVisible={setVisible}
      />
      )} 
    </div>
  );
};
