import { gql } from "apollo-boost";

export const ROLE = gql`
  query Role($id: String!) {
    role(id: $id) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
