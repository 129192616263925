import { gql } from "apollo-boost";

export const REPORT_RESULTS = gql`
  query ReportResults($userId: String, $includeDeleted: Boolean) {
    reportResults(userId: $userId, includeDeleted: $includeDeleted) {
      id
      reportProfileId
      runByUser {
        firstName
        lastName
      }
      runAt
      title
      heading
      description
      params
      paramsDescription
      viewerRoute
      resultCount
    }
  }
`;
