import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { CodesSkeleton, CodeListManager } from "../../components";

import { FOLLOWUP_TYPES, FollowupTypes as FollowupTypesData } from "../../../../lib/graphql/queries";
import {
  DELETE_FOLLOWUP_TYPE,
  DeleteFollowupType as DeleteFollowupTypeData,
  DeleteFollowupTypeVariables,
  UNDELETE_FOLLOWUP_TYPE,
  UndeleteFollowupType as UndeleteFollowupTypeData,
  UndeleteFollowupTypeVariables,
} from "../../../../lib/graphql/mutations/codes";

import "../../styles/admin.css";

interface Props {
  title: string;
}

export const FollowupTypeList = ({ title }: Props) => {
  const codeType = "FollowupType";
  const codeTypePlural = "FollowupTypes";
  const canAddCodes = true;

  const { data, loading, error, refetch } = useQuery<FollowupTypesData>(FOLLOWUP_TYPES, {
    variables: {
      includeDeleted: true,
    },
	  fetchPolicy: "no-cache"
  });
  const codes = data ? data.followupTypes : undefined;

  const [
    deleteCode,
    { loading: deleteRunning },
  ] = useMutation<DeleteFollowupTypeData, DeleteFollowupTypeVariables>(DELETE_FOLLOWUP_TYPE, {
    onCompleted: (data) => {message.success("Record successfully deleted."); refetch()},
    onError: (data) => {message.error("Failed to delete record!");},
  });

  const [
    undeleteCode,
    { loading: undeleteRunning },
  ] = useMutation<UndeleteFollowupTypeData, UndeleteFollowupTypeVariables>(UNDELETE_FOLLOWUP_TYPE, {
    onCompleted: (data) => {message.success("Record successfully restored."); refetch()},
    onError: (data) => {message.error("Failed to restore record!");},
  });

  if (loading) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} error />
      </div>
    );
  }

  return (
    <CodeListManager 
      codeType={codeType} 
      codeTypePlural={codeTypePlural} 
      canAddCodes={canAddCodes}
      codes={codes}
      deleteRunning={deleteRunning}
      undeleteRunning={undeleteRunning}
      deleteCode={deleteCode}
      undeleteCode={undeleteCode}
    />
  );
};
