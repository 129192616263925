import { gql } from "apollo-boost";

export const VENDORS = gql`
  query Vendors($includeDeleted: Boolean) {
    vendors(includeDeleted: $includeDeleted) {
      id
      name
      shortName
      avatar
      coverageStartDate
      coverageTerminationDate
      isDeleted
    }
  }
`;
