import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Alert, Button, Form, Input, Select, Spin, Table, message } from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment-timezone";

import { DateOnlySorter, StringSorter } from "../../../../lib/utils";

import { AddStudentButton } from "../AddStudent";

import {
  SEARCH_STUDENTS,
  SearchStudents as LookupResults,
  SearchStudentsVariables as LookupVariables,
  UNIVERSITIES,
  Universities as UniversitiesData,
} from "../../../../lib/graphql/queries";

interface Props {
  title: string;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

export const StudentLookupBase = ({
  title,
  form,
}: Props & FormComponentProps) => {
  const { Option } = Select;
  const history = useHistory();
  const studentUrl = "/student";

  let [students, setStudents] = useState<any>([]);
  let [studentsFound, setStudentsFound] = useState(false);

  const { data: universitiesData } = useQuery<UniversitiesData>(UNIVERSITIES, {
    fetchPolicy: "no-cache",
  });

  const [lookupStudents, { loading: lookupRunning }] = useLazyQuery<
    LookupResults,
    LookupVariables
  >(SEARCH_STUDENTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data && data.searchStudents) {
        setStudents(data.searchStudents);
      } else {
        setStudents([]);
      }
      setStudentsFound(true);
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Student lookup failed!");
    },
  });

  const handleSearch = (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("No search terms provided.");
        return;
      }

      let input = {
        universityId: values.universityId ? +values.universityId : 0,
        universityCampusId: 0,
        searchTerms: values.searchTerms,
        orderBy: "",
      };

      lookupStudents({ variables: input });
    });
  };

  const handleOpenStudent = (id: string) => {
    history.push(`${studentUrl}/${id}`);
  };

  const handleClearSearch = () => {
    setStudentsFound(false);
  };

  const studentColumns = [
    {
      title: "University",
      dataIndex: "university.shortName",
      key: "university.shortName",
      render: (text: any, row: any) => (
        <span>
          {row.university.shortName}
          {row.universityCampus ? ", " + row.universityCampus.name : ""}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.university.shortName +
          (a.universityCampus ? ", " + a.universityCampus.name : "");
        let strB =
          b.university.shortName +
          (b.universityCampus ? ", " + b.universityCampus.name : "");

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Student ID",
      dataIndex: "studentIdentifier",
      key: "studentIdentifier",
      sorter: (a: any, b: any) =>
        StringSorter(a.studentIdentifier, b.studentIdentifier),
    },
    {
      title: "Last Name",
      dataIndex: "contact.lastName",
      key: "contact.lastName",
      sorter: (a: any, b: any) =>
        StringSorter(a.contact.lastName, b.contact.lastName),
    },
    {
      title: "First Name",
      dataIndex: "contact.firstName",
      key: "contact.firstName",
      sorter: (a: any, b: any) =>
        StringSorter(a.contact.firstName, b.contact.firstName),
    },
    {
      title: "Middle Name",
      dataIndex: "contact.middleName",
      key: "contact.middleName",
    },
    {
      title: "Date Of Birth",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (text: any, row: any) => (
        <span>
          {text
            ? moment(new Date(text)).format(dateFormat)
            : "-- not defined --"}
        </span>
      ),
      sorter: (a: any, b: any) => DateOnlySorter(a.dateOfBirth, b.dateOfBirth),
    },
    {
      title: "Phone Number",
      dataIndex: "contact.primaryPhone",
      key: "contact.primaryPhone",
      sorter: (a: any, b: any) =>
        StringSorter(a.contact.primaryPhone, b.contact.primaryPhone),
    },
    {
      title: "Email",
      dataIndex: "contact.email",
      key: "contact.email",
      sorter: (a: any, b: any) =>
        StringSorter(a.contact.email, b.contact.email),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any) => (
        <span>
          <Button
            onClick={() => handleOpenStudent(text)}
            size="small"
            shape="round"
          >
            Open
          </Button>
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h4 className="mb-4">
            <strong>Student Lookup</strong>
          </h4>
          <Form labelAlign="left" colon={false}>
            <div className="row">
              <div className="col-md-3">
                <Form.Item label="University">
                  {form.getFieldDecorator("universityId")(
                    <Select
                      placeholder="Select the students university"
                      allowClear={true}
                    >
                      {universitiesData &&
                        universitiesData.universities.map((x) => {
                          return (
                            <Option key={`${x.id}`} value={`${x.id}`}>
                              {x.name}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </div>
              <div className="col-md-6">
                <Form.Item label="Student Information">
                  {form.getFieldDecorator("searchTerms", {
                    rules: [
                      {
                        required: true,
                        min: 3,
                        whitespace: false,
                        message: "Please input student information for lookup",
                      },
                    ],
                  })(
                    <Input
                      placeholder="Enter a student's name, id, phone or email"
                      onPressEnter={handleSearch}
                    />
                  )}
                </Form.Item>
              </div>
              <div className="col-md-1">&nbsp;</div>
              <div className="col-md-2">
                <Form.Item label=" ">
                  {form.getFieldDecorator("confirm4")(
                    <button
                      type="button"
                      className="btn btn-light px-5"
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  )}
                </Form.Item>
              </div>
            </div>
          </Form>

          <div>
            {lookupRunning && (
              <Spin spinning={lookupRunning}>
                <Alert message="Searching for students" type="info" />
              </Spin>
            )}
            {studentsFound && !lookupRunning && (
              <div>
                <div className="card-header card-header-flex">
                  <div className="d-flex flex-column justify-content-center mr-auto">
                    <h4 className="mb-0">
                      <strong>Search results</strong>
                      <div
                        style={{ marginLeft: "15px", display: "inline-block" }}
                      >
                        <Button
                          onClick={() => handleClearSearch()}
                          size="small"
                          shape="round"
                        >
                          clear
                        </Button>
                      </div>
                    </h4>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span>
                      <AddStudentButton />
                    </span>
                  </div>
                </div>

                <div className="card-body">
                  <div className="air__utils__scrollTable">
                    <Table
                      rowKey="id"
                      columns={studentColumns}
                      dataSource={students}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const StudentLookup = Form.create<Props & FormComponentProps>({
  name: "student_lookup_form",
})(StudentLookupBase);
