import { gql } from "apollo-boost";
 
export const UPDATE_CALL_NOTE = gql`
  mutation UpdateCallNote($id: String!, $data: UpdateCallNoteInput!) {
    updateCallNote(id: $id, data: $data) {
        id
        noteTypeId
        noteType {
          code
          name
        }
        updatedAt
        updatedByUserId
        updatedByUser {
          firstName
          middleName
          lastName
        }
        note
    }
  }
`;
