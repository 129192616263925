import { gql } from "apollo-boost";

export const USER = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      userStatusId
      userStatus{
        id
        code
        name
      }
      roleId
      role{
        id
        code
        name
      }
      email
      altEmail
      firstName
      middleName
      lastName
      primaryPhone
      canSmsPrimaryPhone
      altPhone
      canSmsAltPhone
      avatar
      userCredential{
        id
        username
        lastLoginAt
      }
      isDeleted
    }
  }
`;
