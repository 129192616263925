import moment from "moment-timezone";

moment.tz.setDefault("America/New_York");
const dateTruncateFormat = "DD-MMM-YYYY";

/**
 * @param {moment.MomentInput} dateA - a date, represented in moment compatible format
 * @param {moment.MomentInput} dateB - a date, represented in moment compatible format
 */
export const DateSorter = (
  dateA: moment.MomentInput,
  dateB: moment.MomentInput
) => {
  if (dateA && dateB) {
    return moment(dateA).diff(moment(dateB));
  } else if (dateA === dateB) {
    return 0;
  } else if (dateA === null) {
    return -1;
  } else {
    return 1;
  }
};

/**
 * @param {moment.MomentInput} dateA - a date, represented in moment compatible format
 * @param {moment.MomentInput} dateB - a date, represented in moment compatible format
 */
export const DateOnlySorter = (
  dateA: moment.MomentInput,
  dateB: moment.MomentInput
) => {
  if (dateA && dateB) {
    return moment(moment(dateA).format(dateTruncateFormat)).diff(
      moment(moment(dateB).format(dateTruncateFormat))
    );
  } else if (dateA === dateB) {
    return 0;
  } else if (dateA === null) {
    return -1;
  } else {
    return 1;
  }
};

/**
 * @param {string} strA - a string
 * @param {string} strB - a string
 */
export const StringSorter = (strA: string, strB: string) => {
  if (strA && strB) {
    return strA.localeCompare(strB);
  } else if (strA === strB) {
    return 0;
  } else if (strA === null) {
    return -1;
  } else {
    return 1;
  }
};

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
export const DefaultSorter = (a: number, b: number) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

export const DefaultSorterDesc = (a: number, b: number) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

export const IdSorter = (a: any, b: any) => {
  if (+a.id > +b.id) return 1;
  if (+a.id < +b.id) return -1;
  return 0;
};

export const IdSorterDesc = (a: any, b: any) => {
  if (+a.id > +b.id) return -1;
  if (+a.id < +b.id) return 1;
  return 0;
};
