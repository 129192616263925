import { gql } from "apollo-boost";

export const STUDENT_VENDOR_INTERACTIONS = gql`
  query StudentVendorInteractions($studentId: String) {
    studentVendorInteractions(studentId: $studentId) {
      id
      studentId
      caseIdentifier
      referredBy
      category
      subCategory
      studentIdentifier
      condition
      description
      caseStatus
      casePriority
      caller
      dateOpened
      dateClosed
      caseSource
      reasonCaseClosed
      universityName
      studentName
      callerName
      studentDateOfBirth
      clinicalInformation
      riskCode
      treatment
      attendees
      presentingProblem
      isMemberWorried
      worriedDetails
      isMemberHarmful
      isHarmfulToSelf
      isHarmfulToOthers
      harmfulDetails
      isMemberConcerned
      isConcernedAboutDrugs
      isConcernedAboutAlcohol
      concernDetails
      clinicalImpressions
      sessionType
      sessionDate
      sessionTime
      method
      duration
      counselor
      caseResolution
      locationState
      locationPhone
      locationCity
      affiliateName
      lastChangeAt
      unmapped
    }
  }
`;
