import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import { CodesSkeleton, CodeListManager } from "../../components";

import {
  STUDENT_TYPES,
  StudentTypes as StudentTypesData,
} from "../../../../lib/graphql/queries";
import {
  DELETE_STUDENT_TYPE,
  DeleteStudentType as DeleteStudentTypeData,
  DeleteStudentTypeVariables,
  UNDELETE_STUDENT_TYPE,
  UndeleteStudentType as UndeleteStudentTypeData,
  UndeleteStudentTypeVariables,
} from "../../../../lib/graphql/mutations/codes";

import "../../styles/admin.css";

interface Props {
  title: string;
}

export const StudentTypeList = ({ title }: Props) => {
  const codeType = "StudentType";
  const codeTypePlural = "StudentTypes";
  const canAddCodes = true;

  const { data, loading, error, refetch } = useQuery<StudentTypesData>(
    STUDENT_TYPES,
    {
      variables: {
        includeDeleted: true,
      },
      fetchPolicy: "no-cache",
    }
  );
  const codes = data ? data.studentTypes : undefined;

  const [deleteCode, { loading: deleteRunning }] = useMutation<
    DeleteStudentTypeData,
    DeleteStudentTypeVariables
  >(DELETE_STUDENT_TYPE, {
    onCompleted: (data) => {
      message.success("Record successfully deleted.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to delete record!");
    },
  });

  const [undeleteCode, { loading: undeleteRunning }] = useMutation<
    UndeleteStudentTypeData,
    UndeleteStudentTypeVariables
  >(UNDELETE_STUDENT_TYPE, {
    onCompleted: (data) => {
      message.success("Record successfully restored.");
      refetch();
    },
    onError: (data) => {
      message.error("Failed to restore record!");
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <CodesSkeleton title={title} error />
      </div>
    );
  }

  return (
    <CodeListManager
      codeType={codeType}
      codeTypePlural={codeTypePlural}
      canAddCodes={canAddCodes}
      codes={codes}
      deleteRunning={deleteRunning}
      undeleteRunning={undeleteRunning}
      deleteCode={deleteCode}
      undeleteCode={undeleteCode}
    />
  );
};
