import { gql } from "apollo-boost";

export const USERS = gql`
  query Users($navigatorsOnly: Boolean, $includeDeleted: Boolean) {
    users(navigatorsOnly: $navigatorsOnly, includeDeleted: $includeDeleted) {
      id
      userStatusId
      userStatus{
        id
        name
      }
      roleId
      role{
        id
        name
      }
      email
      firstName
      middleName
      lastName
      primaryPhone
      avatar
      userCredential{
        id
        username
        lastLoginAt
      }
      isDeleted
    }
  }
`;
