import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { message, Form } from "antd";
import { FormComponentProps } from "antd/lib/form";

import {
  FOLLOWUP_RESOLUTION_TYPE,
  FollowupResolutionType as FollowupResolutionTypeData,
  FollowupResolutionTypeVariables,
} from "../../../../lib/graphql/queries";
import {
  CREATE_FOLLOWUP_RESOLUTION_TYPE,
  CreateFollowupResolutionType as CreateFollowupResolutionTypeData,
  CreateFollowupResolutionTypeVariables,
  UPDATE_FOLLOWUP_RESOLUTION_TYPE,
  UpdateFollowupResolutionType as UpdateFollowupResolutionTypeData,
  UpdateFollowupResolutionTypeVariables,
} from "../../../../lib/graphql/mutations/codes";
import { CodeForm, SetCodeFormValues } from "../../components"

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

export const FollowupResolutionTypeFormBase = ({ title, form }: Props & FormComponentProps) => {
  const codeType = "FollowupResolutionType";
  const codeTypePlural = "FollowupResolutionTypes";
  const { id } = useParams<MatchParams>();

  const { loading, error } = useQuery<FollowupResolutionTypeData, FollowupResolutionTypeVariables>(
    FOLLOWUP_RESOLUTION_TYPE,
    {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
      skip: id === "0",
      onCompleted: (data) => {
        if(!data)return;

        SetCodeFormValues(form, data.followupResolutionType);
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  const [createCode, { loading: createRunning }] = useMutation<
    CreateFollowupResolutionTypeData,
    CreateFollowupResolutionTypeVariables
  >(CREATE_FOLLOWUP_RESOLUTION_TYPE, {
    onCompleted: (data) => {
      message.success("Record successfully created.");
    },
    onError: (data) => {
      console.log(`Create error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to create record!");
    },
  });

  const [updateCode, { loading: updateRunning }] = useMutation<
    UpdateFollowupResolutionTypeData,
    UpdateFollowupResolutionTypeVariables
  >(UPDATE_FOLLOWUP_RESOLUTION_TYPE, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
    },
  });

  return (
    <CodeForm
      form={form} 
      title={title} 
      codeType={codeType} 
      codeTypePlural={codeTypePlural}
      isNew={(id === '0')}
      codeMaxLength={2}
      hasDescription={false}
      queryLoading={loading} 
      queryError={error} 
      createCode={createCode} 
      createRunning={createRunning} 
      updateCode={updateCode} 
      updateRunning={updateRunning}
    />
  );
};

export const FollowupResolutionTypeForm = Form.create<Props & FormComponentProps>({
  name: "admin_code_followup_resolution_type_form",
})(FollowupResolutionTypeFormBase);
