import React, { useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import {
  message,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Upload,
  Icon,
} from "antd";
import { FormComponentProps } from "antd/lib/form";
import moment from "moment-timezone";

import { MomentToDateOrNull } from "../../../../lib/utils";
import { UniversityFormSkeleton } from "../UniversityFormSkeleton";
import { UniversityCampusList } from "./components";
import {
  AddAdditionalContactButton,
  /*EditAdditionalContactIcon,*/
} from "../../components";
import { STATES, States as StatesData } from "../../../../lib/graphql/queries";
import {
  UNIVERSITY,
  University as UniversityData,
  UniversityVariables,
} from "../../../../lib/graphql/queries";
import {
  CREATE_UNIVERSITY,
  CreateUniversity as CreateUniversityData,
  CreateUniversityVariables,
  UPDATE_UNIVERSITY,
  UpdateUniversity as UpdateUniversityData,
  UpdateUniversityVariables,
} from "../../../../lib/graphql/mutations/app";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../../lib/utils";

interface MatchParams {
  id: string;
}
interface Props {
  title: string;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY";

export const UniversityFormBase = ({
  title,
  form,
}: Props & FormComponentProps) => {
  const { id } = useParams<MatchParams>();
  const [universityId, setUniversityId] = useState("0");
  const history = useHistory();
  const { Option } = Select;

  const listUrl = `/admin/app/universities`;
  let isNew = id === "0";
  let showUpload = !isNew;
  let [refreshTs, setRefreshTs] = useState<number>(new Date().valueOf());

  const { data: statesData } = useQuery<StatesData>(STATES, {
    fetchPolicy: "no-cache",
  });

  const { loading, error } = useQuery<UniversityData, UniversityVariables>(
    UNIVERSITY,
    {
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
      skip: isNew,
      onCompleted: (data) => {
        // console.log("UniversityFormBase() - Setting Data: ", data);
        if (data) {
          const record = data.university ? data.university : null;
          if (record) {
            setUniversityId(record.id);

            form.setFieldsValue({
              id: record.id,
              universityName: record.name,
              universityShortName: record.shortName,
              universityKey: record.universityKey,
              avatar: record.avatar,
              coverageStartMoment: record.coverageStartDate
                ? moment(record.coverageStartDate)
                : null,
              coverageEndMoment: record.coverageTerminationDate
                ? moment(record.coverageTerminationDate)
                : null,

              firstName: record.contact.firstName,
              middleName: record.contact.middleName,
              lastName: record.contact.lastName,
              namePrefix: record.contact.namePrefix,
              nameSuffix: record.contact.nameSuffix,
              email: record.contact.email,
              altEmail: record.contact.altEmail,
              primaryPhone: record.contact.primaryPhone,
              // canSmsPrimaryPhone: record.contact.canSmsPrimaryPhone,
              altPhone: record.contact.altPhone,
              // canSmsAltPhone: record.contact.canSmsAltPhone,
              // addressTypeId: `${record.contact.addressTypeId}`,
              // countryId: `${record.contact.countryId}`,
              addressLine1: record.contact.addressLine1,
              addressLine2: record.contact.addressLine2,
              city: record.contact.city,
              stateId: `${record.contact.stateId}`,
              // province: record.contact.province,
              zipCode: record.contact.zipCode,
              // freeFormAddress: record.contact.freeFormAddress,
            });
          }
        }
      },
      onError: (data) => {
        console.log(`Query error: ${JSON.stringify(data, null, 2)}`);
        message.error(`Failed to fetch the record.`);
      },
    }
  );

  const [createUniversity, { loading: createRunning }] = useMutation<
    CreateUniversityData,
    CreateUniversityVariables
  >(CREATE_UNIVERSITY, {
    onCompleted: (data) => {
      message.success("Record successfully created.");
    },
    onError: (data) => {
      console.log(`Create error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to create record!");
    },
  });

  const [updateUniversity, { loading: updateRunning }] = useMutation<
    UpdateUniversityData,
    UpdateUniversityVariables
  >(UPDATE_UNIVERSITY, {
    onCompleted: (data) => {
      message.success("Record successfully updated.");
    },
    onError: (data) => {
      console.log(`Update error: ${JSON.stringify(data, null, 2)}`);
      message.error("Failed to update record!");
    },
  });

  if (loading) {
    return (
      <div className="admin">
        <UniversityFormSkeleton title={title} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin">
        <UniversityFormSkeleton title={title} error />
      </div>
    );
  }

  const gotoList = () => {
    history.push(listUrl);
  };

  const handleCancel = (e: any) => {
    message.info("No changes saved.");
    gotoList();
  };

  const handleSave = async (e: any) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (err) {
        message.error("Please complete all required form fields!");
        return;
      }

      // build contact input
      const contact = {
        ...values,
      };
      // Remove Vendor fields
      delete contact.id;
      delete contact.universityName;
      delete contact.universityShortName;
      delete contact.universityKey;
      delete contact.avatar;
      delete contact.coverageStartMoment;
      delete contact.coverageEndMoment;

      // Convert stateId to number
      contact.stateId = +contact.stateId;

      // Build vendor inout
      const university = {
        name: values.universityName,
        shortName: values.universityShortName,
        universityKey: values.universityKey,
        avatar: values.avatar,
        coverageStartDate: MomentToDateOrNull(values.coverageStartMoment),
        coverageTerminationDate: MomentToDateOrNull(values.coverageEndMoment),
        contact: contact,
      };

      if (values.id) {
        await updateUniversity({
          variables: {
            id: values.id,
            data: university,
          },
        });
      } else {
        await createUniversity({
          variables: {
            data: university,
          },
        });
      }

      gotoList();
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const uploadProps = {
    accept: "image/jpeg",
    action: `/upload/avatar?filename=university-${id}.jpg`,
    name: `data_input`,
    withCredentials: true,
    beforeUpload(file: any) {
      const isJpg = file.type === "image/jpeg";
      if (!isJpg) {
        message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      return isJpg && isLt2M;
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setRefreshTs(new Date().valueOf());
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <div>
          <Spin spinning={createRunning || updateRunning}>
            <div>
              <Helmet
                title={`Manage Universities&nbsp;:&nbsp;${
                  isNew ? "<new>" : form.getFieldValue("universityName")
                }`}
              />
              <div className="air__utils__heading">
                <h5>
                  <Link to={listUrl}>Manage Universities</Link>&nbsp;:&nbsp;
                  {isNew ? "<new>" : form.getFieldValue("universityName")}
                </h5>
              </div>

              <div className="row">
                <div className="col-xl-4 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex flex-wrap flex-column align-items-center">
                        <div className="air__utils__avatar air__utils__avatar--size64 mb-3">
                          <img src={`/avatar/university/${id}?_ts=${refreshTs}`} alt="avatar" />
                        </div>
                        <div className="text-center">
                          <div className="text-dark font-weight-bold font-size-18">
                            {form.getFieldValue("universityName") || "<new>"}
                          </div>
                          <div className="text-dark font-weight-bold font-size-18">
                            {form.getFieldValue("roleName")}
                          </div>
                        </div>
                        <div>&nbsp;</div>
                        {showUpload && (
                          <div>
                            <Upload {...uploadProps}>
                              <Button>
                                <Icon type="upload" />
                                Click to Upload a 128x128 JPG Image
                              </Button>
                            </Upload>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {!isNew && (
                    <UniversityCampusList universityId={universityId} />
                  )}

                  {!isNew && (
                    <div className="card">
                      <div className="card-body">
                        <div>
                          <b>Additional contacts</b>
                          <div
                            style={{
                              display: "inline-block",
                              float: "right",
                            }}
                          >
                            <AddAdditionalContactButton
                              parentId={universityId}
                            />
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xl-8 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="mb-4">
                        <strong>University Form</strong>
                      </h4>
                      <Form {...formItemLayout} labelAlign="left">
                        <div className="row">
                          <div className="col-md-8">
                            <Form.Item label="University Name">
                              {form.getFieldDecorator("universityName", {
                                rules: [{ required: true, max: 55 }],
                              })(<Input placeholder="University Name" />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-4">&nbsp;</div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <Form.Item label="University Short Name">
                              {form.getFieldDecorator("universityShortName", {
                                rules: [{ required: true, max: 200 }],
                              })(<Input placeholder="University Short name" />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-4">&nbsp;</div>
                        </div>
                        <div className="row">
                          <div className="col-md-8">
                            <Form.Item label="Group #">
                              {form.getFieldDecorator("universityKey", {
                                rules: [{ required: true, max: 6 }],
                              })(<Input placeholder="Group #" />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-4">&nbsp;</div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item label="Coverage Start">
                              {form.getFieldDecorator("coverageStartMoment", {
                                rules: [{ required: true }],
                              })(<DatePicker format={dateFormat} />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Coverage End">
                              {form.getFieldDecorator("coverageEndMoment")(
                                <DatePicker format={dateFormat} />
                              )}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="row">
                          <div className="border-top col-md-12">
                            <strong>
                              <u>University Contact Information</u>
                            </strong>
                          </div>
                          <div className="col-md-4">
                            <Form.Item label="First Name">
                              {form.getFieldDecorator("firstName", {
                                rules: [{ max: 100 }],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <Form.Item label="Middle">
                              {form.getFieldDecorator("middleName", {
                                rules: [{ max: 100 }],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <Form.Item label="Last Name">
                              {form.getFieldDecorator("lastName", {
                                rules: [{ max: 100 }],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Primary Phone">
                              {form.getFieldDecorator("primaryPhone", {
                                rules: [{ max: 20 }],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Alternate Phone">
                              {form.getFieldDecorator("altPhone", {
                                rules: [{ max: 20 }],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Email">
                              {form.getFieldDecorator("email", {
                                rules: [
                                  {
                                    max: 100,
                                    type: "email",
                                    message: "The input is not valid E-mail!",
                                  },
                                  {
                                    message: "Please input your E-mail!",
                                  },
                                ],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Alternate Email">
                              {form.getFieldDecorator("altEmail", {
                                rules: [
                                  {
                                    max: 100,
                                    type: "email",
                                    message: "The input is not valid Email!",
                                  },
                                  {
                                    message: "Please input your Email!",
                                  },
                                ],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                        </div>
                        <div className="row">
                          <div className="border-top col-md-12">
                            <strong>
                              <u>Vendor Address</u>
                            </strong>
                          </div>
                          <div className="col-md-6">
                            <Form.Item label="Address">
                              {form.getFieldDecorator("addressLine1", {
                                rules: [{ max: 100 }],
                              })(<Input placeholder="1234 Main St." />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-6">&nbsp;</div>
                          <div className="col-md-6">
                            <Form.Item label="Address 2">
                              {form.getFieldDecorator("addressLine2", {
                                rules: [{ max: 100 }],
                              })(
                                <Input placeholder="Apartment, studio, or floor" />
                              )}
                            </Form.Item>
                          </div>
                          <div className="col-md-6">&nbsp;</div>
                          <div className="col-md-6">
                            <Form.Item label="City">
                              {form.getFieldDecorator("city", {
                                rules: [{ max: 100 }],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                          <div className="col-md-4">
                            <Form.Item label="State">
                              {form.getFieldDecorator("stateId", {
                                rules: [
                                  { message: "Please select your state!" },
                                ],
                              })(
                                <Select placeholder="Select a state">
                                  {statesData &&
                                    statesData.states.map((x) => {
                                      return (
                                        <Option
                                          key={`${x.id}`}
                                          value={`${x.id}`}
                                        >
                                          {x.name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              )}
                            </Form.Item>
                          </div>
                          <div className="col-md-2">
                            <Form.Item label="Zip">
                              {form.getFieldDecorator("zipCode", {
                                rules: [{ max: 20 }],
                              })(<Input />)}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-3">
                            <div className="col-md-3">
                              <Form.Item>
                                {form.getFieldDecorator("id")(
                                  <Input type="hidden" />
                                )}
                              </Form.Item>
                            </div>
                            <Form.Item>
                              {form.getFieldDecorator("avatar")(
                                <Input type="hidden" />
                              )}
                            </Form.Item>
                          </div>
                          <div className="col-md-3">
                            <Form.Item>
                              {form.getFieldDecorator("coverageStartMomnent")(
                                <Input type="hidden" />
                              )}
                            </Form.Item>
                          </div>
                          <div className="col-md-3">
                            <Form.Item>
                              {form.getFieldDecorator("coverageEndMomnent")(
                                <Input type="hidden" />
                              )}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-actions col-md-12">
                            <Button
                              style={{ width: 140 }}
                              type="primary"
                              onClick={handleSave}
                              className="mr-3"
                            >
                              {isNew ? "Create" : "Update"}
                            </Button>

                            <Button onClick={handleCancel}>Cancel</Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(hasAuthRole("Navigator"), hasAuthRole("AccountManager"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};

export const UniversityForm = Form.create<Props & FormComponentProps>({
  name: "admin_university_form",
})(UniversityFormBase);
