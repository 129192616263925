import { gql } from "apollo-boost";
 
export const FOLLOWUP_STATUSES = gql`
  query FollowupStatuses($includeDeleted: Boolean) {
    followupStatuses(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
