import { gql } from "apollo-boost";
 
export const CALL_STATUSES = gql`
  query CallStatuses($includeDeleted: Boolean) {
    callStatuses(includeDeleted: $includeDeleted) {
      id
      code
      name
      description
      isDefault
      isSystemCode
      isDeleted
    }
  }
`;
