import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Helmet } from "react-helmet";
import { message, Button, Layout, Popconfirm, Spin, Table } from "antd";
import moment from "moment-timezone";

import { StringSorter, DateSorter } from "../../../../lib/utils";
import { UserListSkeleton } from "../UserListSkeleton";
import { USERS, Users as UsersData } from "../../../../lib/graphql/queries";
import {
  DELETE_USER,
  DeleteUser as DeleteUserData,
  DeleteUserVariables,
  UNDELETE_USER,
  UndeleteUser as UndeleteUserData,
  UndeleteUserVariables,
  DISABLE_USER,
  DisableUser as DisableUserData,
  DisableUserVariables,
  ENABLE_USER,
  EnableUser as EnableUserData,
  EnableUserVariables,
} from "../../../../lib/graphql/mutations/system";
import { UserStatusEnum } from "../../../../lib/utils";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../../lib/utils";
import "../../styles/admin.css";

const { Content } = Layout;

interface Props {
  title: string;
}

interface PopEditUserProps {
  id: string;
  userStatusId: number;
  handleEditUser: (id: string) => Promise<void>;
  handleDisableUser: (id: string) => Promise<void>;
  handleEnableUser: (id: string) => Promise<void>;
  handleDeleteUser: (id: string) => Promise<void>;
}

interface PopUndeleteUserProps {
  id: string;
  handleUndeleteUser: (id: string) => Promise<void>;
}

moment.tz.setDefault("America/New_York");
const dateFormat = "MM/DD/YYYY hh:mm a z";

export const UserList = ({ title }: Props) => {
  let [refreshTs] = useState<number>(new Date().valueOf());

  const { data, loading, error, refetch } = useQuery<UsersData>(USERS, {
    variables: {
      includeDeleted: true,
    },
    fetchPolicy: "no-cache",
    onError: (data) => {
      message.error("Unable to retrieve record!");
    },
  });
  const users = data ? data.users : undefined;

  const [deleteUser, { loading: deleteRunning }] = useMutation<
    DeleteUserData,
    DeleteUserVariables
  >(DELETE_USER, {
    onCompleted: (data) => {
      message.success("Record successfully deleted.");
    },
    onError: (data) => {
      message.error("Failed to delete record!");
    },
  });

  const [undeleteUser, { loading: undeleteRunning }] = useMutation<
    UndeleteUserData,
    UndeleteUserVariables
  >(UNDELETE_USER, {
    onCompleted: (data) => {
      message.success("Record successfully restored.");
    },
    onError: (data) => {
      message.error("Failed to restore record!");
    },
  });

  const [disableUser, { loading: disableRunning }] = useMutation<
    DisableUserData,
    DisableUserVariables
  >(DISABLE_USER, {
    onCompleted: (data) => {
      message.success("User successfully disabled.");
    },
    onError: (data) => {
      message.error("Failed to disable user!");
    },
  });

  const [enableUser, { loading: enableRunning }] = useMutation<
    EnableUserData,
    EnableUserVariables
  >(ENABLE_USER, {
    onCompleted: (data) => {
      message.success("User successfully enabled.");
    },
    onError: (data) => {
      message.error("Failed to enable user!");
    },
  });

  const addUrl = `/admin/system/user/0`;
  const editUrl = `/admin/system/user`;
  const history = useHistory();

  const handleAddUser = async () => {
    history.push(`${addUrl}`);
  };

  const handleEditUser = async (id: string) => {
    history.push(`${editUrl}/${id}`);
  };

  const handleDeleteUser = async (id: string) => {
    await deleteUser({ variables: { id } });
    refetch();
  };

  const handleUndeleteUser = async (id: string) => {
    await undeleteUser({ variables: { id } });
    refetch();
  };

  const handleDisableUser = async (id: string) => {
    await disableUser({ variables: { id } });
    refetch();
  };

  const handleEnableUser = async (id: string) => {
    await enableUser({ variables: { id } });
    refetch();
  };

  const statusBadgeStyles = [
    "badge-success",
    "badge-secondary",
    "badge-warning",
    "badge-info",
    "badge-danger",
  ];
  const columns: any = [
    {
      title: "Status",
      dataIndex: "userStatusId",
      key: "userStatusId",
      render: (text: any, row: any) => (
        <span className={`font-size-12 badge ${statusBadgeStyles[text - 1]}`}>
          {row.userStatus.name}
        </span>
      ),
      sorter: (a: any, b: any) =>
        StringSorter(a.userStatus.name, b.userStatus.name),
    },
    {
      dataIndex: "id",
      key: "avatar",
      width: 27,
      render: (text: any, row: any) => (
        <div className="air__utils__avatar air__utils__avatar--size27">
          <img src={`/avatar/user/${text}?_ts=${refreshTs}`} alt="avatar" />
        </div>
      ),
    },
    {
      title: "Login ID",
      dataIndex: "userCredential.username",
      key: "userCredential.username",
      render: (text: any) => <span>{text ? text : "-- none --"}</span>,
      sorter: (a: any, b: any) =>
        StringSorter(
          a.userCredential ? a.userCredential.username : null,
          b.userCredential ? b.userCredential.username : null
        ),
    },
    {
      title: "Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text: any, row: any) => (
        <span>
          {row.firstName}
          {row.middleName ? " " + row.middleName : ""}&nbsp;
          {row.lastName}
        </span>
      ),
      sorter: (a: any, b: any) => {
        let strA =
          a.firstName +
          (a.middleName ? " " + a.middleName : "") +
          " " +
          a.lastName;
        let strB =
          b.firstName +
          (b.middleName ? " " + b.middleName : "") +
          " " +
          b.lastName;

        return StringSorter(strA, strB);
      },
    },
    {
      title: "Role",
      dataIndex: "role.name",
      key: "role.name",
      sorter: (a: any, b: any) => StringSorter(a.role.name, b.role.name),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => StringSorter(a.email, b.email),
    },
    {
      title: "Phone Number",
      dataIndex: "primaryPhone",
      key: "primaryPhone",
      sorter: (a: any, b: any) => StringSorter(a.primaryPhone, b.primaryPhone),
    },
    {
      title: "Last Login",
      dataIndex: "userCredential.lastLoginAt",
      key: "userCredential.lastLoginAt",
      align: "center",
      render: (text: any) => (
        <span>
          {text ? moment(new Date(text)).format(dateFormat) : "-- never --"}
        </span>
      ),
      sorter: (a: any, b: any) =>
        DateSorter(
          a.userCredential ? a.userCredential.lastLoginAt : null,
          b.userCredential ? b.userCredential.lastLoginAt : null
        ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any) =>
        row.isDeleted ? (
          <PopUndeleteUser id={text} handleUndeleteUser={handleUndeleteUser} />
        ) : (
          <PopEditUser
            id={text}
            userStatusId={row.userStatusId}
            handleEditUser={handleEditUser}
            handleDisableUser={handleDisableUser}
            handleEnableUser={handleEnableUser}
            handleDeleteUser={handleDeleteUser}
          />
        ),
    },
  ];

  if (loading) {
    return (
      <Content>
        <div className="admin">
          <UserListSkeleton title={title} />
        </div>
      </Content>
    );
  }

  if (error) {
    console.log("Error: ", error);
    return (
      <Content>
        <div className="admin">
          <UserListSkeleton title={title} error />
        </div>
      </Content>
    );
  }

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Administrator"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Spin
              spinning={
                deleteRunning ||
                undeleteRunning ||
                disableRunning ||
                enableRunning
              }
            >
              <div>
                <Helmet title={`Manage Users`} />
                <div className="air__utils__heading">
                  <h5>Manage Users</h5>
                </div>

                <div className="card">
                  <div className="card-header card-header-flex">
                    <div className="d-flex flex-column justify-content-center mr-auto">
                      <h4 className="mb-0">
                        <strong>User List</strong>
                      </h4>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{ marginTop: "25px" }}
                    >
                      {addUrl && (
                        <span>
                          <Button
                            onClick={() => handleAddUser()}
                            size="small"
                            shape="round"
                          >
                            Add
                          </Button>
                        </span>
                      )}
                      &nbsp;
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="air__utils__scrollTable">
                      <Table rowKey="id" columns={columns} dataSource={users} />
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible
        when={anyAuth(hasAuthRole("Navigator"), hasAuthRole("AccountManager"))}
      >
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};

export const PopUndeleteUser = ({
  id,
  handleUndeleteUser,
}: PopUndeleteUserProps) => {
  return (
    <span>
      <Popconfirm
        title={`Are you sure restore this deleted User entry?`}
        onConfirm={() => handleUndeleteUser(id)}
        okText="Yes"
        cancelText="No"
      >
        <Button size="small" shape="round">
          Restore
        </Button>
      </Popconfirm>
    </span>
  );
};

export const PopEditUser = ({
  id,
  userStatusId,
  handleEditUser,
  handleDisableUser,
  handleEnableUser,
  handleDeleteUser,
}: PopEditUserProps) => {
  return (
    <span>
      <Button
        style={{ marginRight: "5px" }}
        onClick={() => handleEditUser(id)}
        size="small"
        shape="round"
      >
        Edit
      </Button>

      {userStatusId === UserStatusEnum.Active && (
        <Button
          style={{ marginRight: "5px" }}
          onClick={() => handleDisableUser(id)}
          size="small"
          shape="round"
        >
          Disable
        </Button>
      )}
      {userStatusId === UserStatusEnum.Disabled && (
        <Button
          style={{ marginRight: "5px" }}
          onClick={() => handleEnableUser(id)}
          size="small"
          shape="round"
        >
          Enable
        </Button>
      )}

      <Popconfirm
        title={`Are you sure delete this User entry?`}
        onConfirm={() => handleDeleteUser(id)}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ marginRight: "5px" }} size="small" shape="round">
          Delete
        </Button>
      </Popconfirm>
    </span>
  );
};
