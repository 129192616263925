import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Card, Icon, Layout } from "antd";

import style from "./style.module.scss";

import {
  REPORT_PROFILES,
  ReportProfiles as ReportProfilesData,
  ReportProfiles_reportProfiles as ReportProfileRecord,
} from "../../../lib/graphql/queries";
import { AuthVisible, hasAuthRole, anyAuth } from "../../../lib/utils";

const { Content } = Layout;

interface ReportGroupProfile {
  reportGroupName: string;
  reportProfiles: ReportProfileRecord[] | undefined;
}
interface ReportGroupProps {
  reportGroupProfiles: ReportGroupProfile[] | undefined;
}

interface ReportProfileProps {
  reportGroupName: string;
  reportProfiles: ReportProfileRecord[] | undefined;
}

export const ReportingDashboard = () => {
  const { data: reportProfileData } = useQuery<ReportProfilesData>(
    REPORT_PROFILES,
    {
      fetchPolicy: "no-cache",
    }
  );
  const reportProfiles = reportProfileData
    ? reportProfileData.reportProfiles
    : undefined;
  console.log("reportProfiles: ", reportProfiles);

  let reportGroupProfiles: ReportGroupProfile[] = [];
  let currentReportGroupId = "";
  let currentReportGroupProfile: ReportGroupProfile | null = null;
  if (reportProfiles) {
    for (let i = 0; i < reportProfiles?.length; i++) {
      if (reportProfiles[i].reportGroup.id !== currentReportGroupId) {
        currentReportGroupProfile = {
          reportGroupName: reportProfiles[i].reportGroup.name,
          reportProfiles: [],
        };
        reportGroupProfiles.push(currentReportGroupProfile);
        currentReportGroupId = reportProfiles[i].reportGroup.id;
      }

      if (currentReportGroupProfile?.reportProfiles) {
        currentReportGroupProfile.reportProfiles.push(reportProfiles[i]);
      }
    }
  }

  const ReportGroupItems = ({ reportGroupProfiles }: ReportGroupProps) => {
    return (
      <div className="row">
        {reportGroupProfiles &&
          reportGroupProfiles.map((reportGroupProfile) => {
            return (
              <div className="col-lg-4 mb-4">
                <div>
                  <Card
                    type="inner"
                    size="small"
                    title={reportGroupProfile.reportGroupName}
                  >
                    <ReportProfileItems
                      reportGroupName={reportGroupProfile.reportGroupName}
                      reportProfiles={reportGroupProfile.reportProfiles}
                    />
                  </Card>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const ReportProfileItems = ({
    reportGroupName,
    reportProfiles,
  }: ReportProfileProps) => {
    return (
      <ul key={reportGroupName} className="list-unstyled">
        {reportProfiles &&
          reportProfiles.map((reportProfile) => {
            const { id, title, description } = reportProfile;
            return (
              <li key={id} className={style.item}>
                <Link
                  to={`/reporting/run-report/${id}`}
                  className={style.itemLink}
                >
                  <div className={style.itemPic}>
                    <Icon type="file-text" />
                  </div>
                  <div className="mr-2">
                    <div>{title}</div>
                    <div className="text-muted">{description}</div>
                  </div>
                  <div className={style.itemAction}>
                    <span />
                  </div>
                </Link>
              </li>
            );
          })}
      </ul>
    );
  };

  return (
    <div>
      <AuthVisible
        when={anyAuth(
          hasAuthRole("Navigator"),
          hasAuthRole("AccountManager"),
          hasAuthRole("PortalManager"),
          hasAuthRole("Auditor")
        )}
      >
        <Content>
          <div>
            <Helmet title="Reports" />
            <div className="air__utils__heading">
              <h5>Reports</h5>
            </div>
            <div className="card">
              <div className="card-header card-header-flex">
                <div className="d-flex flex-column justify-content-center mr-auto">
                  <h5 className="mb-0">Dashboard of Reports</h5>
                </div>
              </div>
              <div className="card-body">
                <div className="air__utils__scrollTable">
                  <ReportGroupItems reportGroupProfiles={reportGroupProfiles} />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </AuthVisible>

      <AuthVisible when={anyAuth(hasAuthRole("Administrator"))}>
        <div>Access denied</div>
      </AuthVisible>
    </div>
  );
};
